import React from 'react';
import { FormattedMessage } from 'react-intl';

import Banner from 'app/components/Banner';
import messages from 'app/components/Heading/messages';
import { IMPORTING_STATUS } from 'app/parameter';

import { ExtraImport } from './types';
import useImportStatus from './useImportStatus';

type Props = {
  extraImport: ExtraImport;
};

const ImportStatusBanner: React.FC<Props> = (props: Props) => {
  const { extraImport } = props;
  const [shouldShow, setShouldShow] = useImportStatus();

  if (
    !(
      !shouldShow &&
      extraImport.dateImportFinish === null &&
      extraImport.status === IMPORTING_STATUS.RUNNING
    )
  ) {
    return null;
  }

  return (
    <Banner
      type="info"
      icon="data_import"
      onClose={setShouldShow as any}
      title={
        <FormattedMessage
          tagName={'span'}
          {...messages.ExtraImportTitle}
          values={{
            completedPercent: extraImport.completedPercent.toString(),
          }}
        />
      }>
      <FormattedMessage tagName={'span'} {...messages.ExtraImportDesc} />
    </Banner>
  );
};

export default ImportStatusBanner;
