import {
  useCurrentOrganization,
  useCurrentProject,
  useCurrentUser,
} from '@ryte/mainframe';
import { useMemo } from 'react';

const getDecimalSeparator = () => {
  // Get user's locale
  const userLocale: string =
    navigator.language || (navigator as any).userLanguage;

  // Get the decimal separator
  const decimalSeparator = (234.567)
    .toLocaleString(userLocale)
    .replace(/\d/g, '');

  return decimalSeparator;
};

export const useHeaders = () => {
  const currentProject = useCurrentProject();
  const organization = useCurrentOrganization();
  const numberFormatSeparatorDecimals = getDecimalSeparator() || '.';
  const { id: userId, lang } = useCurrentUser() || {};
  const headers = useMemo(() => {
    if (!userId || !currentProject || !organization) {
      return undefined;
    }
    return {
      'Content-Type': 'application/json',
      'accountId': userId,
      'projectHash': currentProject?.projectHash,
      numberFormatSeparatorDecimals,
      'language': lang,
      'customerId': organization?.id,
    };
  }, [currentProject?.projectHash, organization?.id, userId, lang]);

  return headers;
};
