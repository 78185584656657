import type { Store, AnyAction, Reducer } from 'redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { history } from '@ryte/mainframe';

const sagaMiddleware = createSagaMiddleware({});

export type EnhancedStore = Store<any, AnyAction> & {
  injectedReducers: Record<string, any>;
  injectedSagas: Record<string, Reducer<any, AnyAction>>;
  runSaga: typeof sagaMiddleware.run;
};

export default function configureStore(): EnhancedStore {
  const middlewares = [
    sagaMiddleware, // Introduces support for redux-saga
    routerMiddleware(history), // Syncs the location/URL path to the state
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          instanceId: 'app', // by specifying an instanceId we prevent multiple instances from being created
        })
      : compose;

  const staticReducers = {
    ['router']: connectRouter(history),
  };

  const initialState = {};

  const store: EnhancedStore = Object.assign(
    createStore(
      combineReducers({ ...staticReducers }),
      initialState,
      composeEnhancers(...enhancers)
    ),
    {
      runSaga: sagaMiddleware.run,
      injectedReducers: staticReducers,
      injectedSagas: {},
    }
  );

  // // Extensions
  // (store as any).runSaga = sagaMiddleware.run;

  // /**
  //  * registry for injected reducers,
  //  * initialized with the staticReducers variable to make sure the
  //  * static reducers don't get removed when a new reducer is injected
  //  */
  // (store as any).injectedReducers = staticReducers; // Reducer registry
  // (store as any).injectedSagas = {}; // Saga registry

  if (process.env.NODE_ENV !== 'production') {
    (window as any).store = store;
  }

  return store;
}
