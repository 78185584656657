import { Select, Tooltip } from '@ryte/ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { compose } from 'redux';

import { Pagination } from 'app/components/Pagination';
import { createStructuredSelector } from 'reselect';

import {
  useCurrentOrganization,
  useCurrentSubscription,
  useCurrentUser,
} from '@ryte/mainframe';
import 'react-table/react-table.css';
import { USER_ID } from '../../parameter';
import { updateSetting } from './actions';
import messages from './messages';
import { selectTable } from './selectors';
import './Table.scss';

export const TABLE_SETTING = (userId = localStorage.getItem(USER_ID)) =>
  `${userId}_table_setting`;
const keyList = [16];
const pageSizeOptions = [10, 25, 100, 250, 1000];

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      sortList: [],
      isCtrlPressed: false,
      savedProps: {},
      isFetchedData: false,
      isShowUpgradeModal: false,
    };

    if (props.showRowCounter) {
      props.updateSetting({ numberRow: true });
    }

    this.tableRef = React.createRef();
    this.tableFooterRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    const { page } = nextProps;
    if (page !== this.state.page) {
      this.setState({
        page,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.isCtrlPressed &&
      prevState.isCtrlPressed &&
      this.convertMultipleSort().length > 0 &&
      this.props.multipleSort &&
      !prevState.isFetchedData
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isFetchedData: true,
      });
      this.props.onFetchData({
        ...prevState.savedProps,
        sorting: this.convertMultipleSort(),
      });
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onCtrlKeyDown, false);
    window.addEventListener('keyup', this.onCtrlKeyUp, false);
    window.addEventListener('scroll', this.scrollHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onCtrlKeyDown, false);
    window.removeEventListener('keyup', this.onCtrlKeyUp, false);
    window.removeEventListener('scroll', this.scrollHandler, false);
  }

  scrollHandler = () => {
    if (!this.props.fixedHeader || !this.tableFooterRef.current) {
      return;
    }

    this.tableHeadElm = this.tableRef.current.querySelector('.rt-thead');
    const elementRect = this.tableRef.current.getBoundingClientRect();
    const headerHeight = document.querySelector('header').clientHeight;
    if (
      headerHeight - elementRect.top >= 30 &&
      headerHeight - elementRect.top - this.tableFooterRef.current.offsetTop <=
        100
    ) {
      this.tableHeadElm.style.position = 'absolute';
      this.tableHeadElm.style.top = `${headerHeight - elementRect.top}px`;
      this.tableHeadElm.style.zIndex = 1001;
    } else {
      this.tableHeadElm.style.position = null;
      this.tableHeadElm.style.top = 0;
    }
  };

  onCtrlKeyDown = (e) => {
    if (!this.props.multipleSort) {
      return;
    }

    if (keyList.indexOf(e.keyCode) > -1) {
      this.setState({
        isCtrlPressed: true,
      });
    }
  };

  onCtrlKeyUp = (e) => {
    if (!this.props.multipleSort) {
      return;
    }

    if (keyList.indexOf(e.keyCode) > -1) {
      this.setState({
        isCtrlPressed: false,
      });
    }
  };

  handlePageClick = (page) => {
    const { handlePageClick, onPageChange } = this.props;

    if (typeof handlePageClick === 'function') {
      handlePageClick(page, this.props.pageSize);
    } else {
      this.props.updateFilter({ ...this.props.filter, page });
    }

    if (typeof onPageChange === 'function') {
      this.props.onPageChange();
    }
  };

  handleFetchData = (state) => {
    if (this.tableHeadElm) {
      this.tableHeadElm.style.position = null;
      this.tableHeadElm.style.top = 0;
    }

    this.setState(
      (prevState) => ({
        sortList: state.sorted,
        isFetchedData: false,
        savedProps: this.props.multipleSort ? state : prevState.savedProps,
      }),
      () => {
        if (this.props.multipleSort) {
          if (!this.state.isCtrlPressed) {
            this.props.onFetchData({
              ...state,
              sorting: this.convertMultipleSort(),
            });
          }
        } else {
          this.props.onFetchData(state);
        }
      }
    );
  };

  handleChangePageSize = (e) => {
    const newPageSize = parseInt(e, 10);

    if (this.props.handlePageClick !== undefined) {
      this.props.handlePageClick(1, newPageSize);
    } else {
      this.props.updateFilter({
        ...this.props.filter,
        page: 1,
        pageSize: newPageSize,
      });
    }

    if (this.props.onPageChange) {
      this.props.onPageChange();
    }
  };

  toggleUpgradeModal = () => {
    this.setState((prevState) => ({
      isShowUpgradeModal: !prevState.isShowUpgradeModal,
    }));
  };

  get conditionForRenderingTableFooter() {
    return this.props.showPagination && !this.props.loading;
  }

  get minRows() {
    if (
      this.props.data.length > 0 &&
      this.props.data.length < 10 &&
      !this.props.fixedMinRow
    ) {
      return this.props.data.length;
    }
    return this.props.minRows;
  }

  columns = () => {
    const width =
      (this.props.defaultPageSize * this.props.page).toString().length * 15;
    const { sortList } = this.state;
    return [
      {
        className: 'text-left dashboard-table-tr number-tr',
        headerClassName: 'text-left dashboard-table-header number-tr',
        accessor: 'index',
        Cell: (row) => {
          if (this.props.hideNumberRow.includes(row.index)) return '';
          return (
            <span className="number-column">
              {this.props.defaultPageSize * (this.props.page - 1) +
                (row.index + 1) -
                this.props.hideNumberRow.length}
            </span>
          );
        },
        maxWidth: width,
        resizable: false,
      },
      ...this.props.columns.map((column) => {
        const header = this.props.multipleSort ? (
          <Tooltip
            content={this.props.intl.formatMessage(
              messages.MultipleSortMessage
            )}
            direction="bottom"
            delayTimer={200}
            maxWidth="300px">
            {column.Header}
          </Tooltip>
        ) : (
          column.Header
        );

        if (!this.props.multipleSort || sortList.length <= 1) {
          return {
            ...column,
            Header: <span className="header-name-wrapper">{header}</span>,
          };
        }
        const index = sortList.findIndex(
          (sortItem) => column.id === sortItem.id
        );
        return {
          ...column,
          Header: (
            <span className="header-name-wrapper">
              {column.Header} {index > -1 ? <b>{index + 1}</b> : null}
            </span>
          ),
          headerClassName: column.headerClassName,
        };
      }),
    ];
  };

  convertMultipleSort = (sortList = this.state.sortList) =>
    sortList.map((item) => ({
      sortField: item.id,
      sortType: item.desc ? 'DESC' : 'ASC',
    }));

  render() {
    const { tableSetting, hideNumberColumn } = this.props;
    const { formatMessage } = this.props.intl;
    const { isCtrlPressed } = this.state;
    let columns = this.columns(); // clone for modification
    let disableSortingAllColumn = this.props.data.length === 0;

    if (disableSortingAllColumn || !this.props.sortable) {
      columns = columns.map((col) => {
        return Object.assign(col, {
          sortable: false,
        });
      });
    }

    if (tableSetting.numberRow && !hideNumberColumn) {
      columns[0].show = true;
      // columns[0].className = columns[0].className.replace('hidden', '');
      columns[1].className = `${columns[1].className} text-blue`;
    } else {
      columns[0].show = false;
      // columns[0].className = `${columns[0].className} hidden`;
      columns[1].className = columns[1].className.replace('text-blue', '');
    }
    let noDataText = this.props.loading
      ? ''
      : this.props.intl.formatMessage(messages.NoData);
    if (this.props.hideNumberColumn && !this.props.forceShowNoData)
      noDataText = '';
    return (
      <div
        id="table"
        className={`table ${isCtrlPressed ? 'multisorting' : ''}`}
        style={{ position: 'relative' }}>
        <ReactTable
          className={`${this.props.className} dashboard-table -highlight`}
          style={
            this.props.hideNumberColumn ||
            this.props.pageSize === 10 ||
            this.props.data.length <= 10 ||
            this.props.fixedHeader ||
            this.props.noFixedHeight
              ? {}
              : { height: '450px' }
          }
          expanded={this.props.expanded}
          getTrGroupProps={this.props.getTrGroupProps}
          getTdProps={this.props.getTdProps}
          getTrProps={this.props.getTrProps}
          collapseOnPageChange={false}
          showPagination={false}
          minRows={this.minRows}
          defaultPageSize={100}
          pageSize={this.props.pageSize}
          pages={this.props.pages}
          data={this.props.data}
          columns={columns}
          loadingText={''}
          noDataText={noDataText}
          manual
          showPageJump={false}
          pageSizeOptions={pageSizeOptions}
          loading={this.props.loading}
          multiSort={this.props.multipleSort}
          onFetchData={this.handleFetchData}
          onSortedChange={this.props.onSortedChange}
          SubComponent={this.props.SubComponent}
          rowsText={formatMessage(messages.Rows)}
          ofText={formatMessage(messages.Of)}
          pageText={formatMessage(messages.Page)}
          defaultSorted={this.props.defaultSorted || []}
          onResizedChange={this.scrollHandler}
          collapseOnDataChange={false}>
          {(state, makeTable) => (
            <div ref={this.tableRef}>
              {makeTable()}
              {this.conditionForRenderingTableFooter && (
                <div className="table-bottom" ref={this.tableFooterRef}>
                  <span className="select-wrap -pageSizeOptions">
                    <Select
                      value={this.props.pageSize}
                      onChange={this.handleChangePageSize}
                      options={pageSizeOptions.map((n) => ({
                        value: n,
                        label: formatMessage(messages.ShowRows, { number: n }),
                      }))}
                    />
                  </span>
                  <Pagination
                    currentPage={this.state.page}
                    totalPages={this.props.pages}
                    onChange={this.handlePageClick}
                  />
                </div>
              )}
            </div>
          )}
        </ReactTable>
      </div>
    );
  }
}

Table.propTypes = {
  organization: PropTypes.shape({}),
  subscription: PropTypes.shape({}),
  hideNumberRow: PropTypes.array,
  data: PropTypes.array,
  columns: PropTypes.array,
  onFetchData: PropTypes.func,
  onSortedChange: PropTypes.func,
  pages: PropTypes.number,
  page: PropTypes.number,
  defaultPageSize: PropTypes.number,
  sortable: PropTypes.bool,
  loading: PropTypes.bool,
  hideNumberColumn: PropTypes.bool,
  showPagination: PropTypes.bool,
  className: PropTypes.string,
  getTdProps: PropTypes.func,
  handlePageClick: PropTypes.func,
  updateFilter: PropTypes.func,
  getTrProps: PropTypes.func,
  filter: PropTypes.object,
  SubComponent: PropTypes.func,
  minRows: PropTypes.number,
  tableSetting: PropTypes.object,
  pageSize: PropTypes.number,
  multipleSort: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  noFixedHeight: PropTypes.bool,
  fixedMinRow: PropTypes.bool,
  forceShowNoData: PropTypes.bool,
  lang: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

Table.defaultProps = {
  hideNumberColumn: false,
  minRows: 10,
  fixedMinRow: false,
  showPagination: false,
  multipleSort: false,
  sortable: false,
  onFetchData: () => {},
  onSortedChange: () => {},
  hideNumberRow: [],
  forceShowNoData: false,
  noFixedHeight: false,
};

const mapStateToProps = createStructuredSelector({
  tableSetting: selectTable(),
});

const actionCreators = {
  updateSetting,
};

const ExtendedTable = (props) => {
  const { lang } = useCurrentUser();
  const subscription = useCurrentSubscription();
  const organization = useCurrentOrganization();
  return (
    <Table
      {...props}
      lang={lang}
      subscription={subscription}
      organization={organization}
    />
  );
};
export default compose(
  connect(mapStateToProps, actionCreators),
  injectIntl
)(ExtendedTable);
