import React from 'react';

import { WrappedFilterContext } from './WrappedFilterContext';

export type WrappedFilterConfigurationConsumerProps =
  React.PropsWithChildren<{}>;
export const WrappedFilterConfigurationConsumer = (
  props: WrappedFilterConfigurationConsumerProps
) => {
  return (
    <WrappedFilterContext.Consumer>
      {(ctx) => (ctx?.isLoaded ? props.children : null)}
    </WrappedFilterContext.Consumer>
  );
};
