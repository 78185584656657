import React from 'react';
import { useIntl } from 'react-intl';
import {
  useCurrentProject,
  useNavigateToSettings,
  useOAuth,
} from '@ryte/mainframe';
import { Icon, Tooltip, TooltipProps } from '@ryte/ui-components';

import messages from './messages';
import styles from './styles.module.scss';

type Props = {
  tooltip?: Partial<TooltipProps>;
};

const GlobalFilterIcon: React.FC<Props> = ({ tooltip }) => {
  const intl = useIntl();
  const projectHash = useCurrentProject()?.projectHash;
  const OAuthAPI = useOAuth();
  const globalFilter = OAuthAPI.selectSESGlobalFilter;
  const { navigateToProjectSettings } = useNavigateToSettings();
  if (
    !globalFilter.length ||
    (!globalFilter[0].country?.contains?.length &&
      !globalFilter[1]?.device?.contains?.length)
  ) {
    return <></>;
  }

  const content = intl.formatMessage(messages.global_filter_set, {
    a: (msg: string) => (
      <a
        onClick={() => navigateToProjectSettings(projectHash!, 'setup')}
        className={styles.link}
        target="_blank">
        {msg}
      </a>
    ),
  });

  return (
    <Tooltip content={content} direction="top" {...tooltip}>
      <Icon color="muted" icon="globe" size={16} />
    </Tooltip>
  );
};

export default GlobalFilterIcon;
