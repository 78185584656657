import React from 'react';
import { Route, RouteProps, Redirect, matchPath } from 'react-router-dom';
import { useOAuth } from '@ryte/mainframe';

import ROUTES from 'app/configureRoutes';
import { REPORT } from 'app/apiDefine';

export const getProjectSlug = (path: string): string => {
  const match = matchPath(path, {
    path: '/:slug/',
  });

  if (match === null) {
    return '';
  }

  const { slug = null } = match.params as any;
  return slug;
};

type Props = RouteProps;
const ConnectedPropertyRoute: React.FC<Props> = (props) => {
  const pathName = window.location.pathname;
  const projectSlug = getProjectSlug(pathName);
  const OAuthAPI = useOAuth();
  const {
    selectIsStateLoaded: isGSCStateLoaded,
    selectIsImportingResults: isImportingResults,
    selectForceUnlockUI: forceUnlockUI,
    selectIsInvalidToken: isInvalidToken,
    selectData,
  } = OAuthAPI;
  const { connectionStatus } = selectData;

  const isImportingInterrupted =
    isInvalidToken || connectionStatus === 'DISCONNECTED_BUT_CONNECTED_BEFORE';

  if (isImportingResults && !isImportingInterrupted && forceUnlockUI === 0) {
    return (
      <Redirect data-testid="lockUI" to={`/${projectSlug}/${REPORT.LOCK_UI}`} />
    );
  }

  if (isGSCStateLoaded && connectionStatus !== 'CONNECTED') {
    return (
      <Redirect
        data-testid="easyConnect"
        to={`/${projectSlug}/${ROUTES.EASY_CONNECT}`}
      />
    );
  }

  return <Route data-testid="report" {...props} />;
};

export default ConnectedPropertyRoute;
