import { defineMessages } from 'react-intl';

export default defineMessages({
  sevenDays: {
    id: 'app.components.DateRangePicker.sevenDays',
    defaultMessage: '7 days',
  },
  oneMonth: {
    id: 'app.components.DateRangePicker.oneMonth',
    defaultMessage: '1 month',
  },
  threeMonths: {
    id: 'app.components.DateRangePicker.threeMonths',
    defaultMessage: '3 months',
  },
  sixMonths: {
    id: 'app.components.DateRangePicker.sixMonths',
    defaultMessage: '6 months',
  },
  twelveMonths: {
    id: 'app.components.DateRangePicker.twelveMonths',
    defaultMessage: '12 months',
  },
  sixteenMonths: {
    id: 'app.components.DateRangePicker.sixteenMonths',
    defaultMessage: '16 months',
  },
  maximum: {
    id: 'app.components.DateRangePicker.maximum',
    defaultMessage: 'Maximum',
  },
  customRange: {
    id: 'app.components.DateRangePicker.customRange',
    defaultMessage: 'Custom',
  },
  previousPeriod: {
    id: 'app.components.DateRangePicker.previousPeriod',
    defaultMessage: 'previous period',
  },
  previousYear: {
    id: 'app.components.DateRangePicker.previousYear',
    defaultMessage: 'previous year',
  },
  previousQuarter: {
    id: 'app.components.DateRangePicker.previousQuarter',
    defaultMessage: 'previous quarter',
  },
  previousMonth: {
    id: 'app.components.DateRangePicker.previousMonth',
    defaultMessage: 'previous month',
  },
  cancel: {
    id: 'app.components.DateRangePicker.cancel',
    defaultMessage: 'Cancel',
  },
  apply: {
    id: 'app.components.DateRangePicker.apply',
    defaultMessage: 'Apply',
  },
  startDate: {
    id: 'app.components.DateRangePicker.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.DateRangePicker.endDate',
    defaultMessage: 'End Date',
  },
  dateRange: {
    id: 'app.components.DateRangePicker.dateRange',
    defaultMessage: 'Date range',
  },
  compareTo: {
    id: 'app.components.DateRangePicker.compareTo',
    defaultMessage: 'Compare to',
  },
  FreshData: {
    id: 'app.containers.KPI.FreshData',
    defaultMessage: 'fresh GSC data',
  },
  SelectFreshDate: {
    id: 'app.containers.KPI.SelectFreshDate',
    defaultMessage: 'Selected date range includes',
  },
  FreshDataTooltip: {
    id: 'app.containers.KPI.FreshDataTooltip',
    defaultMessage:
      'Fresh GSC data is the most recent data from Google. It will be replaced with the final data after a few days, therefore might change a bit.',
  },
  previousDays: {
    id: 'app.components.DateRangePicker.previousDays',
    defaultMessage: 'previous {number} days',
  },
  previousDay: {
    id: 'app.components.DateRangePicker.previousDay',
    defaultMessage: 'previous day',
  },
  lastDays: {
    id: 'app.components.DateRangePicker.lastDays',
    defaultMessage: 'Last {number} days',
  },
});
