import { call, select, takeLatest, put } from 'redux-saga/effects';

import { callApi } from 'app/features/api';
import { selectStartDate, selectEndDate } from './selectors';
import {
  loadTrafficSuccess,
  loadTrafficError,
  updateCostSettingSuccess,
  loadCostSettings,
} from './actions';
import {
  LOAD_TRAFFIC,
  UPDATE_COST_SETTINGS,
  UPDATE_COST_SETTINGS_SUCCESS,
  UPDATE_CURRENCY,
} from './constants';

export function* loadTrafficData({ fetchAuthenticated, headers }) {
  const startDate = yield select(selectStartDate());
  const endDate = yield select(selectEndDate());
  const res = yield call(callApi, fetchAuthenticated, {
    resource: '/dashboard/cpcGraph',
    method: 'POST',
    body: JSON.stringify({ startDate, endDate }),
    headers,
  });
  if (res.error) {
    yield put(loadTrafficError(res.error.response.parsedJSON.errors[0]));
    return;
  }

  yield put(loadTrafficSuccess(res.data));
}

export function* updateCostSettings(
  { fetchAuthenticated, headers },
  { costSettings }
) {
  const res = yield call(callApi, fetchAuthenticated, {
    resource: '/setting/cpc',
    method: 'PUT',
    headers,
    body: JSON.stringify({
      avgCPC: costSettings.avgCpc,
    }),
  });

  if (res.error) {
    return;
  }

  yield put(loadCostSettings());
  yield put(updateCostSettingSuccess());
}

export function* updateCurrency(
  { fetchAuthenticated, headers },
  { currencyCode }
) {
  const res = yield call(callApi, fetchAuthenticated, {
    resource: '/setting/currency',
    method: 'PUT',
    headers,
    body: JSON.stringify({ currencyCode }),
  });

  if (res.error) {
    return;
  }
}

export default function createSaga(fetchAuthenticated, headers) {
  return function* weeklyTrafficSaga() {
    yield takeLatest(
      [LOAD_TRAFFIC, UPDATE_COST_SETTINGS_SUCCESS],
      loadTrafficData,
      { fetchAuthenticated, headers }
    );
    yield takeLatest(UPDATE_COST_SETTINGS, updateCostSettings, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(UPDATE_CURRENCY, updateCurrency, {
      fetchAuthenticated,
      headers,
    });
  };
}
