import React from 'react';
import cx from 'classnames';
import { Icon } from '@ryte/ui-components';

import styles from './styles.module.scss';
import { PaginationProps } from './types';

const ArrowsOnlyPagination = (props: PaginationProps) => {
  const nextHandler = React.useCallback(
    () => props.onChange(props.currentPage + 1),
    [props.onChange, props.currentPage]
  );

  const prevHandler = React.useCallback(
    () => props.onChange(props.currentPage - 1),
    [props.onChange, props.currentPage]
  );

  const prevDisabled = props.currentPage === 1;
  const nextDisabled = props.currentPage === props.totalPages;

  return (
    <div className={styles.arrowPagination}>
      <div
        className={cx(styles.arrowContainer, {
          [styles.disabledArrow]: prevDisabled,
        })}
        onClick={prevDisabled ? undefined : prevHandler}>
        <Icon icon={'chevron_left'} />
      </div>
      <div
        className={cx(styles.arrowContainer, {
          [styles.disabledArrow]: nextDisabled,
        })}
        onClick={nextDisabled ? undefined : nextHandler}>
        <Icon icon={'chevron_right'} />
      </div>
    </div>
  );
};

export default ArrowsOnlyPagination;
