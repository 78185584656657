import { LOCATION_CHANGE } from 'connected-react-router';
import queryString from 'query-string';
import { redirectStoreKey } from 'app/parameter';
import {
  UPDATE_ERROR_TYPE,
  UPDATE_EXTRA_IMPORT,
  UPDATE_CONFIDENCE_LEVEL,
  UPDATE_ANOMALY_SETTING,
  SAVE_ANOMALY_SETTING,
  UPDATE_LOADING_CONTEXT,
  LOAD_RECIPE_LIST_SUCCESS,
  LOAD_SMART_RECIPES,
  UPDATE_RECIPE_LOADING,
  UPDATE_EXCLUDE_KEYWORD,
  LOAD_RECIPE_LIST,
  SET_DATE_RANGE_AVAILABILITY,
  SET_PROJECT_SETTINGS_LOADED,
  SET_FRESH_DATES_RANGES,
  SET_CRAWL_STATUS,
} from './constants';
import defaultSmartRecipeJson from './defaultSmartRecipes.json';

const initialState = {
  extraImport: {
    status: '',
    completedPercent: 0,
    dateImportFinish: null,
  },
  error: {
    type: '',
    code: '',
  },
  firstEnteredUrl: '',
  redirect: '',
  anomalySetting: {
    thresholdValue: 10,
  },
  tempAnomalySetting: {
    thresholdValue: 10,
  },
  loadingContext: [],
  recipeList: [],
  smartRecipes: defaultSmartRecipeJson,
  smartRecipesLoading: false,
  currentConfidenceLevel: 3,
  excludeKeyword: {},
  latestDate: null,
  oldestDate: null,
  freshDates: {
    startDate: null,
    endDate: null,
  },
  projectSettingsLoaded: false,
  wesCrawlStatus: null,
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_RECIPE_LIST:
      return {
        ...state,
        smartRecipes: defaultSmartRecipeJson,
      };
    case LOAD_SMART_RECIPES:
      return {
        ...state,
        smartRecipesLoading: false,
        smartRecipes: action.data,
      };

    case UPDATE_EXCLUDE_KEYWORD:
      return {
        ...state,
        excludeKeyword: action.keyword,
      };

    case UPDATE_RECIPE_LOADING:
      return {
        ...state,
        smartRecipesLoading: true,
      };

    case UPDATE_CONFIDENCE_LEVEL:
      return {
        ...state,
        currentConfidenceLevel: action.value,
      };

    case UPDATE_ANOMALY_SETTING:
      return {
        ...state,
        anomalySetting: action.setting,
        tempAnomalySetting: action.setting,
      };

    case SAVE_ANOMALY_SETTING:
      return {
        ...state,
        tempAnomalySetting: action.setting,
      };

    case UPDATE_EXTRA_IMPORT:
      return {
        ...state,
        extraImport: {
          ...state.extraImport,
          status: action.status,
          completedPercent: action.completedPercent,
          dateImportFinish: action.dateImportFinish,
        },
      };

    case UPDATE_LOADING_CONTEXT:
      return {
        ...state,
        loadingContext: action.loadingContext,
      };

    case UPDATE_ERROR_TYPE:
      return {
        ...state,
        error: action.error,
      };

    case LOCATION_CHANGE:
      const { location } = action.payload;
      const queryParams = queryString.parse(location.search);

      if (queryParams.redirect !== undefined) {
        localStorage.setItem(redirectStoreKey, queryParams.redirect);
      }

      if (action.payload.isFirstRendering) {
        return {
          ...state,
          error: '',
          firstEnteredUrl: `${location.pathname}${location.search}`,
        };
      }

      return {
        ...state,
        error: '',
      };
    case LOAD_RECIPE_LIST_SUCCESS:
      return {
        ...state,
        recipeList: action.payload.tableData,
        isAllowCreateNewSegment: action.payload.isAllowCreateNewSegment,
      };

    case SET_DATE_RANGE_AVAILABILITY:
      return {
        ...state,
        oldestDate: action.payload.oldestDate,
        latestDate: action.payload.latestDate,
      };

    case SET_FRESH_DATES_RANGES:
      return {
        ...state,
        freshDates: action.payload,
      };
    case SET_PROJECT_SETTINGS_LOADED:
      return {
        ...state,
        projectSettingsLoaded: true,
      };

    case SET_CRAWL_STATUS:
      return {
        ...state,
        wesCrawlStatus: action.payload.wesCrawlStatus,
      };

    default:
      return state;
  }
}

export default appReducer;
