import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isUUID } from 'app/utils/uuid';

const useMappingReport = () => {
  const intl = useIntl();
  const mappingReport = useMemo(
    () => ({
      'search-success/monitor/keyword-monitoring': {
        key: 'keyword_monitoring',
        name: intl.formatMessage({
          id: 'report.title.keyword_monitoring',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/queries': {
        key: 'all_keywords',
        name: intl.formatMessage({
          id: 'report.title.all_keywords',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/keywords/segments': {
        key: 'keywords_segments', // TODO: should migrate key on both side: FE/BE
        name: intl.formatMessage({
          id: 'report.title.keyword_segments',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/keyword-changes': {
        key: 'keywords_changes', // TODO: should migrate key on both side: FE/BE
        name: intl.formatMessage({
          id: 'report.title.keyword_changes',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/new-keywords': {
        key: 'new_keywords',
        name: intl.formatMessage({
          id: 'report.title.new_keywords',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/lost-keywords': {
        key: 'lost_keywords',
        name: intl.formatMessage({
          id: 'report.title.lost_keywords',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/distributions': {
        key: 'distribution',
        name: intl.formatMessage({
          id: 'report.title.distribution',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/pages': {
        key: 'all_pages',
        name: intl.formatMessage({
          id: 'report.title.all_pages',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/pages/segments': {
        key: 'pages_segments', // TODO: should migrate key on both side: FE/BE
        name: intl.formatMessage({
          id: 'report.title.page_segments',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/page-changes': {
        key: 'pages_changes', // TODO: should migrate key on both side: FE/BE
        name: intl.formatMessage({
          id: 'report.title.page_changes',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/directories': {
        key: 'directories',
        name: intl.formatMessage({
          id: 'report.title.directories',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/countries': {
        key: 'countries',
        name: intl.formatMessage({
          id: 'report.title.countries',
          defaultMessage: '',
        }),
      },
      'search-success/analyze/mobiles': {
        key: 'devices',
        name: intl.formatMessage({
          id: 'report.title.devices',
          defaultMessage: '',
        }),
      },
      'search-success/optimize/cannibalisations': {
        key: 'cannibalisations',
        name: intl.formatMessage({
          id: 'report.title.cannibalisations',
          defaultMessage: '',
        }),
      },
      'search-success/optimize/underperformer': {
        key: 'ctr_underperformer',
        name: intl.formatMessage({
          id: 'report.title.ctr_underperformer',
          defaultMessage: '',
        }),
      },
    }),
    []
  );

  const getReportRoute = useCallback(() => {
    const [, , ...pathWithoutProject] = window.location.pathname.split('/');

    // Remove trailing empty string from url
    pathWithoutProject.slice(-1)[0] === '' && pathWithoutProject.pop();

    const shareIdIndex = pathWithoutProject.findIndex((p) => isUUID(p));

    if (shareIdIndex > -1) {
      return pathWithoutProject.slice(0, shareIdIndex).join('/');
    }
    return pathWithoutProject.join('/');
  }, []);

  const getCurrentReport = useCallback(() => {
    const reports = Object.entries(mappingReport).find((i) => {
      return getReportRoute() === i[0];
    });
    return reports?.[1]?.key;
  }, [mappingReport]);

  return { mappingReport, getReportRoute, getCurrentReport };
};
export default useMappingReport;
