export const CSV_TEMPLATE_LINK =
  'https://d3ls7k09kh6nv9.cloudfront.net/ryte_keyword_segment_upload_template.csv';
export const UNPAID_PACKAGE = ['FREE', 'COS'];
export const DATE_FORMAT_AMP = 'DATE_FORMAT_AMP';
export const DATE_FORMAT = 'DATE_FORMAT';
export const USER_ID = 'USER_ID';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const NULL_CPC_VALUE = '--';
export const CURRENCY_DECIMAL = 2;
export const redirectStoreKey = 'redirectUrl';
export const IMPORTING_STATUS = {
  RUNNING: 'RUNNING',
  WAIT_OTHER: 'WAIT_OTHER',
  WAIT_OPERATION: 'WAIT_OPERATION',
  IMPORTING_DONE: 'IMPORTING_DONE',
  DONE: 'DONE',
};

export const GLOBAL_COUNTRY = 'GLB';

export const DEVICES = {
  ANY: 'ANY',
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
};

export const ERROR_TYPE = {
  currentlyUnavailable: 'currentlyUnavailable',
  notFound: 'notFound',
  unexpectedProblem: 'unexpectedProblem',
  noData: 'noData',
};

export const MAX_COMPARING_KEYWORD = 5;

export const BRAND_KEYWORD = 'Brand Keywords';
export const MIGRATED_SEGMENT = [BRAND_KEYWORD];

export const aggregatingDataByPageWithMetric = ['byPage'];
