import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, IntegerInput } from '@ryte/ui-components';

import messages from './messages';
import styles from './styles.module.scss';
import { PaginationProps } from './types';

const FullPagination = (props: PaginationProps) => {
  const range: [number, number] = React.useMemo(
    () => [1, props.totalPages],
    [props.totalPages]
  );
  const nextHandler = React.useCallback(
    () => props.onChange(props.currentPage + 1),
    [props.onChange, props.currentPage]
  );

  const prevHandler = React.useCallback(
    () => props.onChange(props.currentPage - 1),
    [props.onChange, props.currentPage]
  );

  const internalOnEnter = React.useCallback(
    (newPage: number) => {
      const [min, max] = range;
      if (newPage >= min && newPage <= max) {
        props.onChange(newPage);
      }
    },
    [range, props.onChange]
  );

  return (
    <div className={styles.pagination}>
      <div className={styles.pageContainer}>
        <FormattedMessage {...messages.page} />
        <div className={styles.pageInputContainer}>
          <IntegerInput
            onEnter={internalOnEnter}
            value={props.currentPage}
            range={range}
          />
        </div>
        <FormattedMessage
          {...messages.of_pages}
          values={{ pages: props.totalPages }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.left}
          variant={'neutral-inverted'}
          disabled={props.currentPage === 1}
          onClick={prevHandler}
          icon={'chevron_left'}
        />
        <Button
          className={styles.right}
          variant={'neutral-inverted'}
          disabled={props.currentPage === props.totalPages}
          onClick={nextHandler}
          icon={'chevron_right'}
        />
      </div>
    </div>
  );
};

export default FullPagination;
