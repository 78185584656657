export const LOAD_TRAFFIC = 'ses/WeeklyTraffic/LOAD_TRAFFIC';
export const LOAD_TRAFFIC_SUCCESS = 'ses/WeeklyTraffic/LOAD_TRAFFIC_SUCCESS';
export const LOAD_TRAFFIC_ERROR = 'ses/WeeklyTraffic/LOAD_TRAFFIC_ERROR';

export const LOAD_COST_SETTINGS = 'ses/WeeklyTraffic/LOAD_COST_SETTINGS';
export const LOAD_CURRENCY_LIST = 'ses/WeeklyTraffic/LOAD_CURRENCY_LIST';
export const LOAD_COST_SETTINGS_SUCCESS =
  'ses/WeeklyTraffic/LOAD_COST_SETTINGS_SUCCESS';
export const LOAD_COST_SETTINGS_ERROR =
  'ses/WeeklyTraffic/LOAD_COST_SETTINGS_ERROR';

export const UPDATE_COST_SETTINGS = 'ses/WeeklyTraffic/UPDATE_COST_SETTINGS';
export const UPDATE_COST_SETTINGS_SUCCESS =
  'ses/WeeklyTraffic/UPDATE_COST_SETTINGS_SUCCESS';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const UPDATE_CURRENCY = 'ses/WeeklyTraffic/UPDATE_CURRENCY';
export const UPDATE_CURRENCY_SUCCESS =
  'ses/WeeklyTraffic/UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_ERROR = 'ses/WeeklyTraffic/UPDATE_CURRENCY_ERROR';
