import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Badge as UiBadge } from '@ryte/ui-components';

export type BadgeType = 'red' | 'green' | 'blue' | 'yellow' | 'grey';
export type BadgeTextTransform = 'lowercase' | 'uppercase' | 'capitalize';

type BadgeProps = React.PropsWithChildren<{
  className?: string;
  type: BadgeType;
  textTransform?: BadgeTextTransform;
}>;

const Badge = ({ className, type, textTransform, children }: BadgeProps) => {
  const remapBadgeTypes = useMemo(() => {
    switch (type) {
      case 'green':
        return 'positive';
      case 'red':
        return 'negative';
      case 'blue':
        return 'info';
      case 'yellow':
        return 'warning';
      default:
        return 'neutral';
    }
  }, [type]);

  return (
    <span
      className={cn(className, styles.badge, styles[`badge-${textTransform}`])}>
      <UiBadge
        variant={remapBadgeTypes}
        content={children as React.ReactElement}
      />
    </span>
  );
};

export default Badge;
