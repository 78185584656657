import React from 'react';
import type * as Redux from 'redux';
import type { ReactReduxContextValue } from 'react-redux';
import { ReactReduxContext } from 'react-redux';
import { combineReducers } from 'redux';

type EnhancedStore = Redux.Store & {
  injectedReducers: { [index: string]: Redux.Reducer };
};

const useInjectedReducer = (key: string, reducer: Redux.Reducer) => {
  const reduxContext: ReactReduxContextValue =
    React.useContext(ReactReduxContext);

  const store = reduxContext.store as unknown as EnhancedStore;

  if (Reflect.has(store.injectedReducers, key)) {
    // reducer key already exists, check if function is the same
    if (store.injectedReducers[key] === reducer) {
      // reducer function is the same, nothing to do
      return;
    }
    // reducer function is different, replace
    store.injectedReducers[key] = reducer;
    store.replaceReducer(combineReducers({ ...store.injectedReducers }));
  } else {
    // key doesn't exist yet, inject
    store.injectedReducers[key] = reducer;
    store.replaceReducer(combineReducers({ ...store.injectedReducers }));
  }
};

export default useInjectedReducer;
