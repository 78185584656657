import React, { useEffect } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useMixpanel } from '@ryte/mainframe';

import Card from 'app/components/Card';
import { getServicesConfig } from 'app/config';
import PageLayout from 'app/components/PageLayout';
import { ERROR_TYPE } from 'app/parameter';
import messages from './messages';
import { GeneralError } from '@ryte/ui-components';

type ErrorCodeType = {
  titleMessage: MessageDescriptor;
  descriptionMessage: MessageDescriptor;
  buttonMessage: MessageDescriptor;
  onClick: () => void;
};

const ERROR_CODES: Record<string, ErrorCodeType> = {
  'currentlyUnavailable': {
    titleMessage: messages.serviceUnavailableTitle,
    descriptionMessage: messages.serviceUnavailable,
    buttonMessage: messages.tryAgain,
    onClick: () => {
      window.location.replace(window.location.pathname);
    },
  },
  '404': {
    titleMessage: messages.notFoundTitle,
    descriptionMessage: messages.notFound,
    buttonMessage: messages.goBack,
    onClick: () => {
      window.location.href = getServicesConfig().dashboardUrl;
    },
  },
  'unexpectedProblem': {
    titleMessage: messages.unexpectedProblemTitle,
    descriptionMessage: messages.unexpectedProblem,
    buttonMessage: messages.tryAgain,
    onClick: () => {
      window.location.replace(window.location.pathname);
    },
  },
  '5002': {
    titleMessage: messages.noDataTitle,
    descriptionMessage: messages.noDataDesc,
    buttonMessage: messages.tryAgain,
    onClick: () => {
      window.location.replace(window.location.pathname);
    },
  },
};

type Props = {
  error: {
    title: string;
    code?: string | number;
    detail?: string;
  };
};
const ErrorPage: React.FC<Props> = (props) => {
  const { trackEvent } = useMixpanel();
  const errorData =
    ERROR_CODES[props.error.code!] ||
    ERROR_CODES[ERROR_TYPE.currentlyUnavailable];

  const { titleMessage, descriptionMessage, buttonMessage, onClick } =
    errorData;
  const intl = useIntl();

  useEffect(() => {
    trackEvent({
      'eventName': 'SES Error State Seen',
      'Error Reason': props.error.title,
      'Error Code': props.error.code!,
    });
  }, []);

  return (
    <PageLayout reportId="error" introbox={false} className="error-page">
      <Card title={<FormattedMessage {...titleMessage} />}>
        <GeneralError
          title={<FormattedMessage {...titleMessage} />}
          description={
            <FormattedMessage
              {...descriptionMessage}
              values={{ span: (msg: string) => <span>{msg}</span> }}
            />
          }
          errorDetails={
            <>
              {props.error?.detail ? <span>{props.error?.detail}</span> : null}
              {props.error?.code ? <span>{props.error?.code}</span> : null}
            </>
          }
          buttonText={intl.formatMessage(buttonMessage)}
          onButtonClick={onClick}
        />
      </Card>
    </PageLayout>
  );
};

export default ErrorPage;
