import React from 'react';
import { Provider } from 'react-redux';
import configureStore, { EnhancedStore } from './configureStore';

const StoreProvider = React.memo(
  ({ children }: React.PropsWithChildren<{}>) => {
    const store: EnhancedStore = React.useMemo(() => configureStore(), []);

    return <Provider store={store}>{children}</Provider>;
  }
);

export default StoreProvider;
