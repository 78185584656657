import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useValidStartDate,
  useValidEndDate,
  useValidPrevStartDate,
  useValidPrevEndDate,
} from 'app/components/Filters';
import { selectDateRangeAvailability as makeDateRangeAvailabilitySelector } from 'app/containers/App/selectors';

import { DateContextType } from './types';

export const DateContext = React.createContext<DateContextType | null>(null);

const selectDateRangeAvailability = makeDateRangeAvailabilitySelector();

type DateContextProviderProps = React.PropsWithChildren<{}>;

export const DateContextProvider: React.FC<DateContextProviderProps> = ({
  children,
}) => {
  const { oldestDate, latestDate } = useSelector(selectDateRangeAvailability);
  const initialStartDate = useValidStartDate(oldestDate, latestDate);
  const initialEndDate = useValidEndDate(oldestDate, latestDate);
  const initialPrevStartDate = useValidPrevStartDate(
    oldestDate,
    latestDate,
    initialStartDate,
    initialEndDate
  );
  const initialPrevEndDate = useValidPrevEndDate(
    oldestDate,
    latestDate,
    initialStartDate,
    initialEndDate
  );

  const [dateRange, setDateRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    prevStartDate: initialPrevStartDate,
    prevEndDate: initialPrevEndDate,
  });

  const contextValue = React.useMemo(
    () => ({ dateRange, setDateRange }),
    [dateRange, setDateRange]
  );

  return (
    <DateContext.Provider value={contextValue}>{children}</DateContext.Provider>
  );
};

export const useDateContext = () => {
  const ctx = useContext(DateContext);

  if (!ctx) {
    throw new Error('No DateContext found.');
  }

  return ctx;
};
