import { defineMessages } from 'react-intl';

export default defineMessages({
  page: {
    id: 'components.pagination.page',
    defaultMessage: 'Page',
  },
  of_pages: {
    id: 'components.pagination.of_pages',
    defaultMessage: 'of {pages}',
  },
});
