import { datadogRum, RumEvent } from '@datadog/browser-rum';
import {
  MainframeConfiguration,
  MixpanelEventMap,
  initLogger,
  MainframeCore,
  MainframeWrapper,
} from '@ryte/mainframe';
import { ErrorBoundary, ThemeProvider } from '@ryte/ui-components';
import config, {
  getChargebeeConfig,
  getDatadog,
  getMixpanel,
  getServicesConfig,
} from 'app/config';
import App from 'app/containers/App';
import 'flag-icon-css/sass/flag-icon.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import { loadIntlPolyfill, loadProxyPolyfill } from './polyfills';
import './styles/base/global.scss';
import './styles/custom/loader.scss';
import { RedirectToLastProject } from './components/RedirectToLastProject';
import StoreProvider from './store';

initLogger();

enum RumEventType /* Latest version of @datadog/browser-rum is note exporting this enum anymore */ {
  ACTION = 'action',
  ERROR = 'error',
  LONG_TASK = 'long_task',
  VIEW = 'view',
  RESOURCE = 'resource',
}

const isLocalhost = window.location.hostname === 'localhost';
const isProductionAppEnv = process.env.APP_ENVIRONMENT === 'production';
const isProductionBuild = process.env.NODE_ENV === 'production';

if (!isLocalhost && getDatadog().enabled) {
  const shouldDiscardEvent = (event: RumEvent) => {
    return (
      event.type === RumEventType.LONG_TASK ||
      event.type === RumEventType.RESOURCE
    );
  };
  datadogRum.init({
    version: process.env.VERSION,
    applicationId: getDatadog().appId,
    clientToken: getDatadog().token,
    env: getDatadog().env,
    site: 'datadoghq.com',
    service: 'SES',
    sampleRate: 100,
    premiumSampleRate: 0,
    trackInteractions: false,
    beforeSend: (event) => {
      if (shouldDiscardEvent(event)) {
        return false;
      }
    },
  });
}

/**
 * it's possible to run the frontend against the production APIs,
 * but when setting the APP_ENVIRONMENT to production, mixpanel is also configured to use production.
 * This should never happen, if the host is localhost.
 */

const mixpanelConfiguration = {
  token:
    (isProductionBuild || isProductionAppEnv) && isLocalhost
      ? 'fake_token'
      : getMixpanel().token,
  events: [] as any as MixpanelEventMap,
  debug: true,
  Component: 'SES',
  apiHost: getMixpanel().apiHost,
};

const render = () => {
  const mainframeConfiguration: MainframeConfiguration = {
    application: 'ses',
    keycloakUrl: getServicesConfig().keycloakUrl,
    suiteUrl: getServicesConfig().suiteFrontendUrl,
    dashboardUrl: getServicesConfig().dashboardUrl,
    cosUrl: getServicesConfig().cosUrl,
    wesUrl: getServicesConfig().wesUrl,
    sesUrl: `https://${window.location.host}`,
    settingsUrl: getServicesConfig().settingsUrl,
    setupUrl: getServicesConfig().setupUrl,
    oAuthApiUrl: getServicesConfig().oauthApi,
    managementApiUrl: getServicesConfig().managementApiUrl,
    userManagementUrl: getServicesConfig().userManagementUrl,
    chargebeeSite: getChargebeeConfig().site,
    intercomId: config.INTERCOM_APP_ID,
    sorryAppId: config.SORRY_APP_ID,
    delightedAppId: config.DELIGHTED_APP_ID,
    saveReportsApiUrl: getServicesConfig().saveReportApi,
    persistentStorageUrl: getServicesConfig().persistentStorage,
    wuxUrl: getServicesConfig().wuxUrl,
    wuxApiUrl: getServicesConfig().wuxApiUrl,
    mixpanelConfiguration,
    googleConnectApiUrl: getServicesConfig().googleConnectApiUrl,
    authUrl: getServicesConfig().authUrl,
  };
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme="2022" compatMode>
        <ErrorBoundary>
          <MainframeCore
            configuration={mainframeConfiguration}
            calculatePathToLocale={(locale: string) =>
              `/${((window as any).translationSrc || {})[locale]}`
            }>
            <Switch>
              <Route path={`/:projectSlug/search-success`}>
                <StoreProvider>
                  <MainframeWrapper>
                    <App />
                  </MainframeWrapper>
                </StoreProvider>
              </Route>
              <Route path="/">
                <RedirectToLastProject />
              </Route>
            </Switch>
          </MainframeCore>
        </ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('app')
  );
};

/**
 * each load function checks if the polyfill is needed
 * and otherwise just returns a resolved promise.
 *
 * This way only required polyfills are loaded.
 */
Promise.all([loadIntlPolyfill(), loadProxyPolyfill()]).then(render);

// eslint-disable-next-line no-console
console.log(`SES version: ${process.env.VERSION}`);
