import { useCurrentOrganization } from '@ryte/mainframe';
import { Button } from '@ryte/ui-components';
import Banner from 'app/components/Banner';
import messages from 'app/components/Heading/messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {};

const ImportMoreDataBanner: React.FC<Props> = (_props, context) => {
  const [import16monthDataBanner, setImport16monthDataBanner] = useState(true);
  const { role } = useCurrentOrganization();
  const hasPermission: boolean = ['owner', 'manager'].includes(role);
  const intl = useIntl();
  const toggle16MonthDataImport = () => {
    setImport16monthDataBanner((prevStatus) => !prevStatus);
  };

  const enableImport16MonthData = () => {
    context.enableImport16MonthData((status) => {
      status && toggle16MonthDataImport();
    });
  };

  useEffect(() => {
    if (context.checkImport16MonthData) {
      context.checkImport16MonthData();
    }
  }, []);

  if (
    !(
      import16monthDataBanner &&
      context.import16MonthData?.canImportMoreStatus &&
      context.import16MonthData?.quota > 0
    )
  ) {
    return null;
  }

  return (
    <Banner
      type="info"
      icon="data_import"
      onClose={toggle16MonthDataImport}
      title={
        <FormattedMessage
          tagName={'span'}
          {...messages.Import16MonthDataTitle}
          values={{
            number: context.import16MonthData?.numOfMonthCanImportMore,
          }}
        />
      }>
      <FormattedMessage
        tagName={'span'}
        {...messages.Import16MonthDataDesc}
        values={{
          number: context.import16MonthData?.numOfMonthCanImportMore,
        }}
      />
      {hasPermission ? (
        <Button
          onClick={enableImport16MonthData}
          variant="neutral"
          text={intl.formatMessage(messages.enableImport)}
        />
      ) : null}
    </Banner>
  );
};

ImportMoreDataBanner.contextTypes = {
  extraImport: PropTypes.object,
  import16MonthData: PropTypes.object,
  enableImport16MonthData: PropTypes.func,
  checkImport16MonthData: PropTypes.func,
};

export default ImportMoreDataBanner;
