import React from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export enum EventType {
  System = 'system_event',
  User = 'user_event',
}

export const useEventsParams = (
  defaultEvents: EventType[] = [EventType.System, EventType.User]
) => {
  const searchParams = useSearchParams();
  let parsedEvents = parseJson(searchParams.get('events'));
  if (Array.isArray(parsedEvents)) {
    parsedEvents = parsedEvents.filter((e) =>
      Object.values(EventType).includes(e)
    );

    /**
     * In case of parsedEvents are empty after filtered, we don't update it as
     * a defaultEvents because user might want to disable the events by default.
     */
  } else {
    parsedEvents = defaultEvents;
  }
  const [events, internalSetEvents] = React.useState(parsedEvents);
  const { setQuery } = useWrappedFilterContext();

  React.useEffect(() => {
    setQuery({ events });
  }, []);

  const setEvents = React.useCallback(
    (event: EventType) => {
      const newEvents = events.includes(event)
        ? events.filter((e) => e !== event)
        : [...events, event];

      internalSetEvents(newEvents);
      setQuery({ events: newEvents });
    },
    [events, internalSetEvents, setQuery]
  );

  return [events, setEvents];
};
