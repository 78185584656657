import React from 'react';
import cookie from 'react-cookies';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Banner from 'app/components/Banner';
import messages from 'app/components/Heading/messages';
import { IMPORTING_STATUS } from 'app/parameter';

import { ExtraImport } from './types';

type Props = {
  extraImport: ExtraImport;
};

const ImportCompletelyBanner: React.FC<Props> = (props: Props) => {
  const { extraImport } = props;
  const [isClosed, setIsClosed] = React.useState(
    Boolean(cookie.load('EXTRA_IMPORT_BANNER'))
  );
  const closeBanner = () => {
    if (extraImport.dateImportFinish !== null) {
      cookie.save('EXTRA_IMPORT_BANNER', true, {
        expires: moment(extraImport.dateImportFinish).add(30, 'day').toDate(),
      });
      setIsClosed(true);
    }
  };

  if (
    !(
      !isClosed &&
      extraImport.status === IMPORTING_STATUS.DONE &&
      extraImport.dateImportFinish !== null &&
      moment().format('YYYY-MM-DD') <=
        moment(extraImport.dateImportFinish).add(30, 'day').format('YYYY-MM-DD')
    )
  ) {
    return null;
  }

  return (
    <Banner
      type="success"
      icon="data_import"
      onClose={closeBanner}
      title={
        <FormattedMessage
          tagName={'span'}
          {...messages.SuccessExtraImportTitle}
        />
      }>
      <FormattedMessage tagName={'span'} {...messages.SuccessExtraImportDesc} />
    </Banner>
  );
};

export default ImportCompletelyBanner;
