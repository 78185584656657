import React from 'react';

import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export enum Aggregation {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const useAggregationParams = (
  defaultAggregation: Aggregation = Aggregation.Day
) => {
  const searchParams = useSearchParams();
  const aggregationParam = searchParams.get('aggregation');
  const [aggregation, internalSetAggregation] = React.useState(
    Object.values(Aggregation).includes(aggregationParam as Aggregation)
      ? aggregationParam
      : defaultAggregation
  );
  const { setQuery } = useWrappedFilterContext();

  React.useEffect(() => {
    setQuery({ aggregation });
  }, []);

  const setAggregation = React.useCallback(
    (newAggregation: Aggregation) => {
      internalSetAggregation(newAggregation);
      setQuery({ aggregation: newAggregation });
    },
    [internalSetAggregation, setQuery]
  );

  return [aggregation, setAggregation] as [
    typeof aggregation,
    typeof setAggregation
  ];
};
