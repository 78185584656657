import { defineMessages } from 'react-intl';

export default defineMessages({
  Rows: {
    id: 'app.components.Table.Rows',
    defaultMessage: 'rows',
  },
  Show: {
    id: 'app.components.Table.Show',
    defaultMessage: 'Show ',
  },
  Of: {
    id: 'app.components.Table.Of',
    defaultMessage: 'of ',
  },
  Page: {
    id: 'app.components.Table.Page',
    defaultMessage: 'Page ',
  },
  ShowRows: {
    id: 'app.components.Table.ShowRows',
    defaultMessage: 'Show {number} rows',
  },
  ShowNumber: {
    id: 'app.components.TableSetting.ShowNumber',
    defaultMessage: 'Show row numbers',
  },
  DataExport: {
    id: 'app.components.TableSetting.DataExport',
    defaultMessage: 'Data export',
  },
  Download: {
    id: 'app.components.TableSetting.Download',
    defaultMessage: 'Download',
  },
  DownloadResult: {
    id: 'app.components.TableSetting.DownloadResult',
    defaultMessage: 'Download results {number}',
  },
  StartingRow: {
    id: 'app.components.TableSetting.StartingRow',
    defaultMessage: 'Starting row:',
  },
  MaxNumberRow: {
    id: 'app.components.TableSetting.MaxNumberRow',
    defaultMessage: 'Max number of rows:',
  },
  FileFormat: {
    id: 'app.components.TableSetting.FileFormat',
    defaultMessage: 'File format:',
  },
  DownloadDesc: {
    id: 'app.components.TableSetting.DownloadDesc',
    defaultMessage: 'Please select a file format and a limit for your export',
  },
  MaxResultRow: {
    id: 'app.components.TableSetting.MaxResultRow',
    defaultMessage: 'Max number of results:',
  },
  MultipleSortMessage: {
    id: 'app.components.TableSetting.MultipleSortMessage',
    defaultMessage:
      'Click to sort by this column or press and hold the shift-key to sort by multiple columns',
  },
  NoData: {
    id: 'app.components.Table.NoData',
    defaultMessage: 'No data',
  },
});
