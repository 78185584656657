import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { selectDateRangeAvailability as makeDateRangeAvailabilitySelector } from 'app/containers/App/selectors';

import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export const useValidStartDate = (oldestDate, latestDate) => {
  const searchParams = useSearchParams();
  let startDate = searchParams.get('startDate');

  if (startDate === null) {
    startDate = moment(latestDate, 'YYYY-MM-DD')
      .subtract(6, 'days')
      .format('YYYY-MM-DD');
  }

  return startDate < oldestDate || startDate > latestDate
    ? oldestDate
    : startDate;
};

export const useValidEndDate = (oldestDate, latestDate) => {
  const validStartDate = useValidStartDate(oldestDate, latestDate);
  const searchParams = useSearchParams();
  const endDate = searchParams.get('endDate');

  return endDate === null ||
    endDate > latestDate ||
    endDate < oldestDate ||
    endDate < validStartDate
    ? latestDate
    : endDate;
};

export const useValidPrevStartDate = (
  oldestDate,
  latestDate,
  startDate,
  endDate
) => {
  const searchParams = useSearchParams();
  let prevStartDate = searchParams.get('prevStartDate');

  if (prevStartDate === null) {
    const diffInDays =
      moment(endDate, 'YYYY-MM-DD').diff(
        moment(startDate, 'YYYY-MM-DD'),
        'days'
      ) + 1;
    prevStartDate = moment(startDate, 'YYYY-MM-DD')
      .subtract(diffInDays, 'days')
      .format('YYYY-MM-DD');
  }

  return prevStartDate < oldestDate || prevStartDate > latestDate
    ? oldestDate
    : prevStartDate;
};

export const useValidPrevEndDate = (
  oldestDate,
  latestDate,
  startDate,
  endDate
) => {
  const validPrevStartDate = useValidPrevStartDate(
    oldestDate,
    latestDate,
    startDate,
    endDate
  );
  const searchParams = useSearchParams();
  let prevEndDate = searchParams.get('prevEndDate');

  if (prevEndDate === null) {
    const diffInDays =
      moment(endDate, 'YYYY-MM-DD').diff(
        moment(startDate, 'YYYY-MM-DD'),
        'days'
      ) + 1;
    prevEndDate = moment(endDate, 'YYYY-MM-DD')
      .subtract(diffInDays, 'days')
      .format('YYYY-MM-DD');
  }

  return prevEndDate > latestDate ||
    prevEndDate < oldestDate ||
    prevEndDate < validPrevStartDate
    ? latestDate
    : prevEndDate;
};

const selectDateRangeAvailability = makeDateRangeAvailabilitySelector();

export const useDateRangeParams = () => {
  const { oldestDate, latestDate } = useSelector(selectDateRangeAvailability);
  const startDate = useValidStartDate(oldestDate, latestDate);
  const endDate = useValidEndDate(oldestDate, latestDate);
  const prevStartDate = useValidPrevStartDate(
    oldestDate,
    latestDate,
    startDate,
    endDate
  );
  const prevEndDate = useValidPrevEndDate(
    oldestDate,
    latestDate,
    startDate,
    endDate
  );

  const [dateRange, internalSetDateRange] = React.useState({
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
  });
  const { setQuery } = useWrappedFilterContext();

  const setDateRange = React.useCallback(
    (dateRangeParam: { [key: string]: string }) => {
      let paramsWillBeUpdated = {};

      if (dateRangeParam.startDate) {
        paramsWillBeUpdated = {
          ...paramsWillBeUpdated,
          startDate: dateRangeParam.startDate,
        };
      }

      if (dateRangeParam.endDate) {
        paramsWillBeUpdated = {
          ...paramsWillBeUpdated,
          endDate: dateRangeParam.endDate,
        };
      }

      if (dateRangeParam.prevStartDate) {
        paramsWillBeUpdated = {
          ...paramsWillBeUpdated,
          prevStartDate: dateRangeParam.prevStartDate,
        };
      }

      if (dateRangeParam.prevEndDate) {
        paramsWillBeUpdated = {
          ...paramsWillBeUpdated,
          prevEndDate: dateRangeParam.prevEndDate,
        };
      }

      if (Object.keys(paramsWillBeUpdated).length) {
        internalSetDateRange((prevState) => ({
          ...prevState,
          ...paramsWillBeUpdated,
        }));
        setQuery(paramsWillBeUpdated);
      }
    },
    [dateRange, internalSetDateRange, setQuery]
  );

  return [dateRange, setDateRange] as [typeof dateRange, typeof setDateRange];
};
