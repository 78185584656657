import { useSaveShareContext } from 'app/components/SaveShareMenu/useSaveShareContext';
import React, { FC } from 'react';
import { Typography } from '@ryte/ui-components';
import GlobalFilterIcon from 'app/components/PageLayout/GlobalFilterIcon';
import messages from 'app/components/PageLayout/messages';
import { useIntl } from 'react-intl';
import { useSavedReportSelectors } from 'app/hooks/useSavedReportSelectors';
import { SavedReportTitle } from 'app/components/PageLayout/SavedReportTitle';
import { useSaveReportModalProvider } from 'app/components/SaveReportProvider/SaveReportModalContext';

type ReportTitleProps = {
  reportId: string;
};

export const ReportTitle: FC<ReportTitleProps> = (props) => {
  const { savedReport } = useSaveShareContext();

  const intl = useIntl();

  const { reportId } = props;

  const { isEditable } = useSavedReportSelectors();

  const { openSaveReportModal } = useSaveReportModalProvider();

  if (savedReport !== null) {
    return (
      <SavedReportTitle
        name={savedReport.name}
        description={savedReport.description}
        onClick={
          isEditable(savedReport)
            ? () => {
                openSaveReportModal({
                  reportId: savedReport.id,
                });
              }
            : undefined
        }
      />
    );
  }

  return (
    <>
      <Typography variant="heading-1" lineClamp={1}>
        {intl.formatMessage(messages[reportId])}
      </Typography>
      <span style={{ marginLeft: '4px' }}>
        <GlobalFilterIcon tooltip={{ maxWidth: 300 }} />
      </span>
    </>
  );
};
