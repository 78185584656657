import { Button, Icon, Typography } from '@ryte/ui-components';
import cx from 'classnames';
import React, { FC } from 'react';

import UnsavedReportState from '../UnsavedReportState';

import styles from './styles.module.scss';

type SavedReportTitleProps = {
  name: string;
  description: string;
  onClick?: () => void;
};

export const SavedReportTitle: FC<SavedReportTitleProps> = ({
  name,
  description,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={cx(styles.container, onClick && styles.pointer)}>
      <div className={styles.circle}>
        <div>
          <Icon icon={'bookmark'} size={12} />
        </div>
      </div>
      <div>
        <div className={styles['name-section']}>
          <Typography variant={'heading-1'} as="span">
            {name}
          </Typography>
          <UnsavedReportState />
        </div>
        <div className={styles.description}>
          <Typography variant={'text-regular'}>{description}</Typography>
        </div>
      </div>
      <div>
        {onClick && (
          <Button
            icon="edit"
            minified
            variant="neutral"
            className={styles.editButton}
          />
        )}
      </div>
    </div>
  );
};
