import { useDidUpdateEffect } from '@ryte/hooks';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getServicesConfig } from 'app/config';
import parseJson from 'app/utils/parseJson';
import { useHeaders } from 'app/hooks/useHeaders';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';
import { useAuthentication } from '@ryte/mainframe';
import { getNumberFormatSeperator } from 'app/config';
import { useIntl } from 'react-intl';

type OperatorType =
  | 'is'
  | 'is_not'
  | 'contains'
  | 'doesnt_contain'
  | 'starts_with'
  | 'doesnt_start_with'
  | 'ends_with'
  | 'doesnt_end_with'
  | 'equals'
  | 'doesnt_equal'
  | 'is_greater_than'
  | 'is_less_than'
  | 'is_in_segments'
  | 'is_not_in_segments';
type OperatorValue = string | number | string[] | number[];
type ParsedFilter = [string, OperatorType, OperatorValue];
type ParsedFilterGroup = Array<ParsedFilter>;
export type ParsedFilterString = Array<ParsedFilterGroup>;

export const formatDecimalNumber = (key, number, decimalSeparator) => {
  const ignoreMetrics = ['query', 'page'];

  const transformedNumber =
    decimalSeparator === ','
      ? parseFloat(number.toString().replace(/,\s?/g, '.'))
      : number;
  if (!isNaN(transformedNumber) && !ignoreMetrics.includes(key)) {
    return transformedNumber;
  }
  return number;
};

export const useFilterStringParser = () => {
  const intl = useIntl();
  const { decimalSeparator } = getNumberFormatSeperator(intl.locale);
  return (filterString: string) => {
    const filterGroups: ParsedFilterString = parseJson(filterString);
    if (!Array.isArray(filterGroups)) {
      return [];
    }
    const mappedFilterGroups = filterGroups.map((filters) => {
      return filters.map(([key, operator, value]) => {
        return {
          key,
          operator,
          value: Array.isArray(value)
            ? /* @ts-ignore  */
              value.map((v) => formatDecimalNumber(key, v, decimalSeparator))
            : [formatDecimalNumber(key, value, decimalSeparator)],
        };
      });
    });
    return mappedFilterGroups;
  };
};

export const useFilterGroupParser = () => {
  const intl = useIntl();
  const { decimalSeparator } = getNumberFormatSeperator(intl.locale);
  return (filterGroups: any) => {
    return filterGroups.map((filters) => {
      return filters.map(({ key, operator, value }) => {
        return [
          key,
          operator,
          Array.isArray(value)
            ? value.map((v) => formatDecimalNumber(key, v, decimalSeparator))
            : [formatDecimalNumber(key, value, decimalSeparator)],
        ];
      });
    });
  };
};

export const useFilterIdParams = () => {
  const location = useLocation();
  const searchParams = useSearchParams();
  const filterId = searchParams.get('filterId');
  const [filtersParams, setFiltersParams] = React.useState<any>(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const requestHeaders = useHeaders();
  const { fetchAuthenticated } = useAuthentication();

  const getFiltersParams = async (id) => {
    try {
      const res = await fetchAuthenticated(
        `${getServicesConfig().saveReportApi}/filter/${id}`,
        {
          method: 'GET',
          headers: requestHeaders,
        }
      );
      const json = await res.json();

      if (json.data?.content) {
        setFiltersParams(json.data.content.filters);
      }
    } catch (error) {
      // silent
    }
    setIsLoaded(true);
  };

  useDidUpdateEffect(() => {
    setFiltersParams(null);
  }, [location.pathname]);

  React.useEffect(() => {
    if (filterId) {
      getFiltersParams(filterId);
    } else {
      setIsLoaded(true);
    }
  }, [filterId]);

  return { isLoaded, filtersParams, setFiltersParams };
};

const isExceededUrlLength = (filterGroups) => {
  const fullUrlLength = window.location.href.length;
  const searchParams = new URLSearchParams(window.location.search);
  const filterStringLength = searchParams.get('filters')
    ? searchParams.get('filters')!.length
    : 0;
  const filterIdLength = searchParams.get('filerId')
    ? searchParams.get('filerId')!.length
    : 0;
  const stringifyFilterGroups = JSON.stringify(filterGroups);
  const urlLength =
    fullUrlLength -
    filterStringLength -
    filterIdLength +
    stringifyFilterGroups.length;
  const MAX_URL_LENGTH = 12000;

  return urlLength > MAX_URL_LENGTH;
};

export const useFiltersParams = () => {
  const requestHeaders = useHeaders();
  const searchParams = useSearchParams();
  const { fetchAuthenticated } = useAuthentication();
  const parseFilterString = useFilterStringParser();
  const parseFilterGroups = useFilterGroupParser();
  const {
    setQuery,
    filtersParams,
    setFiltersParams: setFiltersParamsFromFilterId,
  } = useWrappedFilterContext();
  const mappedFilterGroups = React.useMemo(
    () =>
      parseFilterString(
        filtersParams
          ? JSON.stringify(filtersParams)
          : searchParams.get('filters')!
      ),
    [searchParams.get('filters'), filtersParams]
  );
  const [filters, internalSetFilters] = React.useState(mappedFilterGroups);

  const setFiltersParams = React.useCallback(
    async (parsedFilterGroups) => {
      try {
        const res = await fetchAuthenticated(
          `${getServicesConfig().saveReportApi}/filter`,
          {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
              content: { filters: parsedFilterGroups },
            }),
          }
        );
        const json = await res.json();

        if (json.data?.id) {
          setQuery({ filters: null });
          setQuery({ filterId: json.data?.id });
        }
      } catch (error) {
        console.error('[FILTERS]: SET_FILTER_PARAMS');
      }
    },
    [setQuery]
  );

  const setFilters = React.useCallback(
    (filterGroups) => {
      const parsedFilterGroups = parseFilterGroups(filterGroups);
      // reset for first use
      if (filtersParams !== null) {
        setFiltersParamsFromFilterId(null);
      }

      if (!isExceededUrlLength(parsedFilterGroups)) {
        setQuery({ filterId: null });
        setQuery({ filters: parsedFilterGroups });
      } else if (JSON.stringify(filterGroups) !== JSON.stringify(filters)) {
        setFiltersParams(parsedFilterGroups);
      }

      internalSetFilters(filterGroups);
    },
    [
      filters,
      internalSetFilters,
      setQuery,
      filtersParams,
      setFiltersParamsFromFilterId,
    ]
  );

  return [filters, setFilters] as [typeof filters, typeof setFilters];
};
