import {
  LOAD_TRAFFIC,
  LOAD_TRAFFIC_SUCCESS,
  LOAD_TRAFFIC_ERROR,
  LOAD_COST_SETTINGS,
  LOAD_COST_SETTINGS_SUCCESS,
  LOAD_COST_SETTINGS_ERROR,
  LOAD_CURRENCY_LIST,
  UPDATE_COST_SETTINGS,
  UPDATE_COST_SETTINGS_SUCCESS,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_ERROR,
} from './constants';

export function loadTraffic(startDate, endDate) {
  return {
    type: LOAD_TRAFFIC,
    startDate,
    endDate,
  };
}

export function loadTrafficSuccess(payload) {
  return {
    type: LOAD_TRAFFIC_SUCCESS,
    payload,
  };
}

export function loadTrafficError(error) {
  return {
    type: LOAD_TRAFFIC_ERROR,
    error,
  };
}

export function loadCostSettings() {
  return {
    type: LOAD_COST_SETTINGS,
  };
}

export function loadCurrencyList(payload) {
  return {
    type: LOAD_CURRENCY_LIST,
    payload,
  };
}

export function loadCostSettingsSuccess(payload) {
  return {
    type: LOAD_COST_SETTINGS_SUCCESS,
    payload,
  };
}

export function loadRecipe(payload) {
  return {
    type: LOAD_COST_SETTINGS_SUCCESS,
    payload,
  };
}

export function loadCostSettingsError(error) {
  return {
    type: LOAD_COST_SETTINGS_ERROR,
    error,
  };
}

export function updateCostSettings(costSettings) {
  return {
    type: UPDATE_COST_SETTINGS,
    costSettings,
  };
}

export function updateCostSettingSuccess() {
  return {
    type: UPDATE_COST_SETTINGS_SUCCESS,
  };
}

export function updateCurrency(currencyCode) {
  return {
    type: UPDATE_CURRENCY,
    currencyCode,
  };
}

export function updateCurrencySuccess() {
  return {
    type: UPDATE_CURRENCY_SUCCESS,
  };
}

export function updateCurrencyError() {
  return {
    type: UPDATE_CURRENCY_ERROR,
  };
}
