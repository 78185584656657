import React from 'react';
import { SaveShareContextType, SaveShareContext } from './types';

export const useSaveShareContext = (): SaveShareContextType => {
  const context = React.useContext(SaveShareContext);

  if (!context) {
    throw new Error(
      'No SaveShare context available. Make sure to render <SaveShareContext> somewhere in the tree.'
    );
  }

  return context;
};
