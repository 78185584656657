import { LOCATION_CHANGE } from 'connected-react-router';
import { TABLE_SETTING } from './index';
import { UPDATE_SETTING, UPDATE_REQUEST_BODY } from './constants';

let initialState = {
  numberRow: false,
  requestBody: {},
  isFirstLoad: true,
};

if (
  localStorage.getItem(TABLE_SETTING()) !== null &&
  JSON.parse(localStorage.getItem(TABLE_SETTING())).numberRow !== null
) {
  initialState = JSON.parse(localStorage.getItem(TABLE_SETTING()));
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        ...action.setting,
      };

    case UPDATE_REQUEST_BODY:
      return {
        ...state,
        requestBody: action.requestBody,
        isFirstLoad: false,
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        isFirstLoad: true,
      };

    default:
      return state;
  }
}

export default appReducer;
