import { useSaveShareContext } from 'app/components/SaveShareMenu/useSaveShareContext';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  const { savedReport, isStateChanged } = useSaveShareContext();
  const getSearch = React.useMemo(() => {
    if (savedReport !== null) {
      const searchParams = new URLSearchParams(savedReport.url);
      if (!isStateChanged || searchParams.toString() === '') {
        return savedReport.url;
      }
    }
    return search;
  }, [savedReport, search, isStateChanged]);
  return new URLSearchParams(getSearch);
};
