import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ScreenOnly, useCurrentProject } from '@ryte/mainframe';
import { Button } from '@ryte/ui-components';

import Setting from 'app/components/Heading/Setting';
import { DISPLAY_FORMAT } from 'app/components/DatePicker/constants';
import { DatePickerButton } from 'app/components/DatePicker';
import Introbox from 'app/components/Introbox';
import SaveShareMenu from 'app/components/SaveShareMenu';
import { selectExtraImport } from 'app/containers/App/selectors';

import ImportCompletelyBanner from './ImportCompletelyBanner';
import ImportMoreDataBanner from './ImportMoreDataBanner';
import ImportProgressButton from './ImportProgressButton';
import ImportStatusBanner from './ImportStatusBanner';
import { ExtraImport } from './types';

import './styles.scss';
import messages from './messages';
import { ReportTitle } from 'app/components/PageLayout/ReportTitle';
import { SaveReportModalProvider } from 'app/components/SaveReportProvider';

type DatePickerProps = {
  show: boolean;
  disabled?: boolean;
  displayFormat: DISPLAY_FORMAT;
};

type Props = React.PropsWithChildren<{
  reportId: string;
  showExtraImport?: boolean;
  showFilter?: boolean;
  showSaveShare?: boolean;
  showSetting?: boolean;
  showPdf?: boolean;
  className?: string;
  filter?: any;
  updateFilter?: Function;
  rightElements?: React.ReactNode[];
  introbox?: boolean;
  actionButtons?: Array<React.ReactNode>;
  datePicker?: undefined | false | DatePickerProps;
}>;

const PageLayout: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const [showFilter, setShowFilter] = useState(false);
  const currentProject = useCurrentProject();
  const extraImport: ExtraImport = useSelector(selectExtraImport());

  React.useEffect(() => {
    const reportName = intl.formatMessage(messages[props.reportId]);
    const projectName = currentProject?.name;
    const tool = intl.formatMessage(messages.organic_performance);

    document.title = `${reportName} | ${projectName} | ${tool}`;
  }, [props.reportId, intl, currentProject]);

  const [showIntrobox, setShowIntrobox] = useState(false);

  const shouldShowDatePicker =
    props.datePicker !== false &&
    props.datePicker !== undefined &&
    props.datePicker.show;

  const toggleAdvancedFilter = () => {
    const filterSelector = document.getElementById('advance-filter');
    if (filterSelector) {
      filterSelector.classList.toggle('hide');

      if (!filterSelector.classList.contains('hide')) {
        const inputElm = filterSelector.querySelector('input');
        setShowFilter(true);

        inputElm?.focus();
        inputElm?.click();
        return;
      }
      setShowFilter(false);
    }
  };

  return (
    <SaveReportModalProvider>
      <section className={`search-success-container ${props.className}`}>
        <section className="dashboard-container">
          <div id="search-success-heading">
            <div className="search-success-heading-wrapper">
              <div className="page-title-container">
                <div className="text-gray main-heading">
                  <ReportTitle reportId={props.reportId} />
                </div>
              </div>
              <div className="action-elements-container">
                {props.showFilter && (
                  <ScreenOnly>
                    <Button
                      variant={showFilter ? 'selected' : 'neutral-inverted'}
                      onClick={() => toggleAdvancedFilter()}
                      icon="filter"
                    />
                  </ScreenOnly>
                )}
                {props.rightElements}
                {shouldShowDatePicker && (
                  <DatePickerButton
                    filter={props.filter}
                    updateFilter={props.updateFilter!}
                    disabled={(props.datePicker as any).disabled}
                    displayFormat={(props.datePicker as any).displayFormat}
                  />
                )}
                {props.showSaveShare && (
                  <SaveShareMenu reportId={props.reportId} />
                )}
                <ImportProgressButton extraImport={extraImport} />
                {props.actionButtons}
                {props.showSetting && (
                  <>
                    <Setting
                      showPdf={props.showPdf!}
                      projectHash={currentProject?.projectHash || ''}
                    />
                    <Button
                      variant={!showIntrobox ? 'neutral-inverted' : 'toggle-on'}
                      icon={'education_hat'}
                      className={
                        showIntrobox
                          ? 'introductionToggleButtonOpaque'
                          : undefined
                      }
                      onClick={() => {
                        (
                          document.querySelector(
                            '.introbox-wrapper button'
                          ) as any
                        ).click();
                        setShowIntrobox(!showIntrobox);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="search-success-wrapper">
            {props.showExtraImport && (
              <>
                <ImportStatusBanner extraImport={extraImport} />
                <ImportMoreDataBanner />
                <ImportCompletelyBanner extraImport={extraImport} />
              </>
            )}
            {props.introbox && (
              <div className={`wrapper ${showIntrobox ? 'show' : ''}`}>
                <div className="introbox-wrapper">
                  <Introbox
                    reportId={props.reportId}
                    onIntroboxToggle={(isCollapsed: boolean) =>
                      setShowIntrobox(!isCollapsed)
                    }
                  />
                </div>
              </div>
            )}
            {props.children}
          </div>
        </section>
      </section>
    </SaveReportModalProvider>
  );
};

export default PageLayout;
