import React, { ComponentPropsWithoutRef } from 'react';
import SaveReportForm from 'app/components/SaveShareMenu/SaveReportForm';

type SaveReportModalProvider = {
  openSaveReportModal: (
    params: ComponentPropsWithoutRef<typeof SaveReportForm>
  ) => void;
  closeSaveReportModal: () => void;
  isOpened: boolean;
};

export const SaveReportModalContext =
  React.createContext<null | SaveReportModalProvider>(null);

export const useSaveReportModalProvider = () => {
  const ctx = React.useContext(SaveReportModalContext);

  if (!ctx) {
    throw new Error('No SaveReportModalContext found.');
  }

  return ctx;
};
