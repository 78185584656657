import {
  FeedbackLauncher,
  IntercomLauncher,
  useNavigateToSettings,
} from '@ryte/mainframe';
import { Button, Popover, PopoverItem } from '@ryte/ui-components';
import { openPrintOption } from 'app/utils/browser.utils';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

type SettingProps = {
  open?: boolean;
  projectHash: string;
  showPdf: boolean;
};
const Setting = ({ open = false, projectHash, showPdf }: SettingProps) => {
  const intl = useIntl();
  const { navigateToProjectSettings } = useNavigateToSettings();
  return (
    <Popover
      open={open}
      trigger={<Button variant="neutral-inverted" icon={'three_dots'} />}>
      {showPdf && (
        <PopoverItem
          text={intl.formatMessage(messages.downloadPdf)}
          icon="download"
          onClick={openPrintOption}
        />
      )}
      <PopoverItem
        text={intl.formatMessage(messages.Setting)}
        icon="settings"
        onClick={() => {
          navigateToProjectSettings(projectHash, 'setup');
        }}
      />
      <IntercomLauncher>
        {(show) => (
          <PopoverItem
            onClick={show}
            text={intl.formatMessage(messages.Support)}
            icon="support"
          />
        )}
      </IntercomLauncher>
      <FeedbackLauncher>
        {(show, title) => (
          <PopoverItem onClick={show} text={title} icon="popup" />
        )}
      </FeedbackLauncher>
    </Popover>
  );
};

export default Setting;
