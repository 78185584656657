import { push } from 'connected-react-router';
import { delay } from 'redux-saga';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateErrorType } from '../App/actions';
import { callApi } from 'app/features/api';
import {
  loadCostSettingsSuccess,
  loadCostSettingsError,
  loadCurrencyList,
} from 'app/containers/WeeklyTraffic/actions';
import { LOAD_COST_SETTINGS } from 'app/containers/WeeklyTraffic/constants';
import {
  updateExtraImport,
  updateAnomalySetting,
  getAnomalySetting,
  loadContext,
  loadRecipeSuccess,
  saveAnomalySettingSuccess,
  loadSmartRecipes,
  setDateRangeAvailability,
  setProjectSettingsLoaded,
  setFreshDataRange,
  setCrawlStatus,
} from './actions';
import {
  GET_ANOMALY_SETTING,
  PROCESS_SAVE_ANOMALY_SETTING,
  SUCCESS_ANOMALY_SAVE,
  LOAD_RECIPE_LIST,
  UPDATE_RECIPE_LOADING,
} from './constants';
import { makeSelectFirstEnteredUrl } from './selectors';

import { REPORT } from '../../apiDefine';
import { dateFormat } from 'app/config';

export function createFetchProjectSettingsSaga(
  { fetchAuthenticated, headers },
  selectImportingInfo,
  slug
) {
  return function* fetchProjectSettings() {
    const firstEnteredUrl = yield select(makeSelectFirstEnteredUrl());
    const importingInfo = selectImportingInfo;
    const extraImport = importingInfo?.extraImport;

    if (extraImport) {
      yield put(
        updateExtraImport(
          extraImport.status,
          extraImport.completedPercent,
          extraImport.dateImportFinish
        )
      );
    }

    const res = yield call(callApi, fetchAuthenticated, {
      resource: '/init',
      method: 'GET',
      headers,
    });

    if (res.error) {
      yield put(setProjectSettingsLoaded());
      return;
    }

    const now = moment().format(dateFormat.default);
    let latestDate = now;
    let oldestDate = now;
    let magicContext = [];
    if (res.data) {
      /* eslint-disable prefer-destructuring */
      latestDate = res.data.availableDays?.latestDate;
      oldestDate = res.data.availableDays?.oldestDate;
      magicContext = res.data.loadingAnimationsData;
      /* eslint-enable prefer-destructuring */
    }

    yield put(loadContext(magicContext));

    const freshDates = res.data.freshDates || {
      startDate: null,
      endDate: null,
    };

    yield put(setDateRangeAvailability(oldestDate, latestDate));
    yield put(setFreshDataRange(freshDates.startDate, freshDates.endDate));
    yield put(setCrawlStatus(res.data.wesCrawlStatus));
    yield put(setProjectSettingsLoaded());

    if (
      firstEnteredUrl.includes(REPORT.EASY_CONNECT) ||
      firstEnteredUrl.includes(REPORT.TRIAL) ||
      firstEnteredUrl.includes(REPORT.LOCK_UI)
    ) {
      yield put(push(`/${slug}/${REPORT.OVERVIEW}`));
    }
  };
}

export function* getAnomalySettingData({ fetchAuthenticated, headers }) {
  const res = yield call(callApi, fetchAuthenticated, {
    resource: '/anomalyDetection/getSetting',
    method: 'GET',
    headers,
  });

  if (res.error) {
    return;
  }

  yield put(updateAnomalySetting(res.data));
}

export function* saveAnomalySetting({ fetchAuthenticated, headers }, action) {
  const res = yield call(callApi, fetchAuthenticated, {
    resource: '/anomalyDetection/updateSetting',
    method: 'PUT',
    headers,
    body: JSON.stringify(action.setting),
  });

  if (res.error) {
    return;
  }

  yield put(saveAnomalySettingSuccess());
}

export function* amonalySuccessSave() {
  try {
    yield put(getAnomalySetting());
  } catch (e) {
    // console.log(e);
  }
}

export function* loadCostSettings({ fetchAuthenticated, headers }) {
  const res = yield call(callApi, fetchAuthenticated, {
    method: 'GET',
    headers,
    resource: '/setting/currency',
  });
  if (res.error) {
    yield put(loadCostSettingsError(res.error));
    return;
  }
  yield put(loadCurrencyList(res.data));
}

export function* loadCPCSetting({ fetchAuthenticated, headers }) {
  const res = yield call(callApi, fetchAuthenticated, {
    method: 'GET',
    resource: '/setting/cpc',
    headers,
  });
  if (res.error) {
    yield put(loadCostSettingsError(res.error));
    return;
  }
  yield put(loadCostSettingsSuccess(res.data));
}

export function* loadRecipes({ fetchAuthenticated, headers }) {
  const res = yield call(callApi, fetchAuthenticated, {
    method: 'GET',
    resource: '/segment/recipes/list',
    headers,
  });
  if (res.error) {
    return;
  }
  yield put(loadRecipeSuccess(res.data));
}

export function* getSmartRecipeList({ fetchAuthenticated, headers }) {
  const res = yield call(callApi, fetchAuthenticated, {
    method: 'GET',
    resource: '/detection/brandKeywords',
    headers,
  });
  if (res.error) {
    yield put(loadSmartRecipes([]));
    const { errors } = res.error.response.parsedJSON;
    yield put(updateErrorType(errors[0]));
    return;
  }
  yield delay(2000);
  yield put(loadSmartRecipes(res.data));
}

export default function createAppSaga(fetchAuthenticated, headers) {
  return function* appSaga() {
    yield takeLatest(PROCESS_SAVE_ANOMALY_SETTING, saveAnomalySetting, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(SUCCESS_ANOMALY_SAVE, amonalySuccessSave);
    yield takeLatest([GET_ANOMALY_SETTING], getAnomalySettingData, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(LOAD_COST_SETTINGS, loadCostSettings, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(LOAD_COST_SETTINGS, loadCPCSetting, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(UPDATE_RECIPE_LOADING, getSmartRecipeList, {
      fetchAuthenticated,
      headers,
    });
    yield takeLatest(LOAD_RECIPE_LIST, loadRecipes, {
      fetchAuthenticated,
      headers,
    });
  };
}
