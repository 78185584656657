type RetryConfiguration = {
  retries?: number;
  delay?: number;
  retryOn?: Array<number>;
};

export type ExtendedOptions = RequestInit & {
  retry?: boolean | RetryConfiguration;
};

type ParsedResponse = Response & { parsedJSON: any };

export class RequestError extends Error {
  response: ParsedResponse;

  constructor(response: ParsedResponse) {
    super(response.statusText);
    this.response = response;
  }
}

const defaultRetryOptions = {
  retries: 3,
  delay: 0,
  retryOn: [408],
};

const getRetryOptions = (retry?: boolean | RetryConfiguration) => {
  if (!retry || retry === false) {
    return { retries: 0, delay: 0, retryOn: [] }; // disables retries
  } else if (retry === true) {
    return defaultRetryOptions;
  }
  return { ...defaultRetryOptions, ...retry };
};

const request = (
  url: string,
  options: ExtendedOptions = {},
  fetchAPI = fetch
): Promise<any> => {
  const { retry: retryOptions, ...fetchOptions } = options;
  const { retryOn, retries, delay } = getRetryOptions(retryOptions);

  return new Promise((resolve, reject) => {
    const wrappedFetch = async (attempt: number) => {
      const response = await fetchAPI(url, fetchOptions);

      if (!retryOn.includes(response.status) || attempt >= retries) {
        const json = await response.json();
        if (response.status >= 200 && response.status < 300) {
          resolve(json);
        } else {
          reject(
            new RequestError(Object.assign(response, { parsedJSON: json }))
          );
        }
      } else {
        setTimeout(() => wrappedFetch(attempt + 1), delay);
      }
    };

    wrappedFetch(0);
  });
};

export default request;
