import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Component to be used instead of `FormattedMessage` from react-intl.
 * It defines some simple html elements like `h2`, `h3`, `p`, `strong` and most importantly `link`,
 * which expects markdown syntax inside to workaround the
 * restriction of tags with attributes not being allowed by react-intl
 *
 * Example link:
 * ```
 * <link>[https://www.example.com](This is the text)</link>
 * ```
 *
 * will be rendered as
 *
 * <a href="https://www.example.com" target="_blank">This is the text</a>
 */
const FormattedRichText = ({
  values,
  ...restProps
}: React.ComponentProps<typeof FormattedMessage>) => {
  const defaultValues = {
    h2: (str: string) => <h2>{str}</h2>,
    h3: (str: string) => <h3>{str}</h3>,
    br: () => <br />,
    p: (str: string) => <p>{str}</p>,
    strong: (str: string) => <strong>{str}</strong>,
    b: (str: string) => <b>{str}</b>,
    ul: (str: string) => <ul>{str}</ul>,
    ol: (str: string) => <ol>{str}</ol>,
    li: (str: string) => <li>{str}</li>,
    link: (str: string) => {
      const [a, b] = str.split('](');

      const href = a.substr(1);
      const text = b.substr(0, b.length - 1);

      return (
        <a href={href} target="_blank">
          {text}
        </a>
      );
    },
  };

  return (
    <FormattedMessage
      {...restProps}
      values={{ ...defaultValues, ...(values || {}) }}
    />
  );
};

export default FormattedRichText;
