import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import moment from 'moment';
import {
  Modal,
  Icon,
  TextInput,
  TextArea,
  Tooltip,
  Checkbox,
  Typography,
} from '@ryte/ui-components';
import { useSavedReports } from '@ryte/saved-reports';
import {
  useAuthentication,
  useTriggerNotificationCallback,
  useMixpanel,
  useCurrentProject,
  useFormatters,
} from '@ryte/mainframe';
import { getServicesConfig } from 'app/config';
import { useHeaders } from 'app/hooks/useHeaders';
import messages from './messages';
import messagesDatePicker from '../DatePicker/messages';
import styles from './saveReportForm.module.scss';
import useMappingReport from './useMappingReport';
import { useSearchParams } from '../Filters/hooks/useSearchParams';
import { useHistory } from 'react-router-dom';
import useValidateForm from './useValidateForm';

const noop = () => {};
type Props = {
  onClose?: () => void;
  reportId?: string;
  onCreated?: () => void;
};
const URL_DATE_FORMAT = 'YYYY-MM-DD';
const URL_PARAMS = {
  startDate: 'startDate',
  endDate: 'endDate',
};

const SaveReportForm: React.FC<Props> = (props) => {
  const [name, setName] = React.useState('');
  const [reportName, setReportName] = React.useState('');
  const [isTouchedName, setIsTouchedName] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [isTouchedDesc, setIsTouchedDesc] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rollingDates, setRollingDate] = React.useState(true);
  const [url, setUrl] = React.useState('');
  const { trackEvent } = useMixpanel();
  const { slug } = useCurrentProject() || {};
  const history = useHistory();
  const intl = useIntl();
  const triggerNotification = useTriggerNotificationCallback();
  const { fetchAuthenticated } = useAuthentication();
  const headers = useHeaders();
  const { getCurrentReport, getReportRoute } = useMappingReport();
  const searchParams = useSearchParams();
  const { formatDate } = useFormatters();
  const [startDate, setStartDate] = React.useState(
    moment(searchParams.get(URL_PARAMS.startDate)!)
  );
  const [endDate, setEndDate] = React.useState(
    moment(searchParams.get(URL_PARAMS.endDate)!)
  );

  const { isValidName, isValidDescription, isValidDates } = useValidateForm({
    name,
    description,
    startDate,
    endDate,
  });

  const handleChangeName = React.useCallback((value) => {
    const MAX_LENGTH = 100;
    if (!isTouchedName && value?.length >= 1) {
      setIsTouchedName(true);
    }

    setName(value?.slice(0, MAX_LENGTH));
  }, []);
  const handleChangeDesc = React.useCallback((value) => {
    const MAX_LENGTH = 200;
    if (!isTouchedDesc && value?.length >= 1) {
      setIsTouchedDesc(true);
    }

    setDescription(value?.slice(0, MAX_LENGTH));
  }, []);

  const savedReportsService = useSavedReports();

  const createReport = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const savedReportName = name.trim().slice(0, 100);
      const id = await savedReportsService.createReport?.({
        name: savedReportName,
        description: description?.slice(0, 200),
        reportName: getCurrentReport(),
        url: `?${searchParams.toString()}`,
        rollingDates,
      });

      if (id) {
        props.onCreated?.();
        triggerNotification({
          type: 'success',
          title: intl.formatMessage(messages.updated_success_title),
        });
        trackEvent({
          'eventName': 'SES Report Saved',
          'Rolling Dates': rollingDates,
        });

        const reportRoute = getReportRoute();

        const reportSlug = savedReportName
          .replace(/\/+/g, '') // remove all slash
          .replace(/ +/g, '-') // replace space with hyphen
          .toLowerCase();

        const newSavedReportRoute = `/${slug}/${reportRoute}/${
          id.id
        }/${encodeURIComponent(
          reportSlug || savedReportName // If report slug empty, use report name instead
        )}?${searchParams.toString()}`;

        history.push(newSavedReportRoute);
      }
    } catch (err) {
      console.log('[ERROR: Save Report]', { err });
    } finally {
      setIsLoading(false);
      props.onClose?.();
    }
  }, [
    headers,
    name,
    description,
    searchParams,
    rollingDates,
    triggerNotification,
    props.onClose,
    props.onCreated,
    getCurrentReport,
  ]);

  const updateReport = React.useCallback(async () => {
    try {
      setIsLoading(true);

      await savedReportsService.updateReport?.({
        id: props.reportId,
        name: name.trim().slice(0, 100),
        description: description?.slice(0, 200),
        reportName: getCurrentReport() ? getCurrentReport() : reportName,
        url,
        rollingDates,
      });

      props.onCreated?.();
      triggerNotification({
        type: 'success',
        title: intl.formatMessage(messages.updated_success_title),
      });
    } catch (err) {
      console.log('[ERROR: Save Report]', { err });
    } finally {
      setIsLoading(false);
      props.onClose?.();
    }
  }, [
    headers,
    name,
    description,
    url,
    rollingDates,
    triggerNotification,
    props.onClose,
    props.onCreated,
    props.reportId,
    getCurrentReport,
  ]);

  const fetchReport = React.useCallback(async (reportId) => {
    try {
      setIsLoading(true);

      const reportData = savedReportsService.data?.find(({ id }) => {
        return id === reportId;
      });

      const data = reportData
        ? reportData
        : (
            await (
              await fetchAuthenticated(
                `${getServicesConfig().saveReportApi}/saveReports/${reportId}`,
                { method: 'GET', headers }
              )
            ).json()
          )?.data;

      if (data) {
        const usps = new URLSearchParams(data.url);
        setStartDate(moment(usps.get(URL_PARAMS.startDate)!, URL_DATE_FORMAT));
        setEndDate(moment(usps.get(URL_PARAMS.endDate)!, URL_DATE_FORMAT));
        setName(data.name);
        setReportName(data.reportName);
        setDescription(data.description);
        setRollingDate(data.rollingDates);
        setUrl(data.url);
      }
    } catch (err) {
      console.log('[ERROR: Save Report]', { err });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const saveHandler = React.useCallback(() => {
    let isValid = true;

    if (!isValidName) {
      isValid = false;
      setIsTouchedName(true);
    }

    if (!isValidDescription) {
      isValid = false;
      setIsTouchedDesc(true);
    }

    if (!isValid) {
      return;
    }

    if (props.reportId) {
      updateReport();
    } else {
      createReport();
    }
  }, [
    isValidName,
    isValidDescription,
    props.reportId,
    createReport,
    updateReport,
  ]);

  React.useEffect(() => {
    if (props.reportId) {
      fetchReport(props.reportId);
    }
  }, []);

  const titleMessage = props.reportId
    ? messages.edit_report
    : messages.save_report;

  const currentReport = getCurrentReport();
  const lastThirtyDays = ['ctr_underperformer', 'cannibalisations'].includes(
    currentReport!
  );

  return (
    <Modal
      onClose={isLoading ? noop : props.onClose}
      title={intl.formatMessage(titleMessage)}
      primaryButtonProps={{
        onClick: saveHandler,
        text: intl.formatMessage(messages.save),
        loading: isLoading,
      }}
      secondaryButtonProps={{
        onClick: props.onClose,
        text: intl.formatMessage(messages.cancel),
        disabled: isLoading,
      }}>
      <div className={styles.modalContent}>
        <div className={cn(styles.group, styles.groupDate)}>
          <div className={styles.dates}>
            <Icon icon="calendar" size={18} />
            {isValidDates ? (
              <div>
                <Typography variant="text-regular">
                  {[
                    formatDate(startDate.unix()),
                    formatDate(endDate.unix()),
                  ].join(' - ')}
                </Typography>
                <FormattedMessage
                  tagName="span"
                  {...messages.number_of_days}
                  values={{ days: endDate.diff(startDate, 'days') + 1 }}
                />
              </div>
            ) : (
              lastThirtyDays && (
                <div>
                  <p>
                    <FormattedMessage
                      {...messagesDatePicker.lastDays}
                      values={{ number: 30 }}
                    />
                  </p>
                </div>
              )
            )}
          </div>
          <div className={styles.rollingDates}>
            <Checkbox
              checked={rollingDates}
              onChange={() =>
                setRollingDate((prevRollingDate) => !prevRollingDate)
              }
              label={intl.formatMessage(messages.roll_dates)}
              disabled={isLoading}
            />
            {isValidDates && (
              <Tooltip
                direction="top"
                maxWidth={300}
                content={intl.formatMessage(messages.rolling_date_tooltip, {
                  startDate: formatDate(
                    startDate.clone().add(7, 'days').unix()
                  ),
                  endDate: formatDate(endDate.clone().add(7, 'days').unix()),
                })}>
                <Icon icon="info" className={styles.icon} />
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.group}>
          <p className={styles.title}>
            <FormattedMessage {...messages.name} />
          </p>
          <TextInput
            value={name}
            onChange={handleChangeName}
            isInvalid={isTouchedName && !isValidName}
            disabled={isLoading}
          />
        </div>
        <div className={styles.group}>
          <p className={styles.title}>
            <FormattedMessage {...messages.description} />
          </p>
          <TextArea
            value={description}
            onChange={handleChangeDesc}
            isInvalid={isTouchedDesc && !isValidDescription}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveReportForm;
