import {
  UPDATE_DETAIL_DASHBOARD,
  UPDATE_FILTER,
  UPDATE_ADVANCE_FILTER,
  UPDATE_ERROR_TYPE,
  UPDATE_EXTRA_IMPORT,
  UPDATE_ANOMALY_SETTING,
  UPDATE_CONFIDENCE_LEVEL,
  GET_ANOMALY_SETTING,
  SUCCESS_ANOMALY_SAVE,
  SAVE_ANOMALY_SETTING,
  PROCESS_SAVE_ANOMALY_SETTING,
  UPDATE_LOADING_CONTEXT,
  LOAD_RECIPE_LIST,
  LOAD_RECIPE_LIST_SUCCESS,
  UPDATE_RECIPE_LOADING,
  UPDATE_EXCLUDE_KEYWORD,
  LOAD_SMART_RECIPES,
  SET_DATE_RANGE_AVAILABILITY,
  SET_PROJECT_SETTINGS_LOADED,
  SET_FRESH_DATES_RANGES,
  SET_CRAWL_STATUS,
} from './constants';

export function getRecipe() {
  return {
    type: LOAD_RECIPE_LIST,
  };
}

export function getSmartRecipes() {
  return {
    type: UPDATE_RECIPE_LOADING,
  };
}

export function updateExcludeKeyword(keyword) {
  return {
    type: UPDATE_EXCLUDE_KEYWORD,
    keyword,
  };
}

export function loadSmartRecipes(data) {
  return {
    type: LOAD_SMART_RECIPES,
    data,
  };
}

export function loadRecipeSuccess(payload) {
  return {
    type: LOAD_RECIPE_LIST_SUCCESS,
    payload,
  };
}

export function updateAnomalySetting(setting) {
  return {
    type: UPDATE_ANOMALY_SETTING,
    setting,
  };
}

export function updateConfidenceLevel(value) {
  return {
    type: UPDATE_CONFIDENCE_LEVEL,
    value,
  };
}

export function saveAnomalySetting(setting) {
  return {
    type: SAVE_ANOMALY_SETTING,
    setting,
  };
}

export function processSaveAnomalySetting(setting) {
  return {
    type: PROCESS_SAVE_ANOMALY_SETTING,
    setting,
  };
}

export function saveAnomalySettingSuccess() {
  return {
    type: SUCCESS_ANOMALY_SAVE,
  };
}

export function getAnomalySetting() {
  return {
    type: GET_ANOMALY_SETTING,
  };
}

export function updateDetailDashboard(detail) {
  return {
    type: UPDATE_DETAIL_DASHBOARD,
    detail,
  };
}

export function updateFilter(filter) {
  return {
    type: UPDATE_FILTER,
    filter,
  };
}

export function updateAdvanceFilter(advanceFilter) {
  return {
    type: UPDATE_ADVANCE_FILTER,
    advanceFilter,
  };
}

export function updateErrorType(error) {
  return {
    type: UPDATE_ERROR_TYPE,
    error,
  };
}

export function updateExtraImport(status, completedPercent, dateImportFinish) {
  return {
    type: UPDATE_EXTRA_IMPORT,
    status,
    completedPercent,
    dateImportFinish,
  };
}

export function loadContext(loadingContext) {
  return {
    type: UPDATE_LOADING_CONTEXT,
    loadingContext,
  };
}

export function setDateRangeAvailability(oldestDate, latestDate) {
  return {
    type: SET_DATE_RANGE_AVAILABILITY,
    payload: {
      oldestDate,
      latestDate,
    },
  };
}

export function setFreshDataRange(startDate, endDate) {
  return {
    type: SET_FRESH_DATES_RANGES,
    payload: {
      startDate,
      endDate,
    },
  };
}

export function setProjectSettingsLoaded() {
  return {
    type: SET_PROJECT_SETTINGS_LOADED,
  };
}

export function setCrawlStatus(wesCrawlStatus) {
  return {
    type: SET_CRAWL_STATUS,
    payload: { wesCrawlStatus },
  };
}
