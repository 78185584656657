import React from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export type Pagination = {
  page: number;
  limit: number;
};

const MIN_PAGE = 1;
const MIN_LIMIT = 10;
const MAX_LIMIT = 1000;

export const usePaginationParams = (
  defaultPagination: Pagination = { page: 1, limit: 100 }
) => {
  const searchParams = useSearchParams();
  const parsedPagination = parseJson(searchParams.get('pagination'));
  let page = MIN_PAGE;
  let limit = MIN_LIMIT;
  if (parsedPagination && Number.isInteger(parsedPagination.page)) {
    page = Math.max(parsedPagination.page, MIN_PAGE);
  }

  if (parsedPagination && Number.isInteger(parsedPagination.limit)) {
    limit = Math.min(Math.max(parsedPagination.limit, MIN_LIMIT), MAX_LIMIT);
  }

  const [pagination, internalSetPagination] = React.useState<Pagination>(
    parsedPagination ? { page, limit } : defaultPagination
  );
  const { setQuery } = useWrappedFilterContext();

  const setPagination = React.useCallback(
    (paginationParam: Pagination) => {
      const newPagination = {
        ...pagination,
        ...paginationParam,
      };

      internalSetPagination(newPagination);
      setQuery({ pagination: newPagination });
    },
    [pagination, internalSetPagination, setQuery]
  );

  return [pagination, setPagination] as [
    typeof pagination,
    typeof setPagination
  ];
};
