import moment from 'moment';
import { createSelector } from 'reselect';

export const selectGlobal = (state) => state.global;

export const selectDateRangeAvailability = () =>
  createSelector(selectGlobal, (state) => {
    const now = moment().format('YYYY-MM-DD');

    return {
      oldestDate: state.oldestDate || now,
      latestDate: state.latestDate || now,
    };
  });

export const selectProjectSettingsLoaded = () =>
  createSelector(selectGlobal, (state) => state.projectSettingsLoaded);

export const selectFreshDates = () =>
  createSelector(selectGlobal, (state) => state.freshDates);

export const makeSelectFirstEnteredUrl = () =>
  createSelector(selectGlobal, (state) => state.firstEnteredUrl);

export const selectError = () =>
  createSelector(selectGlobal, (state) => state.error);

export const selectExtraImport = () =>
  createSelector(selectGlobal, (state) => state.extraImport);

export const selectAnomalySetting = createSelector(
  selectGlobal,
  (state) => state.anomalySetting
);

export const selectTempAnomalySetting = createSelector(
  selectGlobal,
  (state) => state.tempAnomalySetting
);

export const selectLoadingContext = () =>
  createSelector(selectGlobal, (state) => state.loadingContext);

export const selectRecipeList = () =>
  createSelector(selectGlobal, (state) => state.recipeList);

export const selectSmartRecipes = () =>
  createSelector(selectGlobal, (state) => state.smartRecipes);

export const selectCurrentConfidenceLevel = () =>
  createSelector(selectGlobal, (state) => state.currentConfidenceLevel);

export const selectSmartRecipeLoading = () =>
  createSelector(selectGlobal, (state) => state.smartRecipesLoading);

export const selectExcludeKeyword = () =>
  createSelector(selectGlobal, (state) => state.excludeKeyword);

export const selectWesCrawlStatus = createSelector(
  selectGlobal,
  (state) => state.wesCrawlStatus
);
