import React from 'react';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';

import { DateRangeSelectValues } from './constants';
import messages from './messages';
import { STATIC_RANGES } from './StaticRanges';

import { Select } from '@ryte/ui-components';

import styles from './DateRange.module.scss';

export type DateRangeSelectProps = {
  onChange: (newValue: DateRangeSelectValues) => void;
  value: DateRangeSelectValues;
  oldestDate: Moment;
  latestDate: Moment;
};

export const DateRangeSelect = ({
  onChange,
  value,
  oldestDate,
  latestDate,
}: DateRangeSelectProps) => {
  const intl = useIntl();

  const internalOnChange = React.useCallback(
    (selectedIndex) => {
      const dateRangeOption: DateRangeSelectValues =
        DateRangeSelectValues[selectedIndex];

      onChange(dateRangeOption);
    },
    [onChange]
  );

  const availableRanges = React.useMemo(() => {
    return STATIC_RANGES.map((entry) => {
      // If the oldest date is less then {value} {unit} ago, the item should not be selectable.
      // Example: oldestDate was 13 months ago, therefore the 16 month option should be disabled
      if (!entry.value || !entry.unit) {
        // Some range elements don't have unit/value (CUSTOM & MAXIMUM) - but those should always be enabled
        return { ...entry, disabled: false };
      }

      return {
        ...entry,
        disabled: oldestDate.isAfter(
          latestDate.clone().subtract(entry.value, entry.unit)
        ),
      };
    });
  }, [STATIC_RANGES, oldestDate, latestDate]);

  return (
    <div className="selection-box">
      <span>{intl.formatMessage(messages.dateRange)}</span>
      <Select
        className={styles.select}
        onChange={internalOnChange}
        value={value}
        options={availableRanges.map(({ option, message, disabled }) => ({
          value: option,
          label: intl.formatMessage(message),
          isDisabled: disabled,
        }))}
      />
    </div>
  );
};
