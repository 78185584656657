export const UPDATE_DETAIL_DASHBOARD = 'ses/App/UPDATE_DETAIL_DASHBOARD';
export const UPDATE_FILTER = 'ses/App/UPDATE_FILTER';
export const UPDATE_ADVANCE_FILTER = 'ses/App/UPDATE_ADVANCE_FILTER';
export const UPDATE_ERROR_TYPE = 'ses/App/UPDATE_ERROR_TYPE';
export const UPDATE_EXTRA_IMPORT = 'ses/App/UPDATE_EXTRA_IMPORT';
export const UPDATE_ANOMALY_SETTING = 'ses/App/UPDATE_ANOMALY_SETTING';
export const UPDATE_CONFIDENCE_LEVEL = 'ses/App/UPDATE_CONFIDENCE_LEVEL';
export const UPDATE_RECIPE_LOADING = 'ses/App/UPDATE_RECIPE_LOADING';
export const UPDATE_EXCLUDE_KEYWORD = 'ses/App/UPDATE_EXCLUDE_KEYWORD';
export const LOAD_SMART_RECIPES = 'ses/App/LOAD_SMART_RECIPES';
export const SAVE_ANOMALY_SETTING = 'ses/App/SAVE_ANOMALY_SETTING';
export const PROCESS_SAVE_ANOMALY_SETTING =
  'ses/App/PROCESS_SAVE_ANOMALY_SETTING';
export const GET_ANOMALY_SETTING = 'ses/App/GET_ANOMALY_SETTING';
export const SUCCESS_ANOMALY_SAVE = 'ses/App/SUCCESS_ANOMALY_SAVE';
export const UPDATE_LOADING_CONTEXT = 'ses/App/UPDATE_LOADING_CONTEXT';
export const LOAD_RECIPE_LIST = 'ses/App/LOAD_RECIPE_LIST';
export const LOAD_RECIPE_LIST_SUCCESS = 'ses/App/LOAD_RECIPE_LIST_SUCCESS';
export const SET_DATE_RANGE_AVAILABILITY = 'ses/SET_DATE_RANGE_AVAILABILITY';
export const SET_FRESH_DATES_RANGES = 'ses/SET_FRESH_DATES_RANGES';
export const SET_PROJECT_SETTINGS_LOADED = 'ses/SET_PROJECT_SETTINGS_LOADED';
export const SET_CRAWL_STATUS = 'ses/SET_CRAWL_STATUS';
