import { unitOfTime } from 'moment';
import { MessageDescriptor } from 'react-intl';

import { DateRangeSelectValues } from './constants';
import messages from './messages';

type StaticRangeType = {
  option: DateRangeSelectValues;
  message: MessageDescriptor;
  unit?: unitOfTime.Base;
  value?: number;
};

export const STATIC_RANGES: Array<StaticRangeType> = [
  {
    option: DateRangeSelectValues.SEVEN_DAYS,
    value: 6,
    unit: 'days',
    message: messages.sevenDays,
  },
  {
    option: DateRangeSelectValues.ONE_MONTH,
    value: 1,
    unit: 'months',
    message: messages.oneMonth,
  },
  {
    option: DateRangeSelectValues.THREE_MONTHS,
    value: 3,
    unit: 'months',
    message: messages.threeMonths,
  },
  {
    option: DateRangeSelectValues.SIX_MONTHS,
    value: 6,
    unit: 'months',
    message: messages.sixMonths,
  },
  {
    option: DateRangeSelectValues.TWELVE_MONTHS,
    value: 12,
    unit: 'months',
    message: messages.twelveMonths,
  },
  {
    option: DateRangeSelectValues.SIXTEEN_MONTHS,
    value: 16,
    unit: 'months',
    message: messages.sixteenMonths,
  },
  {
    option: DateRangeSelectValues.MAXIMUM,
    message: messages.maximum,
  },
  {
    option: DateRangeSelectValues.CUSTOM,
    message: messages.customRange,
  },
];
