import React from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export const useComparingParams = () => {
  const searchParams = useSearchParams();
  const parsedComparing = parseJson(searchParams.get('comparing'));
  const [comparing, internalSetComparing] = React.useState<string[] | number[]>(
    Array.isArray(parsedComparing) ? parsedComparing : []
  );
  const { setQuery } = useWrappedFilterContext();

  const setComparing = React.useCallback(
    (ids: string[] | number[]) => {
      internalSetComparing(ids);
      setQuery({ comparing: ids });
    },
    [comparing, internalSetComparing, setQuery]
  );

  return [comparing, setComparing] as [typeof comparing, typeof setComparing];
};
