import { defineMessages } from 'react-intl';

export default defineMessages({
  ComparisonDays: {
    id: 'app.components.Heading.ComparisonDays',
    defaultMessage: 'Previous {number} days',
  },
  ComparisonDay: {
    id: 'app.components.Heading.ComparisonDay',
    defaultMessage: 'Previous {number} day',
  },
  PreviousDay: {
    id: 'app.components.Heading.PreviousDay',
    defaultMessage: 'Last {number} days',
  },
  AboutReport: {
    id: 'app.components.Heading.AboutReport',
    defaultMessage: 'About this report',
  },
  Setting: {
    id: 'app.components.Heading.Setting',
    defaultMessage: 'Settings',
  },
  Support: {
    id: 'features.report.secondary_page_actions.support',
    defaultMessage: 'Support',
  },
  Filter: {
    id: 'app.containers.Common.Filter',
    defaultMessage: 'Filter',
  },
  ExtraImportDesc: {
    id: 'app.containers.Heading.ExtraImportDesc',
    defaultMessage:
      'The import of 16 months Google Search Console data is running in the background, but you can still use Organic Performance.',
  },
  ExtraImportTitle: {
    id: 'app.containers.Heading.ExtraImportTitle',
    defaultMessage: 'Data import is running ({completedPercent}%)',
  },
  SuccessExtraImportDesc: {
    id: 'app.containers.Heading.SuccessExtraImportDesc',
    defaultMessage:
      "Your Google Search Console data is now saved indefinitely in Organic Performance! This means you're no longer restricted to the 16 month data storage limit in the Google Search Console. Enjoy analyzing and optimizing your website with more data!",
  },
  SuccessExtraImportTitle: {
    id: 'app.containers.Heading.SuccessExtraImportTitle',
    defaultMessage: 'Datenimport erfolgreich abgeschlossen',
  },
  Import16MonthDataTitle: {
    id: 'app.containers.Heading.Import16MonthDataTitle',
    defaultMessage: 'Import {number} months of data for free',
  },
  Import16MonthDataDesc: {
    id: 'app.containers.Heading.Import16MonthDataDesc',
    defaultMessage:
      "You now have a possibility to import your Google Search Console data for the last {number} month. Use this functionality to improve your analysis and get a new valuable insights. This won't affect your possibility to use the tool.",
  },
  enableImport: {
    id: 'app.containers.Heading.enableImport',
    defaultMessage: 'Yes I want the data!',
  },
  previousPeriod: {
    id: 'app.components.DateRangePicker.previousPeriod',
    defaultMessage: 'Previous period',
  },
  downloadPdf: {
    id: 'app.settings.downloadPdf',
    defaultMessage: 'Download PDF',
  },
});
