import messages from './messages';

export const START_DATE = 'startDate';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD';

export enum DISPLAY_FORMAT {
  LAST_DAYS = 'LAST_DAYS',
  DATE_RANGE = 'DATE_RANGE',
  PREVIOUS_DAYS = 'PREVIOUS_DAYS',
  COMPARISON = 'COMPARISON',
}

export enum DateRangeSelectValues {
  SEVEN_DAYS = 'SEVEN_DAYS',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
  SIXTEEN_MONTHS = 'SIXTEEN_MONTHS',
  MAXIMUM = 'MAXIMUM',
  CUSTOM = 'CUSTOM',
}

// these represent the number of days for each select value. 7 = days, 480 = 16 months
export const DATE_RANGE_DIFF = {
  7: DateRangeSelectValues.SEVEN_DAYS,
  30: DateRangeSelectValues.ONE_MONTH,
  90: DateRangeSelectValues.THREE_MONTHS,
  180: DateRangeSelectValues.SIX_MONTHS,
  364: DateRangeSelectValues.TWELVE_MONTHS,
  480: DateRangeSelectValues.SIXTEEN_MONTHS,
};

export const COMPARED_DATE_RANGES = {
  PREVIOUS_PERIOD: 'PREVIOUS_PERIOD',
  PREVIOUS_YEAR: 'PREVIOUS_YEAR',
  PREVIOUS_QUARTER: 'PREVIOUS_QUARTER',
  PREVIOUS_MONTH: 'PREVIOUS_MONTH',
  CUSTOM: 'CUSTOM',
};

export const COMPARED_DATE_RANGES_MSGS = {
  PREVIOUS_PERIOD: messages.previousPeriod,
  PREVIOUS_YEAR: messages.previousYear,
  PREVIOUS_QUARTER: messages.previousQuarter,
  PREVIOUS_MONTH: messages.previousMonth,
  CUSTOM: messages.customRange,
};

export const RELATIONSHIP = {
  [DateRangeSelectValues.SEVEN_DAYS]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [7, 7],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [7, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [7, 90],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [7, 30],
    [COMPARED_DATE_RANGES.CUSTOM]: [7, 7],
  },
  [DateRangeSelectValues.ONE_MONTH]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [30, 30],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [30, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [30, 90],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [30, 30],
    [COMPARED_DATE_RANGES.CUSTOM]: [30, 30],
  },
  [DateRangeSelectValues.THREE_MONTHS]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [90, 90],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [90, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [90, 90],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [90, null],
    [COMPARED_DATE_RANGES.CUSTOM]: [90, 90],
  },
  [DateRangeSelectValues.SIX_MONTHS]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [180, 180],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [180, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [180, null],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [180, null],
    [COMPARED_DATE_RANGES.CUSTOM]: [180, 180],
  },
  [DateRangeSelectValues.TWELVE_MONTHS]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [364, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [364, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [364, null],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [364, null],
    [COMPARED_DATE_RANGES.CUSTOM]: [364, 364],
  },
  [DateRangeSelectValues.SIXTEEN_MONTHS]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [480, 480],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [480, null],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [480, null],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [480, null],
    [COMPARED_DATE_RANGES.CUSTOM]: [480, 480],
  },
  [DateRangeSelectValues.MAXIMUM]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [0, null],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [0, null],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [0, null],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [0, null],
    [COMPARED_DATE_RANGES.CUSTOM]: [1, null],
  },
  [DateRangeSelectValues.CUSTOM]: {
    [COMPARED_DATE_RANGES.PREVIOUS_PERIOD]: [1, 0],
    [COMPARED_DATE_RANGES.PREVIOUS_YEAR]: [1, 364],
    [COMPARED_DATE_RANGES.PREVIOUS_QUARTER]: [1, 180],
    [COMPARED_DATE_RANGES.PREVIOUS_MONTH]: [1, 30],
    [COMPARED_DATE_RANGES.CUSTOM]: [1, 0],
  },
};

export const dateFormats: Record<string, string> = {
  'm/d/Y': 'MM/DD/YYYY',
  'd.m.Y': 'DD.MM.YYYY',
  'Y-m-d': 'YYYY-MM-DD',
};
