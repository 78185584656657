import { UPDATE_SETTING, UPDATE_REQUEST_BODY } from './constants';

export function updateSetting(setting) {
  return {
    type: UPDATE_SETTING,
    setting,
  };
}

export function updateBodyRequest(requestBody) {
  return {
    type: UPDATE_REQUEST_BODY,
    requestBody,
  };
}
