import React from 'react';
import {
  WrappedFilterContextType,
  WrappedFilterContext,
} from '../WrappedFilterContext';

export const useWrappedFilterContext = (): WrappedFilterContextType => {
  const filterContext = React.useContext(WrappedFilterContext);

  if (!filterContext) {
    throw new Error(
      'No Filter context available. Make sure to render <WrappedFilterContext> somewhere in the tree.'
    );
  }

  return filterContext;
};
