import React from 'react';
import { BannerProps, Banner } from '@ryte/ui-components';

import './styles.scss';

const WrappedBanner = (props: BannerProps) => {
  return (
    <div className="banner">
      <Banner {...props} />
    </div>
  );
};

export default WrappedBanner;
