import React from 'react';

import { ParsedFilterString } from './hooks/useFiltersParams';

type QueryParams = { [constant: string]: any };

export type WrappedFilterContextType = {
  setQuery: (query: QueryParams) => void;
  isLoaded: boolean;
  filtersParams: ParsedFilterString;
  setFiltersParams: (filterGroups: ParsedFilterString | null) => void;
};

export const WrappedFilterContext =
  React.createContext<WrappedFilterContextType | null>(null);
