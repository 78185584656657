import { useCallback, useState } from 'react';

export const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  const open = useCallback(() => {
    setIsOpened(true);
  }, []);

  const close = useCallback(() => {
    setIsOpened(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  return {
    open,
    close,
    isOpened,
    toggle,
  };
};
