import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSaveShareContext } from '../SaveShareMenu/useSaveShareContext';

import { useFilterIdParams } from './hooks/useFiltersParams';
import { useSearchParams } from './hooks/useSearchParams';
import { WrappedFilterContext } from './WrappedFilterContext';

export type WrappedFilterConfigurationProviderProps = React.PropsWithChildren<{
  // storeIdentifier: string;
}>;

export const WrappedFilterConfigurationProvider = (
  props: WrappedFilterConfigurationProviderProps
) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const { isLoaded, filtersParams, setFiltersParams } = useFilterIdParams();
  const sharedIdData = useSaveShareContext();
  const searchParamsFromSavedReport = React.useMemo(() => {
    if (sharedIdData.savedReport !== null) {
      const params = new URLSearchParams(sharedIdData.savedReport.url);
      return params;
    }
    return '';
  }, [sharedIdData.savedReport]);
  const setQuery = React.useCallback(
    (query) => {
      Object.entries(query).forEach(([name, value]: [string, any]) => {
        if (value !== null) {
          searchParams.set(
            name,
            typeof value === 'string' ? value : JSON.stringify(value)
          );
        } else {
          searchParams.delete(name);
        }
      });
      if (isLoaded && sharedIdData.savedReport === null) {
        history.replace({
          search: `?${searchParams.toString()}`,
        });
      } else {
        if (searchParamsFromSavedReport.toString() !== '') {
          if (
            searchParamsFromSavedReport.toString() !== searchParams.toString()
          ) {
            history.replace({
              search: `?${searchParams.toString()}`,
            });
          } else {
            history.replace({
              search: '',
            });
          }
        }
      }
    },
    [
      searchParams,
      isLoaded,
      sharedIdData.savedReport,
      searchParamsFromSavedReport,
    ]
  );

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    sharedIdData.updateSavedReport(null);
  };

  React.useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return window.removeEventListener('popstate', onBackButtonEvent);
  }, []);

  React.useEffect(() => {
    return history.listen(() => {
      if (history.action === 'PUSH') {
        sharedIdData.updateSavedReport(null);
      }
      if (history.action === 'POP') {
        sharedIdData.updateSavedReport(null);
      }
    });
  }, [history]);

  return (
    <WrappedFilterContext.Provider
      value={{ setQuery, isLoaded, filtersParams, setFiltersParams }}>
      {props.children}
    </WrappedFilterContext.Provider>
  );
};
