import React from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export type SortingDirection = 'DESC' | 'ASC';
export type QuerySorting = {
  attribute: string;
  direction: SortingDirection;
};
export type QuerySortingTuple = [string, SortingDirection];

/**
 * This callback toggles an attribute sorting from "not sorted" -> DESC -> ASC -> "not sorted"
 */
export const toggleSorting = (
  sorting: QuerySorting[],
  attribute: string,
  additive?: boolean
): QuerySorting[] => {
  const existingSorting = sorting.find((x) => x.attribute === attribute);

  if (existingSorting !== undefined && existingSorting.direction === 'DESC') {
    // already sorted DESC, change to ASC
    return additive
      ? sorting.map((x) =>
          x.attribute === attribute ? { attribute, direction: 'ASC' } : x
        )
      : [{ attribute, direction: 'ASC' }];
  }

  return additive
    ? [...sorting, { attribute, direction: 'DESC' }]
    : [{ attribute, direction: 'DESC' }];
};

export const useSortingParams = (
  defaultSorting: QuerySortingTuple[] = [],
  validAttributes: string[] = []
) => {
  const searchParams = useSearchParams();
  let parsedSorting = parseJson(searchParams.get('sorting'));

  if (Array.isArray(parsedSorting) && parsedSorting.length > 0) {
    parsedSorting = parsedSorting.map((s) => {
      const [attribute, direction] = s;

      if (['DESC', 'ASC'].includes(direction?.toUpperCase())) {
        return s;
      }

      return [attribute, 'DESC'];
    });

    parsedSorting = parsedSorting.filter(([attribute]) =>
      validAttributes.includes(attribute)
    );

    if (parsedSorting.length === 0) {
      parsedSorting = defaultSorting;
    }
  } else {
    parsedSorting = defaultSorting;
  }

  const [sorting, internalSetSorting] = React.useState(parsedSorting);
  const { setQuery } = useWrappedFilterContext();

  const setSorting = React.useCallback(
    (newSorting) => {
      internalSetSorting(newSorting);
      setQuery({ sorting: newSorting });
    },
    [internalSetSorting, setQuery]
  );

  return [sorting, setSorting];
};
