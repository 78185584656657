import moment from 'moment';

const validateName = (name: string) => {
  if (!name || name?.length > 100) return false;
  if (name?.length === 1 && name[0] === '/') return false;
  return true;
};

const validateDescription = (desc: string) => desc?.length <= 200;

const useValidateForm = ({
  name,
  description,
  startDate,
  endDate,
}: {
  name: string;
  description: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}) => {
  const isValidName = validateName(name);
  const isValidDescription = validateDescription(description);
  const isValidDates = startDate.isValid() && endDate.isValid();

  return {
    isValidName,
    isValidDescription,
    isValidDates,
  };
};

export default useValidateForm;
