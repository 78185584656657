import { createSelector } from 'reselect';

import { REPORT, getKey } from '../../apiDefine';

export const selectWeeklyTraffic = (state) =>
  state[getKey(REPORT.WEEKLY_TRAFFIC)];

export const selectWeeklyTrafficLoadingChart = () =>
  createSelector(selectWeeklyTraffic, (state) => state.loadingChart);

export const selectWeeklyTrafficLoadingCpc = () =>
  createSelector(selectWeeklyTraffic, (state) => state.loadingCpc);

export const selectWeeklyTrafficChart = () =>
  createSelector(selectWeeklyTraffic, (state) => state.chart);

export const selectCpcSettings = () =>
  createSelector(selectWeeklyTraffic, (state) => state.cpcSetting);

export const selectCurrencySettings = () =>
  createSelector(selectWeeklyTraffic, (state) => state.currencySetting);

export const selectCurrencyList = () =>
  createSelector(selectWeeklyTraffic, (state) => state.currencyList);

export const selectStartDate = () =>
  createSelector(selectWeeklyTraffic, (state) => state.startDate);

export const selectEndDate = () =>
  createSelector(selectWeeklyTraffic, (state) => state.endDate);
