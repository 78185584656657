type Config = {
  [constant: string]: any;
};

/* gets a specific property from the configuration object that lives in window */
const getConfigProperty = (propertyName: string) => {
  const config = (<any>window).configuration;
  if (!config || !config[propertyName]) {
    // eslint-disable-next-line no-console
    console.error('could not load configuration.js!');
    return '';
  }
  return config[propertyName];
};

export const getServicesConfig = () => getConfigProperty('SERVICES');
export const getSlackConfig = () => getConfigProperty('SLACK');
export const getChargebeeConfig = () => getConfigProperty('CHARGEBEE');
type Mixpanel = {
  token: string;
  apiHost: string;
  enabled: boolean;
};
export const getMixpanel = (): Mixpanel => getConfigProperty('MIXPANEL');

type Datadog = {
  appId: string;
  token: string;
  env: 'production' | 'testing';
  enabled: boolean;
};
export const getDatadog = (): Datadog => getConfigProperty('DATADOG');

export const getNumberFormatSeperator = (locale: string) => {
  let decimalSeparator = '.';
  let thousandSeparator = ',';

  if (locale !== 'en') {
    decimalSeparator = ',';
    thousandSeparator = '.';
  }
  return { decimalSeparator, thousandSeparator };
};

const genDateFormat = (): string => {
  const fullStringDate = new Date(2030, 11, 24).toLocaleDateString();
  return fullStringDate
    .replace('2030', 'YYYY')
    .replace('12', 'MM')
    .replace('24', 'DD');
};

export const dateFormat = {
  default: genDateFormat(),
  amcharts: genDateFormat(),
  eventCreationFormat: 'YYYY-MM-DD',
};

const config: Config = {
  INTERCOM_APP_ID: 'up4jqyum',
  SORRY_APP_ID: 'b89f3287',
  DELIGHTED_APP_ID: '1LkRawZl07YE7Qdq',
};

export default config;
