import { SaveProfile } from 'app/components/SaveShareMenu/types';
import { useCallback } from 'react';
import { useCurrentUser, useCurrentOrganization } from '@ryte/mainframe';

export const useSavedReportSelectors = () => {
  const { id: userId } = useCurrentUser();
  const organization = useCurrentOrganization();
  const isOrganizationOwner = organization?.role === 'owner';

  const isEditable = useCallback(
    (savedReport: Pick<SaveProfile, 'ownerId'>) => {
      const doesBelongToTheUser = userId === savedReport?.ownerId;
      return isOrganizationOwner || doesBelongToTheUser;
    },
    [isOrganizationOwner, userId]
  );

  return {
    isEditable,
  };
};
