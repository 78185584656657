import React from 'react';
import cx from 'classnames';
import { Card as CardComponent, CardProps } from '@ryte/ui-components';

import styles from './styles.module.scss';

/**
 * Simple wrapper component to attach an extra class for spacing between cards
 */
const Card = ({ className, ...restProps }: CardProps) => (
  <CardComponent {...restProps} className={cx(styles.Card, className)} />
);

export default Card;
