import React, { lazy, Suspense } from 'react';

const Loadable = (
  importFunc: any,
  { fallback }: { fallback: React.ReactElement | null } = { fallback: null }
) => {
  const LazyLoadedComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyLoadedComponent {...props} />
    </Suspense>
  );
};

export default Loadable;
