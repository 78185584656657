import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    id: 'app.components.ErrorPage.error',
    defaultMessage: 'Error',
  },
  goBack: {
    id: 'app.components.ErrorPage.goBack',
    defaultMessage: 'Go back',
  },
  tryAgain: {
    id: 'app.components.ErrorPage.tryAgain',
    defaultMessage: 'Try again',
  },
  notFoundTitle: {
    id: 'app.components.ErrorPage.notFoundTitle',
    defaultMessage: '404 not found',
  },
  notFound: {
    id: 'app.components.ErrorPage.notFound',
    defaultMessage:
      "Looks like you got lost. But don't worry, Mr Ryte will take you back.",
  },
  unexpectedProblemTitle: {
    id: 'app.components.ErrorPage.unexpectedProblemTitle',
    defaultMessage: 'Unexpected problem',
  },
  unexpectedProblem: {
    id: 'app.components.ErrorPage.unexpectedProblem',
    defaultMessage:
      'An unexpected problem occurred. Mr Ryte’s taking care of it.',
  },
  serviceUnavailableTitle: {
    id: 'app.components.ErrorPage.serviceUnavailableTitle',
    defaultMessage: 'Currently unavailable',
  },
  serviceUnavailable: {
    id: 'app.components.ErrorPage.serviceUnavailable',
    defaultMessage:
      'App currently unavailable. Mr Ryte’s probably taking a little break.',
  },
  noDataTitle: {
    id: 'null_state.title',
    defaultMessage: 'No data available yet',
  },
  noDataDesc: {
    id: 'null_state.description',
    defaultMessage:
      '<span>Have you just launched your website or created a Google Search Console property?</span><span>Please be patient. It might take a few days for Google to provide the search data.</span>',
  },
});
