import { useEffect, useState } from 'react';
import cookie from 'react-cookies';

let setters: any[] = [];

const useImportStatus = () => {
  const [shouldShowBanner, set] = useState(
    Boolean(cookie.load('RUNNING_EXTRA_IMPORT_BANNER'))
  );

  const toggleImportStatus = () => {
    setters.forEach((setter) => {
      setter((prevStatus) => {
        cookie.remove('RUNNING_EXTRA_IMPORT_BANNER');
        cookie.save('RUNNING_EXTRA_IMPORT_BANNER', !prevStatus);

        return !prevStatus;
      });
    });
  };

  useEffect(() => {
    if (!setters.includes(set)) {
      setters = [...setters, set];
    }

    return () => {
      setters = setters.filter((setter) => setter !== set);
    };
  }, []);

  return [shouldShowBanner, toggleImportStatus];
};

export default useImportStatus;
