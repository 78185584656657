import { Button, Tooltip } from '@ryte/ui-components';
import messages from 'app/components/Heading/messages';
import { IMPORTING_STATUS } from 'app/parameter';
import React from 'react';
import { useIntl } from 'react-intl';
import { ExtraImport } from './types';
import useImportStatus from './useImportStatus';

type Props = {
  extraImport: ExtraImport;
};

const ImportProgressButton: React.FC<Props> = (props) => {
  const { extraImport } = props;
  const intl = useIntl();
  const [shouldShow, setShouldShow] = useImportStatus();

  if (
    !(
      shouldShow &&
      extraImport.dateImportFinish === null &&
      extraImport.status === IMPORTING_STATUS.RUNNING
    )
  ) {
    return <></>;
  }

  return (
    <Tooltip
      content={intl.formatMessage(messages.ExtraImportTitle, {
        completedPercent: extraImport.completedPercent.toString(),
      })}
      direction="bottom"
      delayTimer={500}>
      <Button
        variant="neutral-inverted"
        icon="reload"
        disabled
        iconSpin
        onClick={setShouldShow as () => void}
      />
    </Tooltip>
  );
};

export default ImportProgressButton;
