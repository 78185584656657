import { getServicesConfig } from 'app/config';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useHeaders } from 'app/hooks/useHeaders';
import useMappingReport from './useMappingReport';
import {
  useAuthentication,
  useTriggerNotificationCallback,
} from '@ryte/mainframe';
import messages from './messages';
import { useIntl } from 'react-intl';
import { SaveShareContext } from './types';
import { useShareIdParams } from './useShareIdParams';

export type SaveShareConfigurationProviderProps = React.PropsWithChildren<{}>;
const SaveShareConfigurationProvider = (
  props: SaveShareConfigurationProviderProps
) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    isLoaded,
    savedReport,
    fetchReport,
    updateSavedReport,
    isStateChanged,
  } = useShareIdParams();
  const [isSaving, setIsSaving] = useState(false);
  const { fetchAuthenticated } = useAuthentication();
  const headers = useHeaders();
  const { getCurrentReport } = useMappingReport();
  const triggerNotification = useTriggerNotificationCallback();

  const saveReport = React.useCallback(
    async (afterSaved: () => void) => {
      setIsSaving(true);
      try {
        const res = await fetchAuthenticated(
          `${getServicesConfig().saveReportApi}/saveReports/${savedReport!.id}`,
          {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              ...savedReport,
              reportName: savedReport?.reportName || getCurrentReport(),
              url: !isStateChanged
                ? savedReport!.url
                : `?${new URLSearchParams(history.location.search).toString()}`,
            }),
          }
        );
        const json = await res.json();

        if (json.data) {
          fetchReport(savedReport!.id, false, true);
          setIsSaving(false);
          triggerNotification({
            type: 'success',
            title: intl.formatMessage(messages.saved),
            message: intl.formatMessage(messages.updated_success_title),
          });
          typeof afterSaved === 'function' && afterSaved();
        }
      } catch (err) {
        setIsSaving(false);
        console.log('[ERROR: Save Report]', { err });
      }
    },
    [savedReport, history.location, headers]
  );

  return (
    <SaveShareContext.Provider
      value={{
        isStateChanged,
        isSaving,
        isLoaded,
        savedReport: savedReport as any,
        saveReport,
        updateSavedReport,
      }}>
      {props.children}
    </SaveShareContext.Provider>
  );
};

export type SaveShareConfigurationConsumerProps = React.PropsWithChildren<{}>;
export const SaveShareConfigurationConsumer = (
  props: SaveShareConfigurationConsumerProps
) => {
  return (
    <SaveShareContext.Consumer>
      {(ctx) => (ctx!.isLoaded ? props.children : null)}
    </SaveShareContext.Consumer>
  );
};

export default SaveShareConfigurationProvider;
