import {
  useAuthentication,
  useCurrentProject,
  useCurrentUser,
  useOAuth,
} from '@ryte/mainframe';
import { Spinner } from '@ryte/ui-components';
import AppRoutes from 'app/components/AppRoutes';
import ErrorWidget from 'app/components/ErrorWidget';
import {
  WrappedFilterConfigurationConsumer,
  WrappedFilterConfigurationProvider,
} from 'app/components/Filters';
import SaveShareConfigurationProvider, {
  SaveShareConfigurationConsumer,
} from 'app/components/SaveShareMenu/SaveShareConfigurationProvider';
import ScrollToTop from 'app/components/ScrollToTop';
import { checkEasyConnectSaga } from 'app/containers/EasyConnect/checkEasyConnectSaga';
import createWeeklyTrafficSaga from 'app/containers/WeeklyTraffic/saga';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { dateFormat } from 'app/config';
import globalReducer from 'app/containers/App/reducer';
import tableReducer from 'app/containers/Table/reducer';
import weeklyTrafficReducer from 'app/containers/WeeklyTraffic/reducer';
import { REPORT, getKey } from '../../apiDefine';
import { DATE_FORMAT, DATE_FORMAT_AMP, USER_ID } from '../../parameter';
import './App.scss';
import createAppSaga, { createFetchProjectSettingsSaga } from './sagas';
import { selectError, selectProjectSettingsLoaded } from './selectors';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import { updateErrorType } from './actions';
import { useHeaders } from 'app/hooks/useHeaders';
import useInjectedReducer from 'app/hooks/useInjectedReducer';
import useInjectedSaga, { SagaMode } from 'app/hooks/useInjectedSaga';

const App = () => {
  const dispatch = useDispatch();
  const OAuthAPI = useOAuth();
  const isGSCStateLoaded = OAuthAPI.selectIsStateLoaded;
  const isProjectSettingsLoaded = useSelector(selectProjectSettingsLoaded());
  const error = useSelector(selectError());
  const location = useLocation();

  const updateError = (newError: any) => dispatch(updateErrorType(newError));

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      updateError({ code: null });
    };
  }, []);

  useEffect(() => {
    if (error.code) updateError({ code: null });
  }, [location]);

  if (!isGSCStateLoaded || !isProjectSettingsLoaded) {
    return (
      <div className={styles.loadingWrapper}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <SaveShareConfigurationProvider>
      <SaveShareConfigurationConsumer>
        <WrappedFilterConfigurationProvider>
          <WrappedFilterConfigurationConsumer>
            <div className={cn('page-content-wrapper', styles.appWrapper)}>
              <ScrollToTop />
              <div id="content_wrap">
                {error?.code ? <ErrorWidget error={error} /> : <AppRoutes />}
              </div>
            </div>
          </WrappedFilterConfigurationConsumer>
        </WrappedFilterConfigurationProvider>
      </SaveShareConfigurationConsumer>
    </SaveShareConfigurationProvider>
  );
};

const InjectedApp = () => {
  const { fetchAuthenticated } = useAuthentication();
  const headers = useHeaders();
  const weeklyTrafficSaga = React.useMemo(
    () => createWeeklyTrafficSaga(fetchAuthenticated, headers),
    [headers]
  );
  const appSaga = React.useMemo(
    () => createAppSaga(fetchAuthenticated, headers),
    [headers]
  );

  useInjectedReducer('global', globalReducer);
  useInjectedReducer('table', tableReducer);
  useInjectedReducer(REPORT.WEEKLY_TRAFFIC, weeklyTrafficReducer);
  useInjectedSaga(
    getKey(REPORT.WEEKLY_TRAFFIC),
    weeklyTrafficSaga,
    SagaMode.DAEMON
  );
  useInjectedSaga('global', appSaga, SagaMode.DAEMON);

  const store = useStore() as any;
  const { id: userId } = useCurrentUser();
  const project = useCurrentProject();
  const {
    selectIsGSCConnected,
    selectForceUnlockUI,
    selectIsImportingResults,
    selectIsStateLoaded,
    selectImportingInfo,
  } = useOAuth();

  React.useEffect(() => {
    if (selectIsStateLoaded && project?.slug) {
      const fetchProjectSettingsSaga = createFetchProjectSettingsSaga(
        { fetchAuthenticated, headers },
        selectImportingInfo,
        project?.slug
      );
      store.runSaga(() =>
        checkEasyConnectSaga(
          selectIsGSCConnected,
          selectForceUnlockUI,
          selectIsImportingResults,
          project
        )
      );
      store.runSaga(fetchProjectSettingsSaga);
    }
  }, [selectIsStateLoaded, headers, project?.slug]);

  React.useEffect(() => {
    if (project && userId) {
      window.localStorage.setItem(DATE_FORMAT, dateFormat.default);
      window.localStorage.setItem(DATE_FORMAT_AMP, dateFormat.amcharts);
      window.localStorage.setItem(USER_ID, userId);
    }
  }, [project, userId]);

  return <App />;
};

export default InjectedApp;
