import { useActiveSubscription, useCurrentUser } from '@ryte/mainframe';
import { Redirect } from 'react-router-dom';
import React from 'react';

export const RedirectToLastProject = () => {
  const { activeProject } = useActiveSubscription();
  const { lastProject } = useCurrentUser();

  const projectSlug = lastProject?.slug || activeProject?.slug;

  if (projectSlug) return <Redirect to={`/${projectSlug}/search-success`} />;
  return null;
};
