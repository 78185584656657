import { defineMessages } from 'react-intl';

export default defineMessages({
  unsaved_report_state: {
    id: 'save_share_menu.unsaved_report_state',
    defaultMessage: 'unsaved changes',
  },
  preCachedReport: {
    id: 'save_share_menu.preCachedReport',
    defaultMessage:
      'This report was pre-cached for you to improve the performance',
  },
  organic_performance: {
    id: 'report.title.organic_performance',
    defaultMessage: 'Organic Performance',
  },
  analyze: {
    id: 'report.title.analyze',
    defaultMessage: 'Analyze',
  },
  monitor: {
    id: 'report.title.monitor',
    defaultMessage: 'Monitor',
  },
  optimize: {
    id: 'report.title.optimize',
    defaultMessage: 'Optimize',
  },
  overview: {
    id: 'report.title.overview',
    defaultMessage: 'Overview',
  },
  anomalies: {
    id: 'report.title.anomalies',
    defaultMessage: 'Anomaly Detection',
  },
  anomaly: {
    id: 'report.title.anomaly',
    defaultMessage: 'Anomaly Detection',
  },
  keyword_monitoring: {
    id: 'report.title.keyword_monitoring',
    defaultMessage: 'Keyword Monitoring',
  },
  ranking_development: {
    id: 'report.title.ranking_development',
    defaultMessage: 'Ranking Development',
  },
  all_keywords: {
    id: 'report.title.all_keywords',
    defaultMessage: 'Keywords',
  },
  keyword_details: {
    id: 'report.title.keyword_details',
    defaultMessage: 'Keyword Details',
  },
  keyword_changes: {
    id: 'report.title.keyword_changes',
    defaultMessage: 'Ranking Changes',
  },
  keyword_segments: {
    id: 'report.title.keyword_segments',
    defaultMessage: 'Keyword Segments',
  },
  keyword_segments_create: {
    id: 'report.title.keyword_segments_create',
    defaultMessage: 'Keyword Segments',
  },
  new_keywords: {
    id: 'report.title.new_keywords',
    defaultMessage: 'New Keywords',
  },
  lost_keywords: {
    id: 'report.title.lost_keywords',
    defaultMessage: 'Lost Keywords',
  },
  distribution: {
    id: 'report.title.distribution',
    defaultMessage: 'Ranking Distribution',
  },
  all_pages: {
    id: 'report.title.all_pages',
    defaultMessage: 'Pages',
  },
  page_details: {
    id: 'report.title.page_details',
    defaultMessage: 'Page Details',
  },
  page_changes: {
    id: 'report.title.page_changes',
    defaultMessage: 'Page Ranking Changes',
  },
  page_segments: {
    id: 'report.title.page_segments',
    defaultMessage: 'Page Segments',
  },
  page_segments_create: {
    id: 'report.title.page_segments_create',
    defaultMessage: 'Page Segments',
  },
  directories: {
    id: 'report.title.directories',
    defaultMessage: 'Directories',
  },
  countries: {
    id: 'report.title.countries',
    defaultMessage: 'Countries',
  },
  devices: {
    id: 'report.title.devices',
    defaultMessage: 'Devices',
  },
  ab_testing: {
    id: 'report.title.ab_testing',
    defaultMessage: 'A/B Tests',
  },
  cannibalisations: {
    id: 'report.title.cannibalisations',
    defaultMessage: 'Cannibalisations',
  },
  ctr_underperformer: {
    id: 'report.title.ctr_underperformer',
    defaultMessage: 'Underperformer',
  },
  error: {
    id: 'report.title.error',
    defaultMessage: 'Error',
  },
  easy_connect: {
    id: 'report.title.easy_connect',
    defaultMessage: 'Easy Connect',
  },
  importing_info: {
    id: 'report.title.importing_info',
    defaultMessage: 'Importing Search Console data',
  },
  settings: {
    id: 'report.title.settings',
    defaultMessage: 'Settings',
  },
  trial: {
    id: 'report.title.trial',
    defaultMessage: 'Welcome',
  },
});
