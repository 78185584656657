import { useState, useCallback } from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export enum UPRStatus {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const useUPRStatusParams = (
  defaultUPRStatus: UPRStatus[] = [
    UPRStatus.HIGH,
    UPRStatus.MEDIUM,
    UPRStatus.LOW,
  ]
) => {
  const searchParams = useSearchParams();
  let parsedUPRStatus = parseJson(searchParams.get('uprstatus'));
  if (Array.isArray(parsedUPRStatus)) {
    parsedUPRStatus = parsedUPRStatus.filter((s) =>
      Object.values(UPRStatus).includes(s)
    );

    if (parsedUPRStatus.length === 0) {
      parsedUPRStatus = defaultUPRStatus;
    }
  } else {
    parsedUPRStatus = defaultUPRStatus;
  }

  const [status, internalSetStatus] = useState(parsedUPRStatus);
  const { setQuery } = useWrappedFilterContext();

  const setStatus = useCallback(
    (newUPRStatus: UPRStatus[]) => {
      setQuery({ uprstatus: newUPRStatus });
      internalSetStatus(newUPRStatus);
    },
    [status, internalSetStatus, setQuery]
  );

  return [status, setStatus];
};
