import { call, put } from 'redux-saga/effects';

import { getServicesConfig } from 'app/config';
import request from 'app/utils/request';

import { setError } from './actions';

export function* callApi(fetchAuthenticated, options) {
  const { resource, headers, ...restOptions } = options;

  let response;
  try {
    response = yield call(
      request,
      resource.indexOf('http') > -1
        ? resource
        : `${getServicesConfig().athenaApi}/${resource.replace(/^\//, '')}`,
      {
        method: 'GET',
        ...restOptions,
        headers,
      },
      fetchAuthenticated
    );

    if (response.errors) {
      yield put(setError(response));

      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
}
