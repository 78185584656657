import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@ryte/ui-components';
import messages from './messages';
import { useSaveShareContext } from '../SaveShareMenu/useSaveShareContext';

type UnsavedReportStateProps = {};

const UnsavedReportState: React.FC<UnsavedReportStateProps> = () => {
  const intl = useIntl();
  const { isStateChanged } = useSaveShareContext();
  if (!isStateChanged) return null;
  return (
    <div className="un-saved-report-state">
      <Typography variant="text-small-light" as="span" lineClamp={1}>
        {intl.formatMessage(messages.unsaved_report_state)}
      </Typography>
    </div>
  );
};

export default UnsavedReportState;
