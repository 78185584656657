import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useModal } from 'app/hooks/useModal';
import SaveReportForm from 'app/components/SaveShareMenu/SaveReportForm';
import { SaveReportModalContext } from './SaveReportModalContext';

type FormConfig = ComponentPropsWithoutRef<typeof SaveReportForm>;

export const SaveReportModalProvider: FC = (props) => {
  const { isOpened, open, close } = useModal();

  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);

  const { children } = props;

  const handleModalOpen = useCallback(
    (params: ComponentPropsWithoutRef<typeof SaveReportForm>) => {
      setFormConfig(params);
      open();
    },
    []
  );

  const handleModalClose = useCallback(() => {
    formConfig?.onClose?.();
    setFormConfig(null);
    close();
  }, [formConfig?.onClose]);

  const contextValue = useMemo(
    () => ({
      openSaveReportModal: handleModalOpen,
      closeSaveReportModal: handleModalClose,
      isOpened,
    }),
    [handleModalClose, handleModalOpen, isOpened]
  );

  const isModalVisible = isOpened && Boolean(formConfig);

  return (
    <>
      <SaveReportModalContext.Provider value={contextValue}>
        {isModalVisible && (
          <SaveReportForm {...formConfig} onClose={handleModalClose} />
        )}
        {children}
      </SaveReportModalContext.Provider>
    </>
  );
};
