import React from 'react';

const LazyDateRangePicker = React.lazy(
  () =>
    import(
      /* webpackChunkName: "date-picker" */
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      './DateRangePicker'
    )
);

const LazySingleDatePicker = React.lazy(
  () =>
    import(
      /* webpackChunkName: "date-picker" */
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      './SingleDatePicker'
    )
);

const DateRangePicker = (props) => (
  <React.Suspense fallback={null}>
    <LazyDateRangePicker {...props} />
  </React.Suspense>
);

const SingleDatePicker = (props) => {
  return (
    <React.Suspense fallback={null}>
      <LazySingleDatePicker {...props} firstDayOfWeek={1} />
    </React.Suspense>
  );
};

export { DateRangePicker, SingleDatePicker };
