import { useDidUpdateEffect } from '@ryte/hooks';
import {
  useCurrentOrganization,
  useSettings,
  useTriggerNotificationCallback,
} from '@ryte/mainframe';
import { useSidebarContext } from '@ryte/sidebar';
import { Button, Popover, PopoverItem, Tooltip } from '@ryte/ui-components';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import styles from './styles.module.scss';
import { useSaveShareContext } from './useSaveShareContext';
import { useSaveReportModalProvider } from 'app/components/SaveReportProvider/SaveReportModalContext';

type SaveDropdownItemProps = {
  onClickCallback?: () => void;
  disabled: boolean;
};
const SaveDropdownItem: React.FC<SaveDropdownItemProps> = ({
  disabled,
  onClickCallback,
}) => {
  const intl = useIntl();
  const [isSaved, setIsSaved] = useState(false);
  const { isStateChanged, isLoaded, isSaving, savedReport, saveReport } =
    useSaveShareContext();

  const isDisabled = disabled || !isStateChanged || isSaved || isSaving;

  const handleClick = React.useCallback(() => {
    if (!isDisabled || savedReport!.ownerName === null) {
      if (onClickCallback) {
        onClickCallback();
      }
      setIsSaved(false);
      saveReport(() => {
        setIsSaved(true);
      });
    }
  }, [saveReport, isDisabled]);

  useDidUpdateEffect(() => {
    setIsSaved(false);
  }, [isStateChanged]);

  if (!isLoaded || savedReport === null) {
    return <></>;
  }

  if (savedReport?.editable) {
    return (
      <PopoverItem
        onClick={handleClick}
        disabled={savedReport.ownerName === null ? false : isDisabled}
        icon="save"
        text={intl.formatMessage(messages.save)}
      />
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip
        maxWidth={150}
        direction="bottom"
        content={intl.formatMessage(messages.save_menu_item_tooltip, {
          ownerName: savedReport?.ownerName || '',
        })}>
        <PopoverItem
          icon="save"
          text={intl.formatMessage(messages.save)}
          disabled
        />
      </Tooltip>
    </div>
  );
};

const CopyLinkDropdownItem = ({
  onClickCallback,
}: {
  onClickCallback: () => void;
}) => {
  const intl = useIntl();
  const triggerNotification = useTriggerNotificationCallback();
  const clickHandler = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      triggerNotification({
        type: 'success',
        title: intl.formatMessage(messages.copied_link),
        message: intl.formatMessage(messages.linkCopied),
      });
      onClickCallback();
    } catch (error) {
      console.error('[ERROR]: copy_report_url_error');
    }
  }, [triggerNotification, intl]);

  return (
    <PopoverItem
      text={intl.formatMessage(messages.copy_link)}
      icon="clipboard"
      onClick={clickHandler}
    />
  );
};

const ShareDropdownItem: React.FC<{
  reportId: string;
  onClickCallback?: () => void;
}> = ({ reportId, onClickCallback }) => {
  const intl = useIntl();
  const { role } = useCurrentOrganization();
  const isOrganizationOwner = role === 'owner';
  const { openShareReportModal } = useSettings();
  const { savedReport } = useSaveShareContext();

  const clickHandler = React.useCallback(() => {
    if (onClickCallback) {
      onClickCallback();
    }
    const reportName =
      savedReport !== null
        ? savedReport.name
        : intl.formatMessage({ id: `report.title.${reportId}` });

    openShareReportModal(reportName, window.location.href);
  }, [openShareReportModal, savedReport, intl]);

  if (!isOrganizationOwner) {
    return (
      <Tooltip
        direction="bottom"
        content={<FormattedMessage {...messages.share_report_tooltip} />}>
        <PopoverItem
          text={intl.formatMessage(messages.share_report)}
          icon="share"
          disabled
        />
      </Tooltip>
    );
  }

  return (
    <PopoverItem
      text={intl.formatMessage(messages.share_report)}
      icon="share"
      onClick={clickHandler}
    />
  );
};

type SaveShareMenuProps = {
  reportId: string;
};
const SaveShareMenu = ({ reportId }: SaveShareMenuProps) => {
  const [currentReportId, setCurrentReportId] = useState<string | undefined>();

  const {
    openSaveReportModal: _openSaveReportModal,
    isOpened: isEditModalOpened,
  } = useSaveReportModalProvider();

  const openSaveReportModal = () => {
    _openSaveReportModal({
      reportId: currentReportId,
      onClose: () => {
        setCurrentReportId(undefined);
      },
      onCreated: () => {
        if (currentReportId === undefined) {
          return;
        }
      },
    });
  };

  useDidUpdateEffect(() => {
    if (!isEditModalOpened && currentReportId !== undefined) {
      openSaveReportModal();
    }
  }, [isEditModalOpened, currentReportId]);

  const intl = useIntl();

  const [openPopover, setOpenPopover] = useState(false);

  const { showReportGallerySection } = useSidebarContext()!;

  const openSavedReport = useCallback(() => {
    showReportGallerySection('saved-report');
  }, []);

  return (
    <>
      {reportId === 'overview' ? (
        <Button
          className={styles.savedReportsButton}
          variant="neutral-inverted"
          onClick={openSavedReport}
          icon="folder"
          text={intl.formatMessage(messages.my_saved_reports)}
        />
      ) : (
        <Popover
          externalControl
          open={openPopover}
          onOuterAction={() => setOpenPopover(false)}
          trigger={
            <Button
              className={cn(styles.Button)}
              variant="neutral-inverted"
              text={intl.formatMessage(messages.save_and_share)}
              icon="chevron_down"
              onClick={() => setOpenPopover(!openPopover)}
              iconOnRight
            />
          }>
          <SaveDropdownItem
            disabled={false}
            onClickCallback={() => setOpenPopover(false)}
          />
          <PopoverItem
            icon="save"
            onClick={() => {
              setOpenPopover(false);
              openSaveReportModal();
            }}
            text={intl.formatMessage(messages.save_as_new)}
          />
          <PopoverItem
            icon="folder"
            onClick={() => {
              setOpenPopover(false);
              openSavedReport();
            }}
            text={intl.formatMessage(messages.my_saved_reports)}
          />
          <ShareDropdownItem
            reportId={reportId}
            onClickCallback={() => setOpenPopover(false)}
          />
          <CopyLinkDropdownItem onClickCallback={() => setOpenPopover(false)} />
        </Popover>
      )}
    </>
  );
};

export default SaveShareMenu;
