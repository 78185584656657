import React from 'react';
import { useIntl } from 'react-intl';
import { Introbox as RyteIntrobox } from '@ryte/ui-components';

import Badge from 'app/components/Badge';
import FormattedRichText from 'app/components/FormattedRichText';

import images from './images';
import messages from './messages';
import styles from './styles.module.scss';

const getImage = (reportId: string): string => {
  if (images[reportId]) {
    return images[reportId];
  }

  return images.placeholder;
};

const getMessage = (reportId: string, type: string) => {
  const prop = `${reportId}_${type}`;
  if (messages.hasOwnProperty(prop)) {
    return messages[prop as keyof typeof messages];
  }

  return null;
};

type IntroboxContentProps = {
  reportId: string;
};
const IntroboxContent: React.FC<IntroboxContentProps> = ({ reportId }) => {
  const content = getMessage(reportId, 'content');

  if (content === null) {
    return null;
  }

  return (
    <FormattedRichText
      tagName="div"
      {...content}
      values={{
        'p': (...msg: React.ReactElement[]) => (
          <p className={styles.paragraph}>{msg}</p>
        ),
        'span': (msg: string) => <span>{msg}</span>,
        'red': (msg: string) => <span className={styles.redText}>{msg}</span>,
        'green': (msg: string) => (
          <span className={styles.greenText}>{msg}</span>
        ),
        'group-content': (...msg: React.ReactElement[]) => (
          <p className={styles.groupContext}>{msg}</p>
        ),
        'red-badge': (msg: string) => (
          <span className={styles.badgeWrapper}>
            <Badge type="red">{msg}</Badge>
          </span>
        ),
        'green-badge': (msg: string) => (
          <span className={styles.badgeWrapper}>
            <Badge type="green">{msg}</Badge>
          </span>
        ),
        'blue-badge': (msg: string) => (
          <span className={styles.badgeWrapper}>
            <Badge type="blue">{msg}</Badge>
          </span>
        ),
      }}
    />
  );
};

type IntroboxProps = {
  reportId: string;
  onIntroboxToggle?: (isCollapsed: boolean) => void;
};
const Introbox: React.FC<IntroboxProps> = ({ reportId, onIntroboxToggle }) => {
  const intl = useIntl();
  const title = getMessage(reportId, 'title');

  return (
    <RyteIntrobox
      introId={reportId}
      title={title ? intl.formatMessage(title) : ''}
      content={<IntroboxContent reportId={reportId} />}
      buttonText={intl.formatMessage(messages.okGotIt)}
      img={<img src={getImage(reportId)} alt="" />}
      className=""
      expandedClassName=""
      collapsedClassName="introbox-is-collapsed"
      onIntroboxToggle={onIntroboxToggle}
    />
  );
};

export default Introbox;
