import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import moment, { Moment } from 'moment';

import { Button, Icon, Popover } from '@ryte/ui-components';
import {
  COMPARED_DATE_RANGES,
  COMPARED_DATE_RANGES_MSGS,
  DateRangeSelectValues,
  DATE_RANGE_DIFF,
  DISPLAY_FORMAT,
  RELATIONSHIP,
} from './constants';
import styles from './DatePickerButton.module.scss';
import DateRangePicker from './DateRangePicker';
import messages from './messages';
import { useFormatters } from '@ryte/mainframe';

const getCompareToOption = (
  diffInDaysBetweenComparedDates,
  diffInDaysbBetweenStartDateAndComparedStartDate
) => {
  const dateRangeOption = DATE_RANGE_DIFF[diffInDaysBetweenComparedDates];
  const relationship = RELATIONSHIP[dateRangeOption];

  if (!relationship) {
    return COMPARED_DATE_RANGES.CUSTOM;
  }

  const option = Object.entries(relationship).find(
    ([, value]) =>
      (value as any)[1] === diffInDaysbBetweenStartDateAndComparedStartDate
  );

  if (!option || (option as any)[1][1] === null) {
    return COMPARED_DATE_RANGES.PREVIOUS_PERIOD;
  }

  return option[0];
};

type FormattedDisplayMessageProps = {
  startDate: Moment;
  endDate: Moment;
  comparisonMessage: string;
  displayFormat: DISPLAY_FORMAT;
};

const FormattedDisplayMessage: React.FC<FormattedDisplayMessageProps> = ({
  startDate,
  endDate,
  comparisonMessage,
  displayFormat,
}) => {
  const { formatDate } = useFormatters();
  const durationInDays = endDate.diff(startDate, 'days') + 1;
  const formattedStartDate = formatDate(startDate.unix());
  const formattedEndDate = formatDate(endDate.unix());

  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  if (displayFormat === DISPLAY_FORMAT.COMPARISON) {
    return (
      <span className={styles.dateRange}>
        {formattedDateRange}
        <span className={styles.dateRangeLabel}>
          {' vs. '}
          {comparisonMessage}
        </span>
      </span>
    );
  }

  if (displayFormat === DISPLAY_FORMAT.PREVIOUS_DAYS) {
    return (
      <span className={styles.dateRange}>
        {formattedDateRange}
        <span className={styles.dateRangeLabel}>
          {' vs. '}
          {durationInDays > 1 ? (
            <FormattedMessage
              tagName={'span'}
              {...messages.previousDays}
              values={{ number: durationInDays }}
            />
          ) : (
            <FormattedMessage tagName={'span'} {...messages.previousDay} />
          )}
        </span>
      </span>
    );
  }

  if (displayFormat === DISPLAY_FORMAT.LAST_DAYS) {
    return (
      <span className={styles.dateRange}>
        <FormattedMessage
          tagName={'span'}
          {...messages.lastDays}
          values={{ number: durationInDays }}
        />
      </span>
    );
  }

  return <span className={styles.dateRange}>{formattedDateRange}</span>;
};

type DatePickerButtonProps = {
  filter: any;
  updateFilter: Function;
  disabled?: boolean;
  displayFormat: DISPLAY_FORMAT;
};

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
  filter,
  updateFilter,
  disabled = false,
  displayFormat = DISPLAY_FORMAT.DATE_RANGE,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const startDate = filter?.dateFrom;
  const endDate = filter?.dateTo;
  const beforeStartDate = filter?.beforeStartDate;
  const beforeEndDate = filter?.beforeEndDate;

  const [dates, setDates] = useState({
    startDate: moment(startDate.clone().format('DD.MM.YYYY'), 'DD.MM.YYYY'),
    endDate: moment(endDate.clone().format('DD.MM.YYYY'), 'DD.MM.YYYY'),
  });
  const diffInDaysBetweenStartEnd =
    dates.endDate.diff(dates.startDate, 'days') + 1;
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    DATE_RANGE_DIFF[diffInDaysBetweenStartEnd] || DateRangeSelectValues.CUSTOM
  );
  const [comparedDates, setCompareToDates] = useState({
    startDate:
      beforeStartDate ||
      dates.startDate
        .clone()
        .subtract(
          RELATIONSHIP[selectedDateRangeOption][
            COMPARED_DATE_RANGES.PREVIOUS_PERIOD
          ][1],
          'days'
        ),
    endDate:
      beforeEndDate ||
      dates.endDate
        .clone()
        .subtract(
          RELATIONSHIP[selectedDateRangeOption][
            COMPARED_DATE_RANGES.PREVIOUS_PERIOD
          ][1],
          'days'
        ),
  });
  const diffInDaysBetweenComparedDates =
    comparedDates.endDate.diff(comparedDates.startDate, 'days') + 1;
  const [selectedCompareToOption, setSelectedCompareToOption] = useState(
    getCompareToOption(
      diffInDaysBetweenComparedDates,
      dates.startDate.diff(comparedDates.startDate, 'days')
    )
  );
  const [comparisonMessage, setComparisonMessage] = useState(
    intl.formatMessage(COMPARED_DATE_RANGES_MSGS[selectedCompareToOption])
  );

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const popoverTarget: React.ReactElement = useMemo(
    () => <div className={styles.target} />,
    []
  );

  return (
    <div className={styles.wrapper} ref={containerRef}>
      <Popover
        noToggle
        trigger={popoverTarget}
        open={isOpen}
        externalControl
        className={styles['custom-popup-class']}>
        <DateRangePicker
          filter={filter}
          updateFilter={updateFilter as any}
          noCompare={displayFormat !== DISPLAY_FORMAT.COMPARISON}
          handleClose={toggleOpen}
          dates={dates}
          setDates={setDates}
          selectedDateRangeOption={selectedDateRangeOption}
          setSelectedDateRangeOption={setSelectedDateRangeOption}
          comparedDates={comparedDates}
          setCompareToDates={setCompareToDates}
          selectedCompareToOption={selectedCompareToOption}
          setSelectedCompareToOption={setSelectedCompareToOption}
          setComparisonMessage={setComparisonMessage}
        />
      </Popover>
      <Button
        icon="calendar"
        variant="neutral-inverted"
        className={cn(styles.datePickerButton, {
          [styles.disabled]: disabled,
        })}
        onClick={toggleOpen}
        text={
          <>
            <FormattedDisplayMessage
              startDate={startDate}
              endDate={endDate}
              displayFormat={displayFormat}
              comparisonMessage={comparisonMessage}
            />
            {!disabled && (
              <Icon
                className={styles.downArrow}
                icon="chevron_down"
                size={16}
                color="currentColor"
                strong
              />
            )}
          </>
        }
      />
    </div>
  );
};
