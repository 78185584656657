import {
  LOAD_TRAFFIC,
  LOAD_TRAFFIC_SUCCESS,
  LOAD_TRAFFIC_ERROR,
  LOAD_COST_SETTINGS,
  LOAD_COST_SETTINGS_SUCCESS,
  LOAD_COST_SETTINGS_ERROR,
  LOAD_CURRENCY_LIST,
  UPDATE_COST_SETTINGS,
  UPDATE_COST_SETTINGS_SUCCESS,
} from './constants';

export const initialState = {
  loadingChart: false,
  loadingCpc: false,
  error: false,
  chart: [],
  cpcSetting: {
    avgCPC: 0,
  },
  currencySetting: {
    currentCode: '',
    currentName: '',
    currentSymbol: '',
    currencyExchangeRate: 0,
  },
  currencyList: [],
  startDate: '',
  endDate: '',
};

export default function weeklyTrafficReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TRAFFIC:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        loadingChart: true,
      };

    case LOAD_TRAFFIC_SUCCESS:
      return {
        ...state,
        loadingChart: false,
        chart: action.payload.chart,
      };

    case LOAD_TRAFFIC_ERROR:
      return {
        ...state,
        loadingChart: false,
        error: action.error,
      };

    case LOAD_COST_SETTINGS:
      return {
        ...state,
        loadingCpc: true,
      };

    case LOAD_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload.currencyList,
        currencySetting: action.payload.currencySetting,
      };

    case LOAD_COST_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingCpc: false,
        cpcSetting: {
          ...state.cpcSetting,
          ...action.payload,
        },
      };

    case LOAD_COST_SETTINGS_ERROR:
      return {
        ...state,
        loadingCpc: false,
        error: action.error,
      };

    case UPDATE_COST_SETTINGS:
      return {
        ...state,
        loadingCpc: true,
        cpcSetting: {
          ...state.cpcSetting,
          avgCPC: action.costSettings.avgCpc,
        },
      };

    case UPDATE_COST_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingCpc: false,
        loadingChart: true,
      };

    default:
      return state;
  }
}
