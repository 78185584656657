export const openPrintOption = (): void => {
  try {
    if (!document.execCommand('print', false)) {
      // Firefox return false
      throw new Error('Browser does not recognize the command');
    }
  } catch (e) {
    window.print();
  }
};
