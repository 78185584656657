export const loadIntlPolyfill = async (): Promise<void> => {
  if (!('Intl' in window)) {
    // polyfills the general Intl functionality, including Intl.NumberFormat and Intl.DateTimeFormat
    await import('intl' as any /* webpackChunkName: "intl-polyfill" */);
  }

  if (
    !Intl.PluralRules ||
    Intl.PluralRules.supportedLocalesOf(['de', 'en', 'fr', 'es', 'ko'])
      .length !== 5
  ) {
    await import(
      'intl-pluralrules' as any /* webpackChunkName: "intl-pluralrules-polyfill" */
    );
  }

  if (!('RelativeTimeFormat' in Intl)) {
    await import(
      '@formatjs/intl-relativetimeformat/polyfill' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
    );
    await Promise.all([
      import(
        '@formatjs/intl-relativetimeformat/dist/locale-data/de' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
      ),
      import(
        '@formatjs/intl-relativetimeformat/dist/locale-data/en' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
      ),
      import(
        '@formatjs/intl-relativetimeformat/dist/locale-data/fr' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
      ),
      import(
        '@formatjs/intl-relativetimeformat/dist/locale-data/es' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
      ),
      import(
        '@formatjs/intl-relativetimeformat/dist/locale-data/ko' as any /* webpackChunkName: "intl-relativetimeformat-polyfill" */
      ),
    ]);
  }
};

export const loadProxyPolyfill = async (): Promise<void> => {
  if ('Proxy' in window) {
    return Promise.resolve();
  }

  await import(
    'proxy-polyfill' as any /* webpackChunkName: "proxy-polyfill" */
  );
  return;
};
