import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'query-string';
import { REPORT } from '../../apiDefine';

export function* checkEasyConnectSaga(
  isGSCConnected: boolean,
  forceUnlockUI: number,
  isImporting: boolean,
  currentProject
) {
  const mainUrl: string = qs.stringifyUrl({
    url: `/${currentProject.slug}/${REPORT.EASY_CONNECT}/`,
    query: qs.parseUrl(window.location.href).query,
  });

  if (currentProject.gscConnected === false) {
    // The StateAPI includes the information if GSC has been connected at all.
    // If this is false, we can speed up the process a bit and redirect immediately.
    // The API call to the oAuth API will still be done regardless
    // to check if a token expired or data is importing
    yield put(push(mainUrl));
    // yield put(trackEvent({ eventName: 'EC 00 Initialized Easy Connect' }));
    return;
  }

  if (!isGSCConnected) {
    yield put(push(mainUrl));
    // yield put(trackEvent({ eventName: 'EC 00 Initialized Easy Connect' }));
    return;
  }

  if (isImporting && forceUnlockUI === 0) {
    yield put(push(`/${currentProject.slug}/${REPORT.LOCK_UI}`));
  }
}
