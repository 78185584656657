import React from 'react';
import type * as Redux from 'redux';
import type { ReactReduxContextValue } from 'react-redux';
import { ReactReduxContext } from 'react-redux';
import type { Task } from 'redux-saga';
import conformsTo from 'lodash/conformsTo';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import invariant from 'invariant';

//
// Validate the shape of redux store
//
function checkStore(store: any) {
  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    injectedReducers: isObject,
    injectedSagas: isObject,
  };
  invariant(
    conformsTo(store, shape),
    '(app/utils...) injectors: Expected a valid redux store'
  );
}

export enum SagaMode {
  DAEMON = '@@saga-injector/daemon',
  ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount',
}

export type Saga = () => Iterator<any>;

type SagaDescriptor = {
  saga?: Saga;
  mode?: SagaMode;
  task?: Task;
};

type EnhancedStore = Redux.Store & {
  injectedSagas: { [index: string]: SagaDescriptor };
  runSaga: (saga: Saga) => Task;
};

const useInjectedSaga = (key: string, saga: Saga, mode: SagaMode): void => {
  const [isInjected, setIsInjected] = React.useState(false);
  const reduxContext: ReactReduxContextValue =
    React.useContext(ReactReduxContext);
  checkStore(reduxContext.store);

  const store = reduxContext.store as EnhancedStore;
  React.useEffect(() => {
    setIsInjected(false);

    () => {
      if (mode !== SagaMode.ONCE_TILL_UNMOUNT) {
        return;
      }

      // eject saga on unmount
      if (Reflect.has(store.injectedSagas, key)) {
        const descriptor = store.injectedSagas[key];
        descriptor.task && descriptor.task.cancel();
      }
    };
  }, [key, mode, saga, store.injectedSagas]);

  if (!isInjected) {
    const newDescriptor: SagaDescriptor = { saga, mode };

    if (Reflect.has(store.injectedSagas, key)) {
      if (store.injectedSagas[key].saga === saga) {
        return;
      }

      const descriptor = store.injectedSagas[key];
      descriptor.task && descriptor.task.cancel();
    }

    store.injectedSagas[key] = {
      ...newDescriptor,
      task: store.runSaga(saga),
    };

    setIsInjected(true);
  }
};

export default useInjectedSaga;
