import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getServicesConfig } from 'app/config';
import { useHeaders } from 'app/hooks/useHeaders';
import {
  useAuthentication,
  useTriggerNotificationCallback,
} from '@ryte/mainframe';
import { useHistory } from 'react-router-dom';
import { SaveProfile } from './types';
import { useIntl } from 'react-intl';
import messages from './messages';
import { isUUID } from 'app/utils/uuid';
import { useSavedReports } from '@ryte/saved-reports';

export const useShareIdParams = () => {
  const location = useLocation();
  const history = useHistory();
  const headers = useHeaders();
  const { fetchAuthenticated } = useAuthentication();
  const triggerNotification = useTriggerNotificationCallback();
  const [savedReport, setSavedReport] = React.useState<SaveProfile | null>(
    null
  );

  const [isLoaded, setIsLoaded] = React.useState(false);
  const intl = useIntl();

  const reportIdParam = React.useMemo(() => {
    const paths = location.pathname.split('/');
    return paths.find((path) => isUUID(path));
  }, [location]);

  const { data: savedReports } = useSavedReports();

  useEffect(() => {
    if (!savedReports) {
      return;
    }
    const currentReport = savedReports.find(({ id }) => {
      return id === reportIdParam;
    });
    setSavedReport((currentReport as any) || null);
  }, [savedReports]);

  const isStateChanged = React.useMemo(() => {
    if (savedReport !== null) {
      const searchParams = new URLSearchParams(savedReport.url);
      const newSearch = new URLSearchParams(history.location.search);
      return (
        newSearch.toString() !== searchParams.toString() &&
        newSearch.toString() !== ''
      );
    }
    return false;
  }, [history.location, savedReport]);

  const fetchReport = async (
    reportId,
    needReload: boolean = true,
    redirect: boolean = false
  ) => {
    if (needReload) {
      setIsLoaded(false);
    }
    try {
      const res = await fetchAuthenticated(
        `${getServicesConfig().saveReportApi}/saveReports/${reportId}`,
        { method: 'GET', headers }
      );
      const json = await res.json();

      if (json.data) {
        if (redirect) {
          history.replace(history.location.pathname.split('?')[0]);
        }
        setSavedReport(json.data);
      } else {
        setSavedReport(null);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log('[ERROR: FETCH Report]', { err });
      triggerNotification({
        type: 'error',
        title: intl.formatMessage(messages.Error),
        message: intl.formatMessage(messages.NotFoundReport),
      });
      history.replace(history.location.pathname.split(`/${reportIdParam}`)[0]);
      setIsLoaded(true);
    }
  };

  const updateSavedReport = React.useCallback(
    (newSavedReport: SaveProfile | null) => {
      setSavedReport(newSavedReport);
    },
    []
  );

  React.useEffect(() => {
    if (reportIdParam) {
      if (!isStateChanged) {
        fetchReport(reportIdParam);
      }
    } else {
      setIsLoaded(true);
    }
  }, [reportIdParam]);

  return {
    isLoaded,
    savedReport,
    fetchReport,
    updateSavedReport,
    isStateChanged,
  };
};
