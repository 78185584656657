import { defineMessages } from 'react-intl';

export default defineMessages({
  save_and_share: {
    id: 'save_share_menu.save_and_share',
    defaultMessage: 'Save & share',
  },
  save: {
    id: 'save_share_menu.save',
    defaultMessage: 'Save',
  },
  saved: {
    id: 'save_share_menu.saved',
    defaultMessage: 'Saved',
  },
  save_as_new: {
    id: 'save_share_menu.save_as_new',
    defaultMessage: 'Save as new...',
  },
  my_saved_reports: {
    id: 'save_share_menu.my_saved_reports',
    defaultMessage: 'My saved reports',
  },
  copy_link: {
    id: 'save_share_menu.copy_link',
    defaultMessage: 'Copy link',
  },
  share_report: {
    id: 'save_share_menu.share_report',
    defaultMessage: 'Share report',
  },
  share_report_tooltip: {
    id: 'save_share_menu.share_report_tooltip',
    defaultMessage: 'Only account owners are allowed to share reports.',
  },
  save_report: {
    id: 'save_share_menu.save_report',
    defaultMessage: 'Save new report',
  },
  edit_report: {
    id: 'save_share_menu.edit_report',
    defaultMessage: 'Edit report',
  },
  cancel: {
    id: 'save_share_menu.cancel',
    defaultMessage: 'Cancel',
  },
  name: {
    id: 'save_share_menu.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'save_share_menu.description',
    defaultMessage: 'Description (optional)',
  },
  save_menu_item_tooltip: {
    id: 'save_share_menu.save_menu_item_tooltip',
    defaultMessage:
      'Only the creator of this report ({ownerName}) or project owner can save',
  },
  updated_success_title: {
    id: 'save_share_menu.updated_success_title',
    defaultMessage: 'Report is saved successfully',
  },
  rolling_date_tooltip: {
    id: 'save_share_menu.rolling_date_tooltip',
    defaultMessage:
      'When enabled and you check back in 7 days this report will display data from {startDate} - {endDate}',
  },
  roll_dates: {
    id: 'save_share_menu.roll_dates',
    defaultMessage: 'Roll dates',
  },
  number_of_days: {
    id: 'save_share_menu.number_of_days',
    defaultMessage: '({days} days)',
  },
  copied_link: {
    id: 'save_share_menu.copied_link',
    defaultMessage: 'Copied link',
  },
  linkCopied: {
    id: 'app.components.SaveReportManager.linkCopied',
    defaultMessage: 'Link is copied to the clipboard',
  },
  Error: {
    id: 'save_share_menu.Error',
    defaultMessage: 'Error',
  },
  NotFoundReport: {
    id: 'save_share_menu.NotFoundReport',
    defaultMessage: 'The report could not be found',
  },
  openNewNavigationConfirm: {
    id: 'save_share_menu.openNewNavigationConfirm',
    defaultMessage: 'Switch',
  },
  openNewNavigationDecline: {
    id: 'save_share_menu.openNewNavigationDecline',
    defaultMessage: 'Keep',
  },
  openNewNavigationConfirmationText: {
    id: 'save_share_menu.openNewNavigationConfirmationText',
    defaultMessage: 'Your saved reports moved to the Report Gallery',
  },
  openNewNavigationConfirmationDescription: {
    id: 'save_share_menu.openNewNavigationConfirmationDescription',
    defaultMessage:
      'Switch to the new navigation to access the Report Gallery and all-new interface.',
  },
});
