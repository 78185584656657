import { useState, useCallback, useEffect } from 'react';

import parseJson from 'app/utils/parseJson';
import { useSearchParams } from './useSearchParams';
import { useWrappedFilterContext } from './useWrappedFilterContext';

export enum Metric {
  Clicks = 'clicks',
  Impressions = 'impressions',
  CTR = 'ctr',
  Position = 'position',
  Unique = 'unique',
  CPCTraffic = 'cpcTraffic',
}

export const useMetricsParams = (defaultMetrics: string[] = []) => {
  const searchParams = useSearchParams();
  let parsedMetrics = parseJson(searchParams.get('metrics'));
  if (Array.isArray(parsedMetrics)) {
    parsedMetrics = parsedMetrics.filter((m) =>
      Object.values(Metric).includes(m)
    );

    if (parsedMetrics.length === 0) {
      parsedMetrics = defaultMetrics;
    }
  } else {
    parsedMetrics = defaultMetrics;
  }
  const [metrics, internalSetMetrics] = useState<Metric[]>(parsedMetrics);
  const { setQuery } = useWrappedFilterContext();

  useEffect(() => {
    setQuery({ metrics });
  }, []);

  const setMetrics = useCallback(
    (newMetrics: Metric[]) => {
      internalSetMetrics(newMetrics);
      setQuery({ metrics: newMetrics });
    },
    [internalSetMetrics, setQuery]
  );

  return [metrics, setMetrics] as [typeof metrics, typeof setMetrics];
};
