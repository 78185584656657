import { getServicesConfig } from 'app/config';

export const getKey = (key) => key;

export const REPORT = {
  QUERY: 'search-success/analyze/queries',
  PAGE: 'search-success/analyze/pages',
  CHANGES: 'search-success/analyze/keyword-changes',
  PAGE_CHANGES: 'search-success/analyze/page-changes',
  NEW_KEYWORD: 'search-success/analyze/new-keywords',
  LOST_KEYWORD: 'search-success/analyze/lost-keywords',
  DISTRIBUTION: 'search-success/analyze/distributions',
  DIRECTORY: 'search-success/analyze/directories',
  COUNTRY: 'search-success/analyze/countries',
  DEVICE: 'search-success/analyze/devices',
  CANNIBALISATION: 'search-success/optimize/cannibalisations',
  UNDERPERFORMER: 'search-success/optimize/underperformer',
  AB_TESTS: 'search-success/optimize/ab-tests',
  AB_TESTS_CHART: 'search-success/optimize/ab-tests-chart',
  MONITOR: 'search-success/monitor/keyword-monitoring',
  MOBILE: 'search-success/analyze/mobiles',
  MIGRATE_KEYWORD: 'search-success/monitor/migrate-keywords',
  OVERVIEW: 'search-success/monitor/overview',
  SETTING: 'search-success/settings',
  EASY_CONNECT: 'search-success/easy-connect',
  LOCK_UI: 'search-success/importing',
  SEGMENTS: 'search-success/analyze/:type/segments',
  KEYWORD_SEGMENTS: 'search-success/analyze/keywords/segments',
  PAGE_SEGMENTS: 'search-success/analyze/pages/segments',

  SEGMENT_CREATE: 'search-success/analyze/:type/segments/:id',
  EVENT: 'event',
  KPI: 'search-success/kpi',
  ANOMALY: 'search-success/anomaly',
  WEEKLY_TRAFFIC: 'search-success/weekly-traffic',
  ANOMALIES: 'search-success/monitor/anomalies',
  CURRENCY_SETTING: 'search-success/currency-setting',
  KEYWORD_MONITORING_WEEKLY_HISTORY:
    'search-success/monitor/ranking-development',
};

const vars = {
  category: {
    keywords: 'Keywords',
    pages: 'Pages',
  },
  section: {
    monitor: 'Monitor',
    analyze: 'Analyze',
    optimize: 'Optimize',
  },
};

export const REPORT_META = {
  QUERY: {
    NAME: 'Keywords',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  QUERY_DETAIL: {
    NAME: 'Keywords - Detail',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  PAGE: {
    NAME: 'Pages',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.pages,
  },
  PAGE_DETAIL: {
    NAME: 'Pages - Detail',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.pages,
  },
  CHANGES: {
    NAME: 'Ranking Changes',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  PAGE_CHANGES: {
    NAME: 'Page Changes',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.pages,
  },
  NEW_KEYWORD: {
    NAME: 'New Keywords',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  LOST_KEYWORD: {
    NAME: 'Lost Keywords',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  DISTRIBUTION: {
    NAME: 'Ranking Distribution',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  DIRECTORY: {
    NAME: 'Directories',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.pages,
  },
  COUNTRY: {
    NAME: 'Countries',
    SECTION: vars.section.analyze,
    CATEGORY: '',
  },
  DEVICE: {
    NAME: 'Devices',
    SECTION: vars.section.analyze,
    CATEGORY: '',
  },
  CANNIBALISATION: {
    NAME: 'Cannibalisations',
    SECTION: vars.section.optimize,
    CATEGORY: '',
  },
  UNDERPERFORMER: {
    NAME: 'Underperformer',
    SECTION: vars.section.optimize,
    CATEGORY: '',
  },
  MONITOR: {
    NAME: 'Keyword Monitoring',
    SECTION: vars.section.monitor,
    CATEGORY: '',
  },
  MOBILE: {
    NAME: 'Devices',
    SECTION: vars.section.analyze,
    CATEGORY: '',
  },
  MIGRATE_KEYWORD: {
    NAME: '',
    CATEGORY: '',
  },
  OVERVIEW: {
    NAME: 'Overview',
    SECTION: vars.section.monitor,
    CATEGORY: '',
  },
  SETTING: {
    NAME: '',
    CATEGORY: '',
  },
  TRIAL: {
    NAME: '',
    CATEGORY: '',
  },
  EASY_CONNECT: {
    NAME: '',
    CATEGORY: '',
  },
  LOCK_UI: {
    NAME: '',
    CATEGORY: '',
  },
  SEGMENTS: {
    NAME: '',
    CATEGORY: '',
  },
  KEYWORD_SEGMENTS: {
    NAME: 'Keyword Segments',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.keywords,
  },
  PAGE_SEGMENTS: {
    NAME: 'Page Segments',
    SECTION: vars.section.analyze,
    CATEGORY: vars.category.pages,
  },
  SEGMENT_CREATE: {
    NAME: '',
    CATEGORY: '',
  },
  EVENT: {
    NAME: '',
    CATEGORY: '',
  },
  KPI: {
    NAME: '',
    CATEGORY: '',
  },
  KEYWORD_MONITORING_WEEKLY_HISTORY: {
    NAME: 'Ranking Development',
    SECTION: 'Monitoring',
    CATEGORY: 'Keyword Monitoring',
  },
};

export default {
  [REPORT.QUERY]: {
    list: `${getServicesConfig().athenaApi}/keyword/list`,
    detail: {
      list: `${getServicesConfig().athenaApi}/keyword/detail/list`,
      kpi: `${getServicesConfig().athenaApi}/keyword/detail/kpi`,
    },
    kpi: `${getServicesConfig().athenaApi}/keyword/kpi`,
    compare: `${getServicesConfig().athenaApi}/page/compare`,
  },
  [REPORT.PAGE]: {
    list: `${getServicesConfig().athenaApi}/page/list`,
    detail: {
      list: `${getServicesConfig().athenaApi}/page/detail/list`,
      kpi: `${getServicesConfig().athenaApi}/page/detail/kpi`,
    },
    kpi: `${getServicesConfig().athenaApi}/page/kpi`,
    compare: `${getServicesConfig().athenaApi}/page/compare`,
    seoIssues: `${getServicesConfig().athenaApi}/page/detail/issues`,
  },
  [REPORT.CHANGES]: {
    list: `${getServicesConfig().athenaApi}/change/list`,
    kpi: `${getServicesConfig().athenaApi}/change/kpi`,
  },
  [REPORT.PAGE_CHANGES]: {
    list: `${getServicesConfig().athenaApi}/change/list`,
    kpi: `${getServicesConfig().athenaApi}/change/kpi`,
  },
  [REPORT.NEW_KEYWORD]: {
    list: `${getServicesConfig().athenaApi}/change/list`,
  },
  [REPORT.LOST_KEYWORD]: {
    list: `${getServicesConfig().athenaApi}/change/list`,
  },
  [REPORT.DISTRIBUTION]: {
    list: `${getServicesConfig().athenaApi}/distribution/list`,
  },
  [REPORT.DIRECTORY]: {
    list: `${getServicesConfig().athenaApi}/directory/list`,
  },
  [REPORT.COUNTRY]: {
    list: `${getServicesConfig().athenaApi}/country/list`,
    kpi: `${getServicesConfig().athenaApi}/country/kpi`,
  },
  [REPORT.MOBILE]: {
    list: `${getServicesConfig().athenaApi}/mobile/list`,
    history: `${getServicesConfig().athenaApi}/mobile/history`,
  },
  [REPORT.DEVICE]: {
    list: `${getServicesConfig().athenaApi}/dimension/list`,
  },
  [REPORT.CANNIBALISATION]: {
    list: `${getServicesConfig().athenaApi}/keywordCannibalisation/list`,
  },
  [REPORT.UNDERPERFORMER]: {
    list: `${getServicesConfig().athenaApi}/underperformer/list`,
  },
  [REPORT.MONITOR]: {
    list: `${getServicesConfig().athenaApi}/keywordMonitoring/list`,
    kpi: `${getServicesConfig().athenaApi}/keywordMonitoring/kpi`,
    graph: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingChangeSummary`,
    removeKeywordFromSegment: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/segment/deleteKeywords`,
    addKeywordToSegment: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/segment/addKeywords`,
    weeklyHistoryKpi: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/kpi`,
    weeklyHistoryData: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/list`,
    rankingGoalDetail: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/goal/detail`,
    rankingGoalRemove: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/goal/remove`,
    rankingGoalUpdate: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/goal/update`,
    rankingGoalCreate: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/goal/create`,
    rankingGoalTopList: `${
      getServicesConfig().athenaApi
    }/keywordMonitoring/rankingDevelopment/goal/top`,
  },
  [REPORT.SEGMENTS]: {
    list: `${getServicesConfig().athenaApi}/segment/list`,
    detailData: `${getServicesConfig().athenaApi}/segment/detail/data`,
    list2: `${getServicesConfig().athenaApi}/segment/listOptimize`,
    comparison: `${getServicesConfig().athenaApi}/segment/comparison`,
    shortList: `${getServicesConfig().athenaApi}/segment/shortList`,
    recipeList: `${getServicesConfig().athenaApi}/segment/recipes/list`,
    recipeDetail: `${getServicesConfig().athenaApi}/segment/recipes/detail`,
  },
  [REPORT.SEGMENT_CREATE]: {
    preview: `${getServicesConfig().athenaApi}/segment/preview`,
    create: `${getServicesConfig().athenaApi}/segment/create`,
    update: `${getServicesConfig().athenaApi}/segment/update`,
    delete: `${getServicesConfig().athenaApi}/segment/delete`,
    shortList: `${getServicesConfig().athenaApi}/segment/shortList`,
    detail: `${getServicesConfig().athenaApi}/segment/detail`,
  },
  [REPORT.EVENT]: {
    create: `${getServicesConfig().athenaApi}/events`,
  },
  [REPORT.AB_TESTS]: {
    list: `${getServicesConfig().athenaApi}/abTesting`,
    delete: `${getServicesConfig().athenaApi}/abTesting/delete`,
    status: `${getServicesConfig().athenaApi}/abTesting`,
    chart: `${getServicesConfig().athenaApi}/abTesting`,
  },
} as Record<keyof typeof REPORT, any>;
