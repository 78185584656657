import { useMixpanel, useCurrentUser } from '@ryte/mainframe';
import { Button, Icon, Select, Tooltip } from '@ryte/ui-components';
import cn from 'classnames';
import lodashTimes from 'lodash/times';
import moment from 'moment';
import React, { useState } from 'react';
import {
  DayPickerRangeController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  isSameDay,
  SingleDatePicker,
} from 'react-dates';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import styles from './DateRange.module.scss';

import {
  selectDateRangeAvailability as makeDateRangeAvailabilitySelector,
  selectFreshDates as makeSelectFreshDates,
} from 'app/containers/App/selectors';
import {
  COMPARED_DATE_RANGES,
  COMPARED_DATE_RANGES_MSGS,
  DateRangeSelectValues,
  RELATIONSHIP,
  SERVER_DATETIME_FORMAT,
  START_DATE,
} from './constants';
import { DateRangeSelect } from './DateRangeSelect';
import messages from './messages';
import { dateFormat } from 'app/config';

const selectDateRangeAvailability = makeDateRangeAvailabilitySelector();
const selectFreshDates = makeSelectFreshDates();

function DateRangePicker(props) {
  const { filter, updateFilter, handleClose } = props;
  const displayFormat = dateFormat.default;
  const { lang } = useCurrentUser();
  const freshDates = useSelector(selectFreshDates);
  const availableDateRange = useSelector(selectDateRangeAvailability);
  const oldestDate = moment(availableDateRange.oldestDate);
  const latestDate = moment(availableDateRange.latestDate);
  const intl = useIntl();
  const { trackEvent } = useMixpanel();
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  const isFreshDataDate = (day) => {
    const { startDate, endDate } = freshDates;
    return (
      startDate &&
      endDate &&
      day.isBetween(
        moment(startDate, 'YYYY-MM-DD'),
        moment(endDate, 'YYYY-MM-DD').add(1, 'days'),
        null,
        '[)'
      )
    );
  };

  const isFreshDataDatesSelected = () => {
    if (props.noCompare) {
      return (
        isFreshDataDate(props.dates.startDate) ||
        isFreshDataDate(props.dates.endDate)
      );
    }
    return (
      isFreshDataDate(props.comparedDates.startDate) ||
      isFreshDataDate(props.comparedDates.endDate) ||
      isFreshDataDate(props.dates.startDate) ||
      isFreshDataDate(props.dates.endDate)
    );
  };

  const dateWidget = (actualDay, classNames = []) => (
    <span className={classNames.join(' ')}>
      {actualDay.format('D')}
      {isFreshDataDate(actualDay) && (
        <div className="CalendarDay__highlighted_freshData">
          <Icon icon="update" color="success" size={10} />
        </div>
      )}
    </span>
  );

  const renderDayContents = (comparedStartDate, comparedEndDate) => (day) => {
    let classNames = [];
    if (day.format('DD.MM.YYYY') === comparedStartDate.format('DD.MM.YYYY')) {
      classNames = [...classNames, 'CalendarDay__highlighted_start'];
    }

    if (day.format('DD.MM.YYYY') === comparedEndDate.format('DD.MM.YYYY')) {
      classNames = [...classNames, 'CalendarDay__highlighted_end'];
    }
    return dateWidget(day, classNames);
  };

  const [currentFocus, setCurrentFocus] = useState('startDate');
  const [errors, setErrors] = useState([]);

  moment.updateLocale(lang, { week: { dow: 1 } });

  const isOutsideRange = (day) => {
    const isBeforeOldestDate = isInclusivelyBeforeDay(
      day,
      oldestDate.clone().add(-1, 'days')
    );
    const isAfterLatestDate = isInclusivelyAfterDay(
      day,
      latestDate.clone().add(1, 'days')
    );

    return isBeforeOldestDate || isAfterLatestDate;
  };

  const handle = (dateRangeOption, compareToOption, startDate, endDate) => {
    const [days, compareToDays] =
      RELATIONSHIP[dateRangeOption][compareToOption];
    let diffInDaysBetweenDateRange = days - 1;
    let diffInDaysOfCompareToOption = compareToDays;
    let newCompareToOption = compareToOption;

    if (days === undefined || days === null) {
      return;
    }

    let newDateFromLatestDate = latestDate.clone().subtract(days - 1, 'days');
    let newStartDate = newDateFromLatestDate;
    let newEndDate = latestDate.clone();

    if (dateRangeOption === DateRangeSelectValues.MAXIMUM) {
      newStartDate = oldestDate.clone();
    }

    if (dateRangeOption === DateRangeSelectValues.CUSTOM) {
      newStartDate = moment(
        startDate.clone().format('DD.MM.YYYY'),
        'DD.MM.YYYY'
      );
      newEndDate = moment(endDate.clone().format('DD.MM.YYYY'), 'DD.MM.YYYY');
    }

    props.setSelectedDateRangeOption(dateRangeOption);
    props.setDates({ startDate: newStartDate, endDate: newEndDate });

    if (dateRangeOption === DateRangeSelectValues.MAXIMUM) {
      return;
    }

    if (
      diffInDaysOfCompareToOption === undefined ||
      diffInDaysOfCompareToOption === null
    ) {
      diffInDaysOfCompareToOption =
        RELATIONSHIP[dateRangeOption][COMPARED_DATE_RANGES.PREVIOUS_PERIOD][1];
      newCompareToOption = COMPARED_DATE_RANGES.PREVIOUS_PERIOD;
    }

    if (dateRangeOption === DateRangeSelectValues.CUSTOM) {
      const diffInDays = newEndDate.diff(newStartDate, 'days') + 1; // include startDate and endDate
      diffInDaysBetweenDateRange = diffInDays - 1;

      if (
        compareToOption === COMPARED_DATE_RANGES.PREVIOUS_PERIOD ||
        compareToOption === COMPARED_DATE_RANGES.CUSTOM
      ) {
        diffInDaysOfCompareToOption = diffInDays;
      }
    }

    const newCompareToEndDate = newEndDate
      .clone()
      .subtract(diffInDaysOfCompareToOption, 'days');
    const newCompareToStartDate = newCompareToEndDate
      .clone()
      .subtract(diffInDaysBetweenDateRange, 'days');

    props.setSelectedCompareToOption(newCompareToOption);

    if (compareToOption === DateRangeSelectValues.CUSTOM) {
      return;
    }

    props.setCompareToDates({
      startDate: newCompareToStartDate,
      endDate: newCompareToEndDate,
    });
  };

  const onDatesChange = ({ startDate, endDate }) => {
    let sdate = startDate;
    let edate = endDate;

    if (currentFocus === 'startDate' && startDate) {
      edate =
        props.dates.endDate.diff(startDate, 'days') < 0
          ? startDate
          : props.dates.endDate;
      props.setDates({
        startDate,
        endDate: edate,
      });
      setCurrentFocus('endDate');
    }

    if (currentFocus === 'endDate' && endDate) {
      sdate =
        endDate.diff(props.dates.startDate, 'days') < 0
          ? endDate
          : props.dates.startDate;
      props.setDates({
        startDate: sdate,
        endDate,
      });
      !props.noCompare
        ? setCurrentFocus('comparedStartDate')
        : setCurrentFocus('startDate');
    }

    if (currentFocus === 'comparedStartDate' && startDate) {
      edate =
        props.comparedDates.endDate.diff(startDate, 'days') < 0
          ? startDate
          : props.comparedDates.endDate;
      props.setCompareToDates({
        startDate,
        endDate: edate,
      });
      !props.noCompare && setCurrentFocus('comparedEndDate');
    }

    if (currentFocus === 'comparedEndDate' && endDate) {
      sdate =
        endDate.diff(props.comparedDates.startDate, 'days') < 0
          ? endDate
          : props.comparedDates.startDate;
      props.setCompareToDates({
        startDate: sdate,
        endDate,
      });
      !props.noCompare && setCurrentFocus('startDate');
    }

    if (['startDate', 'endDate'].includes(currentFocus) && sdate && edate) {
      props.setSelectedDateRangeOption(DateRangeSelectValues.CUSTOM);
      handle(
        DateRangeSelectValues.CUSTOM,
        props.selectedCompareToOption,
        sdate,
        edate
      );
    }

    if (
      !props.noCompare &&
      ['comparedStartDate', 'comparedEndDate'].includes(currentFocus) &&
      sdate &&
      edate
    ) {
      props.setSelectedCompareToOption(COMPARED_DATE_RANGES.CUSTOM);
    }
  };

  const handleChangeDateRange = (dateRangeOption) => {
    const compareToOption = props.selectedCompareToOption;

    handle(
      dateRangeOption,
      compareToOption,
      props.dates.startDate,
      props.dates.endDate
    );
  };

  const handleChangeComparedOption = (e) => {
    const dateRangeOption = props.selectedDateRangeOption;
    const compareToOption = COMPARED_DATE_RANGES[e];

    handle(
      dateRangeOption,
      compareToOption,
      props.dates.startDate,
      props.dates.endDate
    );
  };

  const onFocusChange = (focusingInput) => {
    setFocusedInput(!focusingInput ? START_DATE : focusingInput);
  };

  const onSubmit = () => {
    if (props.dates.startDate === null || props.dates.endDate === null) {
      return;
    }

    if (
      isOutsideRange(props.dates.startDate) ||
      isOutsideRange(props.dates.endDate)
    ) {
      return;
    }

    updateFilter({
      ...filter,
      dateFrom: props.dates.startDate,
      dateTo: props.dates.endDate,
      beforeStartDate: props.noCompare ? null : props.comparedDates.startDate,
      beforeEndDate: props.noCompare ? null : props.comparedDates.endDate,
      page: 1,
    });

    const formattedStartDate = props.dates.startDate.format(
      SERVER_DATETIME_FORMAT
    );
    const formattedEndDate = props.dates.endDate.format(SERVER_DATETIME_FORMAT);

    if (
      window.localStorage.getItem('startDate') !== formattedStartDate ||
      window.localStorage.getItem('endDate') !== formattedEndDate
    ) {
      trackEvent({
        'eventName': 'SES Report Date Range Changed',
        'Start Date': props.dates.startDate.format('YYYY-MM-DDTHH:mm:ss'),
        'End Date': props.dates.endDate.format('YYYY-MM-DDTHH:mm:ss'),
        'Number Of Days':
          moment(props.dates.endDate).diff(
            moment(props.dates.startDate),
            'days'
          ) + 1,
        'Days From End Date To Today':
          moment().diff(moment(props.dates.endDate), 'days') + 1,
      });
    }

    window.localStorage.setItem('startDate', formattedStartDate);
    window.localStorage.setItem('endDate', formattedEndDate);
    props.setComparisonMessage(
      intl.formatMessage(
        COMPARED_DATE_RANGES_MSGS[props.selectedCompareToOption]
      )
    );
    handleClose();
  };

  const onMonthClick = (e) => {
    e.preventDefault();

    const selectedDate = moment(e.target.dataset.selected, displayFormat);
    const selectedFirstDayOfMonth = selectedDate.clone().startOf('month');
    const selectedLastDayOfMonth = selectedDate.clone().endOf('month');

    // is outside range of oldest date and latest date
    if (
      isInclusivelyBeforeDay(
        selectedLastDayOfMonth,
        oldestDate.clone().add(-1, 'days')
      ) ||
      isInclusivelyAfterDay(
        selectedFirstDayOfMonth,
        latestDate.clone().add(1, 'days')
      )
    ) {
      return;
    }

    const startDate = moment.max([oldestDate, selectedFirstDayOfMonth]);
    const endDate = moment.min([latestDate, selectedLastDayOfMonth]);

    if (['startDate', 'endDate'].includes(currentFocus)) {
      props.setDates({ startDate, endDate });
      !props.noCompare && setCurrentFocus('comparedStartDate');
    }

    if (
      !props.noCompare &&
      ['comparedStartDate', 'comparedEndDate'].includes(currentFocus)
    ) {
      props.setCompareToDates({ startDate, endDate });
      setCurrentFocus('startDate');
    }
  };

  const renderMonthElement = (monthElementProps) => {
    const selectedDate = monthElementProps.month;

    return (
      <a
        href="/"
        onClick={onMonthClick}
        data-selected={selectedDate.format(displayFormat)}>
        {selectedDate.format('MMMM YYYY')}
      </a>
    );
  };

  const onStartDateChange = (sDate) => {
    if (sDate) {
      const eDate =
        props.dates.endDate.diff(sDate, 'days') < 0
          ? sDate
          : props.dates.endDate;
      props.setDates({
        startDate: sDate,
        endDate: eDate,
      });
      props.setSelectedDateRangeOption(DateRangeSelectValues.CUSTOM);
      handle(
        DateRangeSelectValues.CUSTOM,
        props.selectedCompareToOption,
        sDate,
        eDate
      );
      setErrors(errors.filter((e) => e !== 'startDate'));
    } else {
      !errors.includes('startDate') && setErrors([...errors, 'startDate']);
    }
  };
  const onStartDateFocus = () => {
    setCurrentFocus('startDate');
  };
  const onEndDateChange = (eDate) => {
    if (eDate) {
      const sDate =
        eDate.diff(props.dates.startDate, 'days') < 0
          ? eDate
          : props.dates.startDate;
      props.setDates({
        startDate: sDate,
        endDate: eDate,
      });
      props.setSelectedDateRangeOption(DateRangeSelectValues.CUSTOM);
      handle(
        DateRangeSelectValues.CUSTOM,
        props.selectedCompareToOption,
        sDate,
        eDate
      );
      setErrors(errors.filter((e) => e !== 'endDate'));
    } else {
      !errors.includes('endDate') && setErrors([...errors, 'endDate']);
    }
  };
  const onEndDateFocus = () => {
    setCurrentFocus('endDate');
  };
  const onComparedStartDateChange = (sDate) => {
    if (sDate) {
      const eDate =
        props.comparedDates.endDate.diff(sDate, 'days') < 0
          ? sDate
          : props.comparedDates.endDate;
      props.setCompareToDates({
        startDate: sDate,
        endDate: eDate,
      });
      props.setSelectedCompareToOption(COMPARED_DATE_RANGES.CUSTOM);
      setErrors(errors.filter((e) => e !== 'comparedStartDate'));
    } else {
      !errors.includes('comparedStartDate') &&
        setErrors([...errors, 'comparedStartDate']);
    }
  };
  const onComparedStartDateFocus = () => {
    setCurrentFocus('comparedStartDate');
  };
  const onComparedEndDateChange = (eDate) => {
    if (eDate) {
      const sDate =
        eDate.diff(props.comparedDates.startDate, 'days') < 0
          ? eDate
          : props.comparedDates.startDate;
      props.setCompareToDates({
        startDate: sDate,
        endDate: eDate,
      });
      props.setSelectedCompareToOption(COMPARED_DATE_RANGES.CUSTOM);
      setErrors(errors.filter((e) => e !== 'comparedEndDate'));
    } else {
      !errors.includes('comparedEndDate') &&
        setErrors([...errors, 'comparedEndDate']);
    }
  };
  const onComparedEndDateFocus = () => {
    setCurrentFocus('comparedEndDate');
  };

  const dateRangePickerOption = [
    {
      value: 'PREVIOUS_PERIOD',
      label: intl.formatMessage(messages.previousPeriod),
      isDisabled:
        RELATIONSHIP[props.selectedDateRangeOption].PREVIOUS_PERIOD[1] === null,
    },
    {
      value: 'PREVIOUS_YEAR',
      label: intl.formatMessage(messages.previousYear),
      isDisabled:
        RELATIONSHIP[props.selectedDateRangeOption].PREVIOUS_YEAR[1] === null,
    },
    {
      value: 'PREVIOUS_QUARTER',
      label: intl.formatMessage(messages.previousQuarter),
      isDisabled:
        RELATIONSHIP[props.selectedDateRangeOption].PREVIOUS_QUARTER[1] ===
        null,
    },
    {
      value: 'PREVIOUS_MONTH',
      label: intl.formatMessage(messages.previousMonth),
      isDisabled:
        RELATIONSHIP[props.selectedDateRangeOption].PREVIOUS_MONTH[1] === null,
    },
    {
      value: 'CUSTOM',
      label: intl.formatMessage(messages.customRange),
    },
  ];

  const renderCalendarInfo = () => {
    return (
      <div className={`date-range-picker__panel ${currentFocus}--focusing`}>
        <div className="date-range-picker__time">
          <DateRangeSelect
            onChange={handleChangeDateRange}
            value={props.selectedDateRangeOption}
            oldestDate={oldestDate}
            latestDate={latestDate}
          />
          <div
            className={cn('date-range-picker__input', {
              'error-start-date': errors.includes('startDate'),
              'error-end-date': errors.includes('endDate'),
            })}>
            <SingleDatePicker
              id="startDate"
              date={props.dates.startDate}
              displayFormat={displayFormat}
              isOutsideRange={isOutsideRange}
              placeholder={intl.formatMessage(messages.startDate)}
              onDateChange={onStartDateChange}
              onFocusChange={onStartDateFocus}
            />
            <div className="SingleDatePickerInput_arrow">
              <span>{`-`}</span>
            </div>
            <SingleDatePicker
              id="endDate"
              date={props.dates.endDate}
              displayFormat={displayFormat}
              isOutsideRange={isOutsideRange}
              placeholder={intl.formatMessage(messages.endDate)}
              onDateChange={onEndDateChange}
              onFocusChange={onEndDateFocus}
            />
          </div>
          <div
            className={cn('selection-box', {
              'visibility-hidden': props.noCompare,
            })}>
            <span>{intl.formatMessage(messages.compareTo)}</span>
            <Select
              className={styles.select}
              onChange={handleChangeComparedOption}
              value={props.selectedCompareToOption}
              isDisabled={
                props.selectedDateRangeOption ===
                  DateRangeSelectValues.MAXIMUM || props.noCompare
              }
              options={dateRangePickerOption}
            />
          </div>
          <div
            className={cn('date-range-picker__input', {
              'visibility-hidden': props.noCompare,
              'error-compared-start-date': errors.includes('comparedStartDate'),
              'error-compared-end-date': errors.includes('comparedEndDate'),
            })}>
            <SingleDatePicker
              id="comparedStartDate"
              date={props.comparedDates.startDate}
              displayFormat={displayFormat}
              isOutsideRange={isOutsideRange}
              placeholder={intl.formatMessage(messages.startDate)}
              onDateChange={onComparedStartDateChange}
              onFocusChange={onComparedStartDateFocus}
              disabled={
                props.selectedDateRangeOption ===
                  DateRangeSelectValues.MAXIMUM || props.noCompare
              }
            />
            <div className="SingleDatePickerInput_arrow">
              <span>{`-`}</span>
            </div>
            <SingleDatePicker
              id="comparedEndDate"
              date={props.comparedDates.endDate}
              displayFormat={displayFormat}
              isOutsideRange={isOutsideRange}
              placeholder={intl.formatMessage(messages.endDate)}
              onDateChange={onComparedEndDateChange}
              onFocusChange={onComparedEndDateFocus}
              disabled={
                props.selectedDateRangeOption ===
                  DateRangeSelectValues.MAXIMUM || props.noCompare
              }
            />
          </div>

          <div
            className={cn({
              'visibility-hidden': !isFreshDataDatesSelected(),
            })}>
            <Icon icon="update" color="success" size={14} />{' '}
            <FormattedMessage tagName={'span'} {...messages.SelectFreshDate} />{' '}
            <Tooltip
              content={intl.formatMessage(messages.FreshDataTooltip)}
              direction="bottom">
              <u>{intl.formatMessage(messages.FreshData)}</u>
            </Tooltip>
          </div>
        </div>
        <div className="btn-group">
          <Button
            variant="backgroundless"
            text={intl.formatMessage(messages.cancel)}
            onClick={handleClose}
          />
          <Button
            variant="primary"
            text={intl.formatMessage(messages.apply)}
            onClick={onSubmit}
          />
        </div>
      </div>
    );
  };

  let datesList = [];

  if (
    !props.noCompare &&
    props.selectedDateRangeOption !== DateRangeSelectValues.MAXIMUM
  ) {
    const comparedStartDate = moment(
      props.comparedDates.startDate.format('DD.MM.YYYY'),
      'DD.MM.YYYY'
    );
    const comparedEndDate = moment(
      props.comparedDates.endDate.format('DD.MM.YYYY'),
      'DD.MM.YYYY'
    );

    datesList = lodashTimes(
      comparedEndDate.diff(comparedStartDate, 'days') + 1
    ).map((index) => comparedStartDate.clone().add(index, 'days'));
  }

  return (
    <div
      className={`date-range-picker ${
        !props.noCompare &&
        ['comparedStartDate', 'comparedEndDate'].includes(currentFocus)
          ? 'DRP-compared-to'
          : ''
      }`}>
      <DayPickerRangeController
        minimumNights={0}
        numberOfMonths={2}
        firstDayOfWeek={1}
        startDate={props.dates.startDate}
        endDate={props.dates.endDate}
        minDate={oldestDate}
        maxDate={latestDate}
        initialVisibleMonth={() => latestDate.clone().add(-1, 'months')}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        renderCalendarInfo={renderCalendarInfo}
        renderMonthElement={renderMonthElement}
        isOutsideRange={isOutsideRange}
        renderDayContents={
          !props.noCompare
            ? renderDayContents(
                props.comparedDates.startDate,
                props.comparedDates.endDate
              )
            : (day) => dateWidget(day)
        }
        transitionDuration={0}
        hideKeyboardShortcutsPanel
        calendarInfoPosition="after"
        navPrev={<Icon icon={'chevron_left'} />}
        navNext={<Icon icon={'chevron_right'} />}
        endDateOffset={
          !props.noCompare &&
          ['endDate', 'comparedEndDate'].includes(currentFocus)
            ? (day) => day
            : null
        }
        isDayHighlighted={(day1) =>
          datesList.some((day2) => isSameDay(day1, day2))
        }
      />
    </div>
  );
}

DateRangePicker.defaultProps = {
  displayFormat: 'DD.MM.YYYY',
  noCompare: true,
};

export default DateRangePicker;
