import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AnomaliesPage from 'app/containers/AnomaliesPage/Loadable';
import AnomalyPage from 'app/containers/AnomalyPage/Loadable';
import CannibalisationsPage from 'app/containers/Cannibalisations/Loadable';
import CountriesPage from 'app/containers/Countries/Loadable';
import DirectoriesPage from 'app/containers/Directories/Loadable';
import EasyConnectPage from 'app/containers/EasyConnect/Loadable';
import ImportingInfoPage from 'app/containers/ImportingInfo/Loadable';
import KeywordMonitoringPage from 'app/containers/KeywordMonitoring/Loadable';
import LostKeywordsPage from 'app/containers/LostKeyword/Loadable';
import DevicesPage from 'app/containers/Devices/Loadable';
import NewKeywordsPage from 'app/containers/NewKeyword/Loadable';
import NotFoundPage from 'app/containers/NotFoundPage/Loadable';
import OverviewPage from 'app/containers/Overview/Loadable';
import PagesPage from 'app/containers/Pages/Loadable';
import KeywordsPage from 'app/containers/Queries/Loadable';
import DistributionPage from 'app/containers/Distribution/Loadable';
import RedirectPage from 'app/containers/RedirectPage/Loadable';
import UnderPerformerPage from 'app/containers/UnderPerformer/Loadable';
import ChangesPage from 'app/containers/ChangesPage/Loadable';
import RankingDevelopment from 'app/containers/RankingDevelopment/Loadable';
import SegmentCreate from 'app/containers/SegmentCreate/Loadable';
import Segments from 'app/containers/Segments/Loadable';
import ABTestsPage from 'app/containers/ABTestsPage/Loadable';

import { REPORT } from '../../apiDefine';
import ConnectedPropertyRoute from './ConnectedPropertyRoute';

type Props = {};
const AppRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <Redirect
        exact
        path="/:project/search-success"
        to={`/:project/${REPORT.OVERVIEW}`}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.OVERVIEW}`}
        component={OverviewPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.ANOMALIES}`}
        component={AnomaliesPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.MONITOR}`}
        component={KeywordMonitoringPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.ANOMALY}`}
        component={AnomalyPage}
      />
      <ConnectedPropertyRoute
        exact
        path={`/:project/${REPORT.SEGMENT_CREATE}`}
        component={SegmentCreate}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE_SEGMENTS}`}
        path={`/:project/${REPORT.PAGE_SEGMENTS}`}
        component={Segments}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE_SEGMENTS}/:shareId/:slug`}
        path={`/:project/${REPORT.PAGE_SEGMENTS}/:shareId/:slug`}
        component={Segments}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.KEYWORD_SEGMENTS}`}
        path={`/:project/${REPORT.KEYWORD_SEGMENTS}`}
        component={Segments}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.KEYWORD_SEGMENTS}/:shareId/:slug`}
        path={`/:project/${REPORT.KEYWORD_SEGMENTS}/:shareId/:slug`}
        component={Segments}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.QUERY}`}
        path={`/:project/${REPORT.QUERY}`}
        component={KeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.QUERY}/:shareId/:slug`}
        path={`/:project/${REPORT.QUERY}/:shareId/:slug`}
        component={KeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE}`}
        path={`/:project/${REPORT.PAGE}`}
        component={PagesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE}/:shareId/:slug`}
        path={`/:project/${REPORT.PAGE}/:shareId/:slug`}
        component={PagesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.CHANGES}`}
        path={`/:project/${REPORT.CHANGES}`}
        component={ChangesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.CHANGES}/:shareId/:slug`}
        path={`/:project/${REPORT.CHANGES}/:shareId/:slug`}
        component={ChangesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE_CHANGES}`}
        path={`/:project/${REPORT.PAGE_CHANGES}`}
        component={ChangesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.PAGE_CHANGES}/:shareId/:slug`}
        path={`/:project/${REPORT.PAGE_CHANGES}/:shareId/:slug`}
        component={ChangesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.NEW_KEYWORD}`}
        path={`/:project/${REPORT.NEW_KEYWORD}`}
        component={NewKeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.NEW_KEYWORD}/:shareId/:slug`}
        path={`/:project/${REPORT.NEW_KEYWORD}/:shareId/:slug`}
        component={NewKeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.LOST_KEYWORD}`}
        path={`/:project/${REPORT.LOST_KEYWORD}`}
        component={LostKeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.LOST_KEYWORD}/:shareId/:slug`}
        path={`/:project/${REPORT.LOST_KEYWORD}/:shareId/:slug`}
        component={LostKeywordsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.DISTRIBUTION}`}
        path={`/:project/${REPORT.DISTRIBUTION}`}
        component={DistributionPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.DISTRIBUTION}/:shareId/:slug`}
        path={`/:project/${REPORT.DISTRIBUTION}/:shareId/:slug`}
        component={DistributionPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.DIRECTORY}`}
        path={`/:project/${REPORT.DIRECTORY}`}
        component={DirectoriesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.DIRECTORY}/:shareId/:slug`}
        path={`/:project/${REPORT.DIRECTORY}/:shareId/:slug`}
        component={DirectoriesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.COUNTRY}`}
        path={`/:project/${REPORT.COUNTRY}`}
        component={CountriesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.COUNTRY}/:shareId/:slug`}
        path={`/:project/${REPORT.COUNTRY}/:shareId/:slug`}
        component={CountriesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.MOBILE}`}
        path={`/:project/${REPORT.MOBILE}`}
        component={DevicesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.MOBILE}/:shareId/:slug`}
        path={`/:project/${REPORT.MOBILE}/:shareId/:slug`}
        component={DevicesPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.CANNIBALISATION}`}
        path={`/:project/${REPORT.CANNIBALISATION}`}
        component={CannibalisationsPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.CANNIBALISATION}/:shareId/:slug`}
        path={`/:project/${REPORT.CANNIBALISATION}/:shareId/:slug`}
        component={CannibalisationsPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.KEYWORD_MONITORING_WEEKLY_HISTORY}`}
        component={RankingDevelopment}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.UNDERPERFORMER}`}
        path={`/:project/${REPORT.UNDERPERFORMER}`}
        component={UnderPerformerPage}
      />
      <ConnectedPropertyRoute
        exact
        key={`/:project/${REPORT.UNDERPERFORMER}/:shareId/:slug`}
        path={`/:project/${REPORT.UNDERPERFORMER}/:shareId/:slug`}
        component={UnderPerformerPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/${REPORT.AB_TESTS}`}
        component={ABTestsPage}
      />
      <ConnectedPropertyRoute
        path={`/:project/search-success/_r`}
        component={RedirectPage}
      />
      <Route
        path={`/:project/${REPORT.EASY_CONNECT}`}
        component={EasyConnectPage}
      />
      <Route
        path={`/:project/${REPORT.LOCK_UI}`}
        component={ImportingInfoPage}
      />
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
};

export default AppRoutes;
