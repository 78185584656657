import React from 'react';

export type SaveProfile = {
  id: string;
  name: string;
  description: string;
  slug: string;
  created: string;
  reportName: string;
  url: string;
  fullUrl?: string;
  fullReportName?: string;
  startDate: string | null;
  endDate: string | null;
  beforeStartDate: string | null;
  beforeEndDate: string | null;
  updated: string;
  updatedBy: string;
  ownerId: string;
  ownerName: string | null;
  editable: boolean;
  deletable: boolean;
  rollingDates: boolean;
  isCached: boolean;
};

export type SaveShareContextType = {
  isSaving: boolean;
  isStateChanged: boolean;
  isLoaded: boolean;
  savedReport: SaveProfile | null;
  saveReport: (afterSaved: () => void) => void;
  updateSavedReport: (newSavedReport: SaveProfile | null) => void;
};
export const SaveShareContext =
  React.createContext<SaveShareContextType | null>(null);
