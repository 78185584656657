import { defineMessages } from 'react-intl';

export default defineMessages({
  okGotIt: {
    id: 'ses.Introbox.okGotIt',
    defaultMessage: 'Ok, Got it',
  },
  ab_testing_title: {
    id: 'ses.Introbox.ab_testing_title',
    defaultMessage:
      'Perform A/B tests to see how your changes affected organic search performance',
  },
  ab_testing_content: {
    id: 'ses.Introbox.ab_testing_content',
    defaultMessage:
      'Using A / B tests you can increase the conversion of landing pages, select optimal ad titles and improve the search results quality. Here you can compare a <strong>test group of pages</strong>, which were optimized, against a <strong>control (unchanged) group</strong>, and see if there was a meaningful improvement over the time.',
  },
  all_keywords_title: {
    id: 'ses.Introbox.all_keywords_title',
    defaultMessage: 'Discover changes in your Google traffic over time',
  },
  all_keywords_content: {
    id: 'ses.Introbox.all_keywords_content',
    defaultMessage:
      'Have you noticed a drop or spike in your organic traffic and are wondering what the cause is? For each of your keywords, see clicks, impressions and ranking positions with real Google data, and how they compare to the previous period. Check in each week to monitor your rankings. If they’ve dropped, make the most of Content Success to polish your content. Be the next trendsetter!',
  },
  all_pages_title: {
    id: 'ses.Introbox.all_pages_title',
    defaultMessage: 'Everything you need to know about your pages',
  },
  all_pages_content: {
    id: 'ses.Introbox.all_pages_content',
    defaultMessage:
      "Find out which pages (individual URLs) performed well in Google search during your selected time period and which ones need some optimization work. Click on an individual page to see its performance and which keywords it ranks for. Content Success will help to put you on the right track if any pages are losing ranking positions. Come back again next week to check your progress. Don't miss a thing!",
  },
  anomalies_title: {
    id: 'ses.Introbox.anomalies_title',
    defaultMessage: 'Be aware of unexpected changes in you search traffic',
  },
  anomalies_content: {
    id: 'ses.Introbox.anomalies_content',
    defaultMessage:
      '<p>Anomaly Detection alerts you when your website’s search performance suddenly experiences significant irregularities in impressions, clicks, CTR, and position.</p><p>The graph below shows your expected range of values in grey and actual values overtime with colored lines. You can adjust the threshold of anomaly detection according to your needs. Unexpected drops (negative anomalies) are <red>highlighted in red</red> and unexpected gains (positive anomalies) are <green>highlighted in green</green>.</p><p>To learn more about the affected pages or keywords, click on the individual anomaly. Set up automated email alerts to ensure that you are notified in time in case of an anomaly.</p><p>Learn more about this new feature in <link>[https://en.ryte.com/product-insights/anomaly-detection](this article)</link>.</p>',
  },
  cannibalisations_title: {
    id: 'ses.Introbox.cannibalisations_title',
    defaultMessage: 'Find keywords that rank for more than one page',
  },
  cannibalisations_content: {
    id: 'ses.Introbox.cannibalisations_content',
    defaultMessage:
      'Keyword cannibalization occurs when multiple pages rank for the same keyword, damaging the individual ranking of each page. This report helps you avoid keyword cannibalization by showing which keywords rank for multiple pages so that you can think about how to change the content to target different keywords. <group-content><red-badge>cannibalization</red-badge><span>Check which is your preferred page and - depending on the pages performance - either merge and redirect the pages accordingly or try to give all ranking pages a clear keyword focus.</span></group-content><group-content><blue-badge>multiple ranking</blue-badge><span>Those keywords have multiple pages ranking but are not cannibalising each other at the moment. No immediate action needed but keep an eye on them in case they turn into an actual cannibalization </span></group-content><group-content><green-badge>brand ranking</green-badge><span>It is normal for these brand keywords that multiple pages are ranking at the same time. No action needed form your side.</span></group-content>',
  },
  countries_title: {
    id: 'ses.Introbox.countries_title',
    defaultMessage: 'Check out your international search performance',
  },
  countries_content: {
    id: 'ses.Introbox.countries_content',
    defaultMessage:
      'If you know which countries your users come from, you can make sure to offer localized versions. See where your website visitors are coming from to make your business soar internationally.',
  },
  ctr_underperformer_title: {
    id: 'ses.Introbox.ctr_underperformer_title',
    defaultMessage: 'Increase the CTR for these keywords to shine bright',
  },
  ctr_underperformer_content: {
    id: 'ses.Introbox.ctr_underperformer_content',
    defaultMessage:
      'The Underperformer Report shows you keywords of the last 30 days in your project that have a lower click-through rate (CTR) than expected based on their ranking position. These keywords are at high-risk for a drop in rankings which could have a major impact on your search performance, so you should get to work on optimizing these keywords as soon as possible. <link>[https://en.ryte.com/product-insights/underperformer-report-identify-organic-underperforming-keywords-easily](Learn more in this article)</link>',
  },
  devices_title: {
    id: 'ses.Introbox.devices_title',
    defaultMessage:
      'Take a look at your website performance on different devices',
  },
  devices_content: {
    id: 'ses.Introbox.devices_content',
    defaultMessage:
      'Well over 50% of global traffic is now carried out on mobile devices. This report shows you how many visitors use your website on mobile, desktop and tablet, and how many keywords are ranking per device. If less than 50% of your visitors use the mobile version, put all of your efforts into mobile optimization. Be available everywhere! <link>[https://en.ryte.com/product-insights/mobile-vs-desktop-find-out-where-your-traffic-comes-from](Learn more)</link>',
  },
  directories_title: {
    id: 'ses.Introbox.directories_title',
    defaultMessage: 'Get detailed information about traffic per directory',
  },
  directories_content: {
    id: 'ses.Introbox.directories_content',
    defaultMessage:
      'Directories are essentially the different sections of your website. Understanding which directories get the biggest share of your Google traffic is useful for the first analysis of a website and for deriving concrete optimization measures. See which directory gets which share of your Google traffic in this report. Choose any directory to dig even deeper!',
  },
  distribution_title: {
    id: 'ses.Introbox.distribution_title',
    defaultMessage: 'Determine your prominence in Google',
  },
  distribution_content: {
    id: 'ses.Introbox.distribution_content',
    defaultMessage:
      "Monitoring how your rankings are distributed across the Google search result pages is a great way to track the progress of your optimization measures. See what pages rank on which page of the Google search results and filter by keyword, country and pages. Analyze the distribution over time to see if you're on the right track. If your rankings are slipping off page 1, it's time to optimize your content.",
  },
  keyword_changes_title: {
    id: 'ses.Introbox.keyword_changes_title',
    defaultMessage: 'Discover changes in your Google traffic over time',
  },
  keyword_changes_content: {
    id: 'ses.Introbox.keyword_changes_content',
    defaultMessage:
      'For each of your keywords, see clicks, impressions and ranking positions with real Google data, and how they compare to a previous period. Check in each week to monitor your rankings. Have you noticed a drop in your traffic? Use this report to find out why. If your rankings have dropped, make the most of Content Success to polish your content. Be the next trendsetter!',
  },
  keyword_monitoring_title: {
    id: 'ses.Introbox.keyword_monitoring_title',
    defaultMessage: 'Everything you need to know about your keywords',
  },
  keyword_monitoring_content: {
    id: 'ses.Introbox.keyword_monitoring_content',
    defaultMessage:
      "Track the progress of your keywords with continuous monitoring of traffic data. See detailed, real Google data for all keywords, including for different countries and devices. Check the report once a week to make sure you don't miss anything important.",
  },
  keyword_segments_title: {
    id: 'ses.Introbox.keyword_segments_title',
    defaultMessage: 'Introducing segments',
  },
  keyword_segments_content: {
    id: 'ses.Introbox.keyword_segments_content',
    defaultMessage:
      'You are now able to cluster your keywords and pages into segments to compare their combined performance. If you had some labels in keyword monitoring, they have now been turned into segments. You can edit them from the dropdown menu above. We created the first segment, Brand Keywords, for you. Go ahead and add some keywords there. <link>[https://en.ryte.com/product-insights/better-search-performance-analysis-with-keyword-and-page-segments](Learn more)</link>',
  },
  lost_keywords_title: {
    id: 'ses.Introbox.lost_keywords_title',
    defaultMessage: 'Explore lost keywords',
  },
  lost_keywords_content: {
    id: 'ses.Introbox.lost_keywords_content',
    defaultMessage:
      'We help you limit the damage if your Google traffic goes downhill. See for which keywords your website generated fewer impressions and clicks than the previous period, and if your rankings are suffering because of it.',
  },
  new_keywords_title: {
    id: 'ses.Introbox.new_keywords_title',
    defaultMessage: 'Explore new keywords',
  },
  new_keywords_content: {
    id: 'ses.Introbox.new_keywords_content',
    defaultMessage:
      'Always stay up to date! See which new keywords your website is now ranking for. If you aimed to rank for one of these, you did it. Yay!',
  },
  page_changes_title: {
    id: 'ses.Introbox.page_changes_title',
    defaultMessage: 'Discover changes in your Google traffic over time',
  },
  page_changes_content: {
    id: 'ses.Introbox.page_changes_content',
    defaultMessage:
      'For each of your pages, see clicks, impressions and ranking positions with real Google data, and how they compare to a previous period. Check in each week to monitor your rankings. Have you noticed a drop in your traffic? Use this report to find out why. If your rankings have dropped, make the most of Content Success to polish your content. Be the next trendsetter!',
  },
  page_segments_title: {
    id: 'ses.Introbox.page_segments_title',
    defaultMessage: 'Introducing segments',
  },
  page_segments_content: {
    id: 'ses.Introbox.page_segments_content',
    defaultMessage:
      'You are now able to cluster your keywords and pages into segments to compare their combined performance. If you had some labels in keyword monitoring, they have now been turned into segments. You can edit them from the dropdown menu above. We created the first segment, Brand Keywords, for you. Go ahead and add some keywords there. <link>[https://en.ryte.com/product-insights/better-search-performance-analysis-with-keyword-and-page-segments](Learn more)</link>',
  },
  ranking_development_title: {
    id: 'ses.Introbox.ranking_development_title',
    defaultMessage: 'The Ranking Development report',
  },
  ranking_development_content: {
    id: 'ses.Introbox.ranking_development_content',
    defaultMessage:
      'The Ranking Development report shows the changes in position of your monitored keywords. This allows you to track the success of your SEO efforts precisely. The graph shows the time series of how many keywords are ranking on pages 1, 2 or 3+. You can use keyword segments to focus on a particular set of keywords.',
  },
});
